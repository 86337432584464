import styled from "styled-components";

import { Text } from "../../../../base/Content";
import { DatePicker, CountrySelect, DistributorSelect } from "../../../../form";

export const DistributorReportFilterContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-top: 30px;
`;

export const FilterLabel = styled(Text)``;

export const RangeStart = styled(DatePicker)``;
export const RangeEnd = styled(DatePicker)``;
export const Country = styled(CountrySelect)``;
export const Distributor = styled(DistributorSelect)``;

export const Error = styled(Text)`
  color: red;
  flex: 1 1 100%;
`;
