import React from 'react'
import {Select} from "../base/Select";

const Pagination = ({ nPages, currentPage, setCurrentPage, setTotalItems, totalItems, currentRecords, totalActive = true }) => {

    const optionsTotalItems = [20, 100, 200, 300, 500, 1000];

    const pageNumbers = [...Array(nPages + 1).keys()].slice(1)

    const nextPage = () => {
        if(currentPage !== nPages) setCurrentPage(currentPage + 1)
    }
    const prevPage = () => {
        if(currentPage !== 1) setCurrentPage(currentPage - 1)
    }
    return (
        <div className="row">
            <div className="col-10">
                {currentRecords.length > 0 &&
                    <nav>
                        <ul className='pagination flex-wrap'>
                            <li className="page-item">
                                <a className="page-link c-pointer"
                                   onClick={prevPage}>
                                    Previous
                                </a>
                            </li>
                            {pageNumbers.map(pgNumber => (
                                <li key={pgNumber}
                                    className= {`page-item ${currentPage == pgNumber ? 'active' : ''} `} >

                                    <a onClick={() => setCurrentPage(pgNumber)}
                                       className='page-link c-pointer'>
                                        {pgNumber}
                                    </a>
                                </li>
                            ))}
                            <li className="page-item">
                                <a className="page-link c-pointer"
                                   onClick={nextPage}>
                                    Next
                                </a>
                            </li>
                        </ul>
                    </nav>
                }
            </div>
            {totalActive &&
                <div className="col-2">
                    <div className="input-group">
                        <div className="input-group-prepend d-flex align-items-center">
                            <span>Total Items</span>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <Select options={optionsTotalItems} name="totalItems" onSelect={setTotalItems} selected={totalItems} />
                            </div>
                        </div>

                    </div>
                </div>
            }
        </div>

    )
}

export default Pagination