import React from "react";
import { useTranslation } from "react-i18next";

import { Alert } from "../../components/alert";

export const ShowLoading = ({ loading }) => {
  const { t } = useTranslation();
  return (
    <Alert blocking show={loading}>
      {t("general.loading")}
    </Alert>
  );
};
