import React, { useContext, createContext, useEffect, useState } from "react";

import { APIContext } from "./APIContext";
import useSearch from "../hooks/useSearch";

export const PatientContext = createContext({});
export const PatientContextProvider = ({ children, data }) => {
  const [patient, setPatient] = useState(null);
  const [implant, setImplant] = useState(null);
  const [loading, setLoading] = useState(false);
  const [saved, setSaved] = useState(false);
  const clearImplant = () => setImplant(null);
  const {
    patientSearch,
    getPatientErrors,
    getImplantTransactions,
    postPatient,
    postImplant,
    postWarrantyExtend,
    postSurgery,
  } = useContext(APIContext);
  const { criteria } = useSearch();

  useEffect(clearImplant, [data]);
  useEffect(() => setPatient(data), [data]);


  const reloadPatient = () => {
    return patientSearch(criteria).then(({ results }) => {
      const patient = results[0];
      setPatient(patient);
      if (implant) {
        setImplant(patient.implants.find((i) => implant.id === i.id));
      }
    });
  };

  return (
    <PatientContext.Provider
      value={{
        loading,
        saved,
        patient,
        setPatient,
        implant,
        setImplant,
        clearImplant,
          setLoading,

        async getPatientErrors() {
          return patient && (await getPatientErrors(patient.id));
        },
        async getImplantTransactions() {
          return implant && (await getImplantTransactions(implant.id));
        },
        async postPatient(data) {
          setLoading(true);
          setSaved(false);
          await postPatient(patient.id, data);
          await reloadPatient();
          setLoading(false);
          setSaved(true);
        },
        async postImplant(id, data) {
          setLoading(true);
          setSaved(false);
          try {
            await postImplant(id, data);
            await reloadPatient();
            setLoading(false);
            setSaved(true);
          } catch (e) {
            setLoading(false);
            setSaved(false);
            throw e;
          }
        },
        async postSurgery(id, data) {
          setLoading(true);
          setSaved(false);
          await postSurgery(id, data);
          await reloadPatient();
          setLoading(false);
          setSaved(true);
        },
        async postWarrantyExtend(data) {
          try {
            setLoading(true);
            setSaved(false);
            return (
              implant &&
              (await postWarrantyExtend(implant.registrationId, data))
            );
          } finally {
            await reloadPatient();
            setLoading(false);
            setSaved(true);
          }
        },
      }}
    >
      {children}
    </PatientContext.Provider>
  );
};
