import styled from "styled-components";
import { Icon } from "../../../icons";

import { Button as BaseButton } from "../../../base";

export const InstructionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const InstructionsIcon = styled(Icon)`
  height: 100px;
  width: 100px;
  font-size: 5em;
  color: ${({ theme }) => theme["$lightest-text"]};
`;

export const InstructionsText = styled.div``;

export const Button = styled(BaseButton)``;
