import styled from "styled-components";
import { H3 } from "../../base/Content";

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const Title = styled(H3)`
  margin: 20px 0px;
`;
export const CardLayout = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;

  & > * {
    flex: 0 0 ${(props) => (props.columns ? 95 / props.columns : 99)}%;
  }
`;
