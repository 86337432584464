import styled from "styled-components";
import { Alert } from "react-bootstrap";

export const StyledBackdrop = styled.div`
  position: fixed;
  height: 100vw;
  width: 100vw;
  top: 0;
  left: 0;
  backdrop-filter: blur(1px) opacity(1);
  transition: all 0.2s;
  z-index: 100;
`;

export const StyledAlert = styled(Alert)`
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 10%;
  min-width: 50%;
  text-align: center;
  border-radius: 50px;
  z-index: 101;

  background-color: ${(props) => props.theme["$primary-background"]};
  color: ${(props) => props.theme["$primary"]};
  transition: all 0.2s;
  font-weight: bold;
`;
