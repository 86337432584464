import { DataEntryTitle, DataEntryDescription } from "./styles";

export const DataEntry = ({ title, children }) => {
  return (
    <>
      <DataEntryTitle>{title}</DataEntryTitle>
      <DataEntryDescription>{children}</DataEntryDescription>
    </>
  );
};
