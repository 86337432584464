import styled from "styled-components";
import { Text } from "../../../../base/Content";
import { DatePicker, CountrySelect, DistributorSelect, SimpleContextDropdown, TextInput } from "../../../../form";
export const ImplantReportFilterContainer = styled.div`
  margin-top: -30px;
`;

export const FilterLabel = styled(Text)``;

export const RangeStart = styled(DatePicker)``;
export const RangeEnd = styled(DatePicker)``;
export const Country = styled(CountrySelect)``;
export const Distributor = styled(DistributorSelect)``;
export const ImplantType = styled(SimpleContextDropdown)``;
export const Lot = styled(TextInput)``;
export const FamilyType = styled(SimpleContextDropdown)``;
export const IsRegistered = styled(SimpleContextDropdown)``;
export const IsTracked = styled(SimpleContextDropdown)``;
export const NotBlocked = styled(SimpleContextDropdown)``;
export const IsQInside = styled(SimpleContextDropdown)``;
export const Status = styled(SimpleContextDropdown)``;

//['program', 'status', 'lot', 'is_q_inside', 'is_tracked', 'not_blocked', 'is_registered'];
export const Error = styled(Text)`
  color: red;
  flex: 1 1 100%;
`;
