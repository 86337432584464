import { PublicClientApplication } from "@azure/msal-browser";
import { Client } from "@microsoft/microsoft-graph-client";
import React, { useState, createContext, useEffect, useRef } from "react";

export const AzureAuthContext = createContext({});

export const AzureAuthProvider = ({ children }) => {
  const [userProfile, setUserProfile] = useState({});
  const [isUserProfileDone, setIsUserProfileDone] = useState(false);
  const accessTokenGetter = useRef(null);

  const ACTIVE_DIRECTORY_SCOPES = ["user.read"];
  const azureClientApp = new PublicClientApplication({
    auth: {
      clientId: process.env.REACT_APP_AZURE_ACTIVE_DIRECTORY_APP_CLIENT_ID,
      redirectUri: process.env.REACT_APP_AZURE_ACTIVE_DIRECTORY_REDIRECT_URI,
      authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_ACTIVE_DIRECTORY_TENANT_ID}`,
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: true,
    },
  });

  const getUserDetails = (token) => {
    const client = Client.init({
      authProvider: (callback) => callback(null, token),
    });

    return client.api("/me").select("*").get();
  };

  const getAccessToken = async (reload = false) => {
    if (reload) accessTokenGetter.current = null;

    if (accessTokenGetter.current === null) {
      const accounts = azureClientApp.getAllAccounts();

      if (accounts.length <= 0) throw new Error("login_required");

      accessTokenGetter.current = azureClientApp
        .acquireTokenSilent({
          scopes: ACTIVE_DIRECTORY_SCOPES,
          account: accounts[0],
        })
        .then((result) => result.accessToken);
    }

    return accessTokenGetter.current;
  };

  const isAuthenticated = async (reload = false) => {
    try {
      return !!(await getAccessToken(reload));
    } catch {
      return false;
    }
  };

  const getUserProfile = async () => {
    try {
      const token = await getAccessToken();

      if (token) {
        console.log(await getUserDetails(token));
        setUserProfile(await getUserDetails(token));
        setIsUserProfileDone(true);
      }
    } catch (e) {
      setUserProfile({});
      setIsUserProfileDone(true);
    }
  };

  const login = async () => {
    await azureClientApp.loginPopup({
      prompt: "select_account",
      scopes: ACTIVE_DIRECTORY_SCOPES,
    });
    await getUserProfile();
  };

  const confirmPassword = async () => {
    try {
      await azureClientApp.acquireTokenPopup({
        prompt: "login",
        scopes: ACTIVE_DIRECTORY_SCOPES,
        loginHint: userProfile.mail,
        extraQueryParameters: {
          hsu: 1,
        },
      });
    } catch (e) {
      throw new Error("The password was not given");
    }
  };

  const logout = () => {
    return azureClientApp.logout();
  };

  useEffect(() => {
    getUserProfile();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AzureAuthContext.Provider
      value={{
        login,
        logout,
        isAuthenticated,
        userProfile,
        isUserProfileDone,
        confirmPassword,
      }}
    >
      {children}
    </AzureAuthContext.Provider>
  );
};
