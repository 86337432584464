import styled from "styled-components";
import { Button } from "../../../components/base";

export const UsersComponentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const UserShortcutsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Shortcut = styled(Button).attrs({ primary: true })``;
