import React, {useContext, useEffect, useState} from "react";
import { APIContext } from "../../../../contexts/APIContext";

import { ImplantReportContainer } from "./styles";
import { ImplantReportFilter } from "./ImplantReportFilter";
import { ShowLoading } from "../../../../alerts/ShowLoading";
import {Table} from "../../../base/Table";
import Pagination from "../../../pagination";

export const ImplantReport = () => {
  const { getImplantsReport, getCatalogByGroup } = useContext(APIContext);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(100);
    const [totalItems,setTotalItems] = useState(100);

  const handleOnSubmit = async (data) => {
      let payload ={
          ...data,
          totalItems: totalItems
      }
    setLoading(true);
    await getImplantsReport(payload)
      .then((response) => setResponse(response))
      .then(() => setLoading(false));
  };

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = response.slice(indexOfFirstRecord, indexOfLastRecord);
    const nPages = Math.ceil(response.length / recordsPerPage);

    return (
    <ImplantReportContainer>
      <ShowLoading loading={loading} />
      <ImplantReportFilter onSubmit={handleOnSubmit} />
        <div className="list__implants">
            <Table
                data={currentRecords}
                columns={[
                    "serial",
                    "electronic_serial",
                    "lot",
                    "family_type",
                    "is_registered",
                    "is_tracked",
                    "not_blocked",
                    "is_q_inside",
                    "status",
                ]}
                headingTranslationKey="reports.implants.data"
                padding="0"
            />
        </div>

        <div className="pt-4">
            <Pagination
                nPages={nPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalItems={totalItems}
                setTotalItems={setTotalItems}
                currentRecords={currentRecords}
            />
        </div>
    </ImplantReportContainer>
  );
};
