import styled from "styled-components";
import { Card,   CardTitle } from "../../base/Content.jsx";

export const DataCardView = styled(Card)``;

export const DataCardTitleArea = styled.div`
  display: flex;
`;

export const DataCardTitle = styled(CardTitle)`
  flex-basis: 50%;
  padding: 15px 0px;
  flex-grow: 1;
  min-width: 250px;
`;

export const Clickable = styled.button`
  border: 0px;
  margin: 0px;
  padding: 0px;
  background-color: transparent;
  text-align: inherit;
`;

export const DataCardTitleRight = styled.div`
  text-align: right;
  flex-basis: 50%;
`;
