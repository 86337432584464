import styled from "styled-components";
import { DropZone as BaseDropZone } from "../../../../base/DropZone";

export const UploadAreaContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
`;

export const DropZone = styled(BaseDropZone)`
  min-height: 200px;
`;
