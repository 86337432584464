import React, { useContext, useState } from "react";
import { Link as RRDLink, NavLink } from "react-router-dom";
import { NavigationContext } from "../../contexts/NavigationContext";
import { Icon } from "../icons";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { withModal } from "../modal";

const StyledLink = styled(RRDLink)`
  color: ${(props) => props.theme.$primary};
  text-decoration: none;
`;

const StyledNavLink = styled(NavLink)`
  color: ${(props) => props.theme.$primary};
  text-decoration: none;
`;

const StyledIcon = styled(Icon)`
  margin: 0px 5px;
`;

const StyledA = styled.a`
  color: ${(props) => props.theme.$primary};
  margin: 0px 5px;

  &:hover {
    color: ${(props) => props.theme.$primary};
  }
`;

const LinkContent = ({ icon, name }) => (
  <>
    {icon && <StyledIcon className="icon" icon={icon} />}
    <span className="text">{name}</span>
  </>
);

export const Link = ({
  to = "#",
  onClick,
  href,
  name,
  icon,
  action,
  modal,
  nav,
  back,
  big = false,
  children,
  ...props
}) => {
  const {actions} = useContext(NavigationContext);
  const {t} = useTranslation();
  let Component;

  const subMenu = actions[action] ? actions[action].subMenu : null
  const [showLinks, setShowLinks] = useState(false)

  if (back) name = `< ${t("general.back")}`;

  if (action in actions) {
      ({name, href, icon, modal} = actions[action]);
  }

  Component = onClick || modal ? StyledA : nav ? StyledNavLink : StyledLink;

  if (!href) {
    href = to;
  }

  if (onClick) {
    href = "#";
  }

  if (modal) {
    href = "#";
    Component = withModal(Component, modal);
  }

  const getMainComponent = () => {
    if (subMenu) {
      onClick = (e) => {
        e.preventDefault();
        setShowLinks(!showLinks);
        const navItems = document.querySelectorAll('.nav-item a')
        const lastItem = Array.from(navItems).pop()
        navItems.forEach(navItem => {
          if(navItem.classList.contains('active')) {
              navItem.classList.remove('active')
          }
            if (!showLinks) {
              lastItem.classList.add('active');
            }
        })
      };
    }

    return (
        <>
          <Component to={href} onClick={onClick} {...props}>
            {children || <LinkContent name={name} icon={icon}/>}
          </Component>
          {subMenu && showLinks && (
              <nav style={{
                display: "flex",
                fontSize: '12px',
                flexDirection: "column",
                gap: '0.5rem',
                padding: "3px 10px",
                marginLeft: "1rem"
              }}>
                <RRDLink style={{ textDecoration: "none", color: '#6e0072' }} to={subMenu[0].link}>{subMenu[0].title}</RRDLink>
                <RRDLink style={{ textDecoration: "none", color: '#6e0072' }} to={subMenu[1].link}>{subMenu[1].title}</RRDLink>
              </nav>
          )}
        </>
    );
  }

  return (
      getMainComponent()
  )
}

