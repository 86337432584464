import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  DistributorReportFilterContainer,
  FilterLabel,
  RangeStart,
  RangeEnd,
  Country,
  Distributor,
  Error,
} from "./styles";

import { Form, FormContext, SubmitButton } from "../../../../form";
import { Instruction } from "../../../../base";

const FormFields = () => {
  const { t } = useTranslation();
  const { formValues } = useContext(FormContext);

  return (
    <>
      <FilterLabel>{t("reports.distributor.filter.label")}</FilterLabel>
      <RangeStart
        name="startDate"
        placeholder={t("reports.distributor.filter.startDate")}
      />
      <RangeEnd
        name="endDate"
        placeholder={t("reports.distributor.filter.endDate")}
      />
      <Country
        name="country"
        placeholder={t("reports.distributor.filter.country")}
      />
      <Distributor
        name="distributor"
        placeholder={t("reports.distributor.filter.distributor")}
        country={formValues["country"]}
      />
      <SubmitButton>{t("general.filter")}</SubmitButton>
    </>
  );
};

export const DistributorReportFilter = ({ onSubmit, validate }) => {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const handleValidation = ({ country }) => {
    if (country === undefined) {
      setError(t("reports.distributor.filter.error.countryRequired"));
      return false;
    } else {
      setError(null);
      return true;
    }
  };
  return (
    <Form validate={handleValidation} onSubmit={onSubmit}>
      <Instruction>{t("reports.distributor.instructions")}</Instruction>
      <DistributorReportFilterContainer>
        <FormFields />
      </DistributorReportFilterContainer>
      {error && <Error>{error}</Error>}
    </Form>
  );
};
