import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "@bootstrap-styled/v4";
import React, { useState } from "react";
import styled from "styled-components";

export const SimpleInputDropdown = ({ options, name, value }) => {
  const [selected, setSelected] = useState(value);

  return (
    <>
      <SimpleDropdown
        selected={selected}
        onSelect={setSelected}
        options={options}
      />
      <input type="hidden" name={name} value={selected} />
    </>
  );
};

const StyledDropdown = styled(Dropdown)`
  button.btn-primary {
    background-color: ${(props) => props.theme["$clear-background"]};
    color: ${(props) => props.theme["$default-text"]};
    border-color: ${(props) => props.theme["$input-border-color"]};
    width: 100%;

    &:hover,
    &.active {
      background-color: ${(props) => props.theme["$light-primary"]};
      color: ${(props) => props.theme["$default-text"]};
    }

    &::after {
      font-size: 1.2em;
      text-align: bottom;
      line-height: 2em;
      color: ${(props) => props.theme["$primary"]};
    }
  }
`;

export const SimpleDropdown = ({ options, selected, onSelect, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (option) => (e) => {
    e.preventDefault();
    onSelect(option);
    setIsOpen(false);
  };

  return (
    <StyledDropdown isOpen={isOpen} toggle={() => setIsOpen((s) => !s)}>
      <DropdownToggle className="btn-secondary" {...props}  caret>{selected && options[selected]}</DropdownToggle>
      <DropdownMenu>
        {Object.entries(options).map(([option, label], idx) => (
            <div key={idx}>
                {label &&
                <DropdownItem onClick={handleSelect(option)}>
                    {label}
                </DropdownItem>
                }
            </div>
        ))}
      </DropdownMenu>
    </StyledDropdown>
  );
};
