import React, { useContext } from "react";

import { UsersList } from "../UsersList";
import { UsersLog } from "../UsersLog";
import {
  UsersComponentContainer,
  UserShortcutsContainer,
  Shortcut,
} from "./styles";

import { ActionList } from "../../actions/ActionList";
import { NavigationContext } from "../../../contexts/NavigationContext";
import { UsersContextProvider } from "../../../contexts/UsersContext";

export const UsersComponent = () => {
  const { userActions } = useContext(NavigationContext);
  return (
    <UsersContextProvider>
      <UsersComponentContainer>
        <UserShortcutsContainer>
          <ActionList actions={userActions} ItemComponent={Shortcut} />
        </UserShortcutsContainer>
        <UsersList />
        <UsersLog />
      </UsersComponentContainer>
    </UsersContextProvider>
  );
};
