import {useTranslation} from "react-i18next";
import React, {useContext, useState} from "react";
import {useFormik} from 'formik';
import * as Yup from "yup";

import {Button} from "@bootstrap-styled/v4";
import {FormControlLabel, Grid, RadioGroup, Typography, Box, Radio} from "@mui/material";
import {styled} from "@mui/material/styles";

import {APIContext} from "../../contexts/APIContext";
import {PatientContext} from "../../contexts/PatientContext";

import {CustomModal} from "../../components/modal";

const RbIcon = styled("span")(() => ({
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow: "inset 0 0 0 1px #e5d6e7",
}));

const RbCheckedIcon = styled(RbIcon)({
    backgroundColor: "#e5d6e7",
    backgroundImage: "#e5d6e7",
    "&:before": {
        display: "block",
        width: 16,
        height: 16,
        backgroundImage: "radial-gradient(#540d5a,#540d5a 28%,transparent 32%)",
        content: '""'
    }
});

function CustomRadio(props) {
    return (
        <Radio
            disableRipple
            color="default"
            checkedIcon={<RbCheckedIcon/>}
            icon={<RbIcon/>}
            {...props}
        />
    );
}

export const ReSendEmailModal = ({data, ...props}) => {

    const {t} = useTranslation();
    const {postSendEmails} = useContext(APIContext);
    const {patient} = useContext(PatientContext);
    const [alertMessage, setAlertMessage] = useState({})

    const SendMailSchema = Yup.object({
        email: Yup.string().email('Invalid email'),
    });

    const formik = useFormik({
        initialValues: {
            email: '',
            typeOfImplants: 'B',
            addSecondEmail: 'no',
            lang: 'en',
        },
        validationSchema: SendMailSchema,
        onSubmit: values => {
            let payload = {
                emails: [patient.email],
                emailsCopy: values.email ? [values.email] : [],
                type: values.typeOfImplants,
                lang: values.lang
            }
            postSendEmails(payload).then((data) => {
                setAlertMessage({
                    message: data.message,
                    status: 200
                });
                formik.resetForm();
            }).catch((e) => {
                setAlertMessage({
                    message: e.response?.data?.message?.error_message,
                    status: e.response?.status
                });
            }).finally(() => {
                setTimeout(() => {
                    setAlertMessage({});
                }, 5000)
            });
        },
    });


    return (
        <CustomModal {...props} title={t("action.sendWarrantyOrRegistrationEmail")} alertMessage={alertMessage}
                     formik={formik} size="lg">
            <form onSubmit={formik.handleSubmit}>
                <Grid container mb={5} mt={10} display="flex" alignItems="center">
                    <Grid item md={4}>
                        <Typography variant="body2" sx={{font: "normal normal 700 16px/16px Avenir"}}>{t("reSendEmail.typeOfImplants")}</Typography>
                    </Grid>
                    <Grid item md={2}></Grid>
                    <Grid item md={4} mt={{xs:2,md:0}}>
                        <Box>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="typeOfImplants"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.typeOfImplants}
                                sx={{display: 'flex', justifyContent: 'space-evenly'}}
                            >
                                <FormControlLabel value="B" control={<CustomRadio/>} label={<Typography style={{fontFamily: 'Avenir'}}>Breasts</Typography>}/>
                                <FormControlLabel value="G" control={<CustomRadio/>} label={<Typography style={{fontFamily: 'Avenir'}}>Gluteal</Typography>}/>
                            </RadioGroup>
                        </Box>
                    </Grid>
                </Grid>

                <Grid container mb={4} mt={4} display="flex" alignItems="center">
                    <Grid item xs={12} md={6.1}>
                        <Typography variant="body2" sx={{font: "normal normal 700 16px/16px Avenir"}}>{t("reSendEmail.selectLanguage")}</Typography>
                    </Grid>
                    <Grid item xs={12} md={5} mt={{xs:2,md:0}}>
                        <Box>
                            <select
                                name="lang"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.lang}
                                style={{
                                    borderRadius: 36,
                                    border: 0,
                                    background: '#f3f3f4',
                                    height: 45,
                                    paddingLeft: 15,
                                    outline: 'none', width: '100%',
                                    borderRight: '16px solid transparent',
                                    fontFamily: 'Avenir'
                                }}>
                                <option value="en">English</option>
                                <option value="es">Español</option>
                                <option value="it">Italian</option>
                                <option value="de">German</option>
                                <option value="fr">French</option>
                                <option value="sv">Swedish</option>
                                <option value="da">Danish</option>
                                <option value="nn">Norwegian</option>
                                <option value="pt">Portuguese</option>
                            </select>
                        </Box>
                    </Grid>
                </Grid>

                <Grid container my={4} display="flex" alignItems="center">
                    <Grid item xs={12} md={6.1}>
                        <Typography variant="body3" sx={{font: "normal normal 700 16px/16px Avenir"}}>CC</Typography>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <Box>
                            <input type="text"
                                   name="email"
                                   onBlur={formik.handleBlur}
                                   onChange={formik.handleChange}
                                   value={formik.values.email}
                                   style={{
                                       borderRadius: 36,
                                       border: 0,
                                       background: '#f3f3f4',
                                       height: 45,
                                       paddingLeft: 15,
                                       outline: 'none', width: '100%',
                                       fontFamily: 'Avenir'
                                   }}/>
                            <Box pl={2} mt={1.5}>
                                {formik.errors.email && formik.touched.email ? (
                                    <Box sx={{color: 'red', fontSize: 12}}>{formik.errors.email}</Box>) : null}
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" mt={10}>
                    <Button variant="primary" type="submit" style={{
                        height: 45, padding: '0px 25px', border: 0,
                        borderRadius: 50, textTransform: 'uppercase'
                    }}>
                        {t("general.sendMail")}
                    </Button>

                    <Box mt={3}>
                        <Typography variant="body2" fontSize={16} color="secondary" onClick={() => {
                            props.onHide();
                            formik.resetForm();
                        }}
                        sx={{cursor: 'pointer', color: '#6e0072', textTransform: 'uppercase'}}>{t("edit.cancel")}</Typography>
                    </Box>
                </Box>
            </form>
        </CustomModal>
    );
};
