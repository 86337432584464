import { useTranslation } from "react-i18next";
import React from "react";
import { PatientSearchResultsDescription } from "./styles";

export const PatientSearchCriteria = ({ criteria }) => {
  const { t } = useTranslation();
  if (!criteria) return <p />;

  const [field, filter] = Object.entries(criteria)[0];
  return (
    <PatientSearchResultsDescription>
      {t("search.results")}&nbsp;{t(`search.criteria.${field}`)}&nbsp;
      <i>{JSON.stringify(filter)}</i>
    </PatientSearchResultsDescription>
  );
};
