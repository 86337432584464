import React, { useContext, } from "react";
import { SimpleContextDropdown } from "./SimpleContextDropdown";
import { NavigationContext } from "../../contexts/NavigationContext";

export const ImplantStatusSelect = ({ name, data, ...props  }) => {
  const {
    objectOptions: { implantStatus },
  } = useContext(NavigationContext);

  return (
    <SimpleContextDropdown name={name} value={data.toString()} options={implantStatus} {...props} />
  );
};
