import styled from "styled-components";

export const StyledDropZone = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme["$lighter-text"]};
  background-color: ${({ theme }) => theme["$shaded-background"]};
  border: 2px dashed ${({ theme }) => theme["$light-text"]};
  padding: 30px;
`;
