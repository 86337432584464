import { format, parseISO } from "date-fns";

export const hidratePatientSearchResponse = (response) => {
  if ("error" in response) {
    return { resultsCount: 0, results: [] };
  } else {
    const { resultsCount, results } = response;

    return {
      resultsCount,
      results: results.map(hidratePatient),
    };
  }
};

export const hidratePatient = (patient) => {
  return {
    ...patient,
    id: patient.userId,
    fullName: `${patient.firstName} ${patient.lastName}`,
    country: patient.country?.toString() || "",
    implants: patient?.implants?.map((implant) =>
      hidrateImplant({ ...implant, isRegistered: !!patient?.userId })
    ),
  };
};

export const hidrateImplant = ({ dateOfRegistration, ...implant }) => {
  const esnFix = (esn) => (esn === 0 ? "-" : esn);
  const getStatus = ({ warranty: { endDate }, isExplanted }) => {
    // 0: No Warranty
    // 1: Active
    // 2: Expired
    // 3: Inactive

    if (isExplanted) {
      return 3; // Inactive
    } else if (endDate) {
      const end = new Date(endDate);
      const today = new Date();

      if (today <= end) {
        return 1; // Active
      } else if (today > end) {
        return 2; // Expired
      }
    } else {
      return 0;
    }
  };
  //   extendedWarranty: { canBuyExtendedWarranty: 0, program: '2Y', extendedWarrantyBuyExtendedRejectionReason: 1, isActive: false,
  //
  //   19120815-09
  return {
    ...implant,
    isExplanted: !!implant.isRegistered && !!implant.isExplanted,
    isBlocked: !implant.isBlocked ? 1 : 0,
    implantType: implant?.reference?.startsWith("G") ? "G" : "B",
    electronicSerialNumber: esnFix(
      implant.electronicSerialNumber || implant.electronic_serial
    ),
    surgery: implant.surgery && {
      dateOfRegistration,
      ...hidrateSurgery(implant.surgery),
    },
    warranty: {
      ...hidrateWarranty(implant.warranty),
      status: getStatus(implant),
    },
  };
};

const hidrateWarranty = (warranty) => ({
  program:
    warranty?.order?.transaction_id === "FREE YEAR"
      ? `${warranty.program}/FREE YEAR`
      : warranty.program,
  order:
    warranty?.order &&
    warranty?.order?.transaction_id !== "FREE YEAR" &&
    hidrateOrder(warranty.order),
  ...warranty,
});

const hidrateSurgery = (surgery) => ({
  ...surgery,
  incisionKind: surgery?.incisionKind?.toString(),
  implantPlacement: surgery?.implantPlacement?.toString(),
  indication: surgery?.indication?.toString(),

  dateOfSurgery:
    surgery && new Date(surgery.dateOfSurgery || null).toISOString(),
  surgeryCountry: surgery?.surgeryCountry?.toString(),
});

const hidrateOrder = (order) => {
  return {
    ...order,
    transactionId: order.transactionId || order.transaction_id,
  };
};

export const fixPostImplantData = (data) => ({
  ...data,
  isBlocked: data.isBlocked === 1 ? 0 : 1,
});

export const fixPostSurgeryData = ({ surgeonId, ...data }) => ({
  ...data,
  doctorId: surgeonId,
  dateOfSurgery: data.dateOfSurgery?.replace(/T.*$/, ""),
});

export const fixPostWarrantyExtendData = ({ extendedDate, ...data }) => ({
  ...data,
  extendedDate: format(parseISO(extendedDate), "yyyy-MM-dd"),
});

export const hidrateUser = (data) => ({
  ...data,
  isActive: data.active,
  roleId: data.role?.id,
});

export const hidrateUsers = (data) => data.map(hidrateUser);

export const hidrateUsersActivityLog = (data) =>
  data.map(({ created_at, ...data }) => ({ ...data, createdAt: created_at }));

export const fixPostUser = ({ isActive, ...data }) => ({
  ...data,
  active: isActive ? 1 : 0,
});

export const fixDistributorReportQuery = ({ startDate, endDate, ...data }) => ({
  ...data,
  id: -1,
  start_date: format(parseISO(startDate), "yyyy-MM-dd"),
  end_date: format(parseISO(endDate), "yyyy-MM-dd"),
});
