import styled from "styled-components";

export const StyledSwitch = styled.div`
  position: relative;
  width: 2em;
  height: 1.5em;
  display: inline-block;
  vertical-align: middle;
  text-align: left;

  .switch-checkbox {
    display: none;
  }
  .switch-inner {
    position: absolute;
    top: 0;
    display: block;
    background-color: ${(props) => props.theme["$light-text"]};
    border-radius: 20px;
    width: 2em;
    height: 1.5em;
    transition: all 0.2s ease-in 0s;
  }

  .switch-switch {
    position: absolute;
    top: 0;
    right: 0.5em;
    margin: 0.25em;
    background-color: white;
    display: block;
    width: 1em;
    height: 1em;
    border-radius: 20px;
    transition: all 0.2s ease-in 0s;
  }

  .switch-checkbox:checked + .switch-label {
    .switch-inner {
      margin-left: 0;
      background-color: ${(props) => props.theme["$primary"]};
    }
    .switch-switch {
      right: 0px;
    }
  }
`;
