import React, { useContext, useEffect, useState } from "react";
import { APIContext } from "../../contexts/APIContext";
import { DefaultTypeahead } from "./DefaultTypeahead";

export const DistributorSelect = ({ name, country, data, placeholder }) => {
  const { getDistributors } = useContext(APIContext);
  const [distributors, setDistributors] = useState([]);
  const fromData = (data) => [
    {
      id: data,
      label: distributors[data] || "",
    },
  ];

  useEffect(() => {
    if (country) {
      getDistributors(country)
        .then((data) =>
          Object.fromEntries(data.map(({ id, name }) => [id, name]))
        )
        .then(setDistributors);
    } else {
      setDistributors([]);
    }
  }, [country]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DefaultTypeahead
      name={name}
      data={fromData(data)}
      placeholder={placeholder}
      entries={distributors}
    />
  );
};
