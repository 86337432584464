import { addDays, differenceInCalendarDays, formatISO } from "date-fns";
import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Instruction } from "../../components/base";
import {
  DatePicker,
  Form,
  DefaultTextInput,
  SubmitButton,
} from "../../components/form";
import { BaseModal } from "../../components/modal";
import { PatientContext } from "../../contexts/PatientContext";
import { ActionFooter, Container, Description } from "./styles";
import { ConfigContext } from "../../contexts/ConfigContext";

export const ExtendWarrantyModal = ({ data, ...props }) => {
  const { t } = useTranslation();
  const { postWarrantyExtend } = useContext(PatientContext);

  return (
    <BaseModal {...props} title={t("action.extendWarranty")}>
      <Container>
        <Form onFinishEdit={props.onHide} onSubmit={postWarrantyExtend}>
          <ExtendWarrantyModalFormContents name="extendedDate" data={data} />
        </Form>
      </Container>
    </BaseModal>
  );
};

const ExtendWarrantyModalFormContents = ({ name, data }) => {
  const { t } = useTranslation();
  const { minWarrantyExtendDays, maxWarrantyExtendDays } =
    useContext(ConfigContext);
  const fixData = (data) =>
    Math.min(
      maxWarrantyExtendDays,
      Math.max(minWarrantyExtendDays, parseInt(data) || 1)
    );
  const calculateDate = (data) => formatISO(addDays(new Date(), fixData(data)));

  const [dateDiff, setDateDiff] = useState(parseInt(data));
  const [date, setDate] = useState(calculateDate(parseInt(data)));
  const handleOnInputChange = (e) => {
    const { value } = e.target;
    setDateDiff(value);
    setDate(calculateDate(value));
  };
  const handleOnDateChange = (value) => {
    setDateDiff(differenceInCalendarDays(Date.parse(value), new Date()));
  };

  return (
    <>
      <Description>{t("extendWarranty.instructions")}</Description>
      <Instruction>{t("extendWarranty.instructionDays")}</Instruction>
      <DefaultTextInput
        type="number"
        value={dateDiff}
        min={minWarrantyExtendDays}
        max={maxWarrantyExtendDays}
        onChange={handleOnInputChange}
      />
      <Instruction>{t("extendWarranty.instructionDate")}</Instruction>
      <DatePicker
        name={name}
        minDate={addDays(new Date(), minWarrantyExtendDays)}
        maxDate={addDays(new Date(), maxWarrantyExtendDays)}
        data={date}
        onChange={handleOnDateChange}
      />
      <ActionFooter>
        <SubmitButton>{t("general.continue")}</SubmitButton>
      </ActionFooter>
    </>
  );
};

ExtendWarrantyModalFormContents.propTypes = {
  name: PropTypes.string,
  data: PropTypes.number,
};
