import React, {useContext, useEffect, useState} from "react";
import {APIContext} from "../../../../contexts/APIContext";

import {ExplantsInvestigationReportContainer} from "./styles";
import fileDownload from "js-file-download";
import {TableComponent} from "../../../base/TableComponent";
import {isEmpty, isNumber} from "lodash";
import {Button, Grid, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import * as Yup from "yup";
import {useFormik} from "formik";
import {useTranslation} from "react-i18next";
import {SerialNumber} from "../../../form/SerialComponent";
import {CustomModal} from "../../../modal";

const bottonStyle = {
    height: 45, padding: '0px 25px', border: 0,
    borderRadius: 50, textTransform: 'uppercase', color: '#fff',
    backgroundColor: '#6e0072'
}

export const ExplantsInvestigationReport = () => {
    const {t} = useTranslation();

    const {getDeviceTrackingTempReport, postCompleteTemporaryExplant} = useContext(APIContext);
    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState([]);
    const [openModal, setOpenModal] = React.useState(false);
    const [alertMessage, setAlertMessage] = useState({})

    const getData = (page) => {
        setLoading(true)
        getDeviceTrackingTempReport().then((response) => {
            setResponse(response);
        }).finally(() => setLoading(false));
    }

    useEffect(() => {
        getData(1);
    }, [])

    const formik = useFormik({
        initialValues: {
            lot: "",
            surgeonExplantEmail: "",
            patientEmail: "",
            serial: {
                SNLeft: "",
                VCLeft: "",
                isValidLeft: false,
                SNRight: "",
                VCRight: "",
                isValidRight: false,
            },
        },
        onSubmit: (values) => {

            let payload = {
                lot: values.lot,
                serialNumber: values.serial.SNLeft,
                surgeonExplantEmail: values.surgeonExplantEmail,
                patientEmail: values.patientEmail,
            }

            postCompleteTemporaryExplant(payload).then((data) => {
                setAlertMessage({
                    message: data.message,
                    status: data.status
                });
                formik.resetForm();
            }).catch((e) => {
                setAlertMessage({
                    message: e.response?.data?.message?.error_message,
                    status: e.response?.status
                });
            }).finally(() => {
                setTimeout(() => {
                    setAlertMessage({});
                    closeModal()
                    getData(1)
                }, 3000)
            });
        },
    });

    const newItem = (row) => {
        formik.setFieldValue("lot", row.lot)
        formik.setFieldValue("patientEmail", row.patient_email)
        formik.setFieldValue("surgeonExplantEmail", row.doctor_name)
        setOpenModal(true);
    }

    const closeModal = () => {
        setOpenModal(false);
        formik.resetForm();
    }

    const renderAddSN = (row) => {
        return <Box sx={{color: ' #6e0072', cursor: 'pointer', '&:hover': {textDecoration: 'underline'}}}
                    onClick={() => newItem(row)}> <Typography variant="body2">ADD SN</Typography> </Box>
    }

    const data = [
        {label: 'Lot', value: 'lot'},
        {label: 'Patient Email', value: 'patientEmail'},
        {
            label: 'SN',
            component: (props) => (
                <SerialNumber
                    autoMask
                    serial="serial.SNLeft"
                    side={"Left"}
                    formik={props.formik}
                    isExplantedFlow={true}
                />
            ),
        },
    ];

    const columns = [
        {label: "Serial", keyValue: "serial", minWidth: "200px"},
        {label: "Lot", keyValue: "lot"},
        {label: "Surgeon name", keyValue: "doctor_name"},
        {label: "Current Status", keyValue: "status"},
        {label: "Date", keyValue: "date"},
        {label: "Reason", keyValue: "reason"},
        {label: "Patient Name", keyValue: "patient_name"},
        {label: "Address", keyValue: "device_address"},
        {label: "SS Number", keyValue: "ss_number"},
        {label: "Email", keyValue: "patient_email"},
    ];

    return (
        <ExplantsInvestigationReportContainer>
            <Box sx={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
                <Button variant="primary" type="submit" onClick={()=> getData(1)} style={{
                    ...bottonStyle
                }}>
                    {t("general.refresh")}
                </Button></Box>
            <TableComponent rows={response} columns={columns} fetchData={getData} isLoading={loading} tableHeight={400} download={false} startActions={renderAddSN}/>
            {openModal && <CustomModal show={openModal} title={t("reports.explantsInvestigation.titleModal")}
                                       alertMessage={alertMessage}
                                       formik={formik} size="lg" close={closeModal}>
                <form onSubmit={formik.handleSubmit}>

                    <Box pt={4}>
                        {data.map((item, index) => (
                            <Grid container my={2} display="flex" alignItems="center">
                                <Grid xs={12} md={4.5}>
                                    <Typography fontWeight={600}>{item.label}</Typography>
                                </Grid>
                                <Grid item xs={12} md={7.5}>
                                    {item.component ? (
                                        item.component({formik})
                                    ) : (
                                        formik.values[item.value]
                                    )}
                                </Grid>
                            </Grid>
                        ))}
                    </Box>

                    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" mt={10}>
                        <Button variant="primary" type="submit" style={{
                            ...bottonStyle,
                            backgroundColor: formik.values.serial?.isValidLeft ? '#6e0072' : '#999999'
                        }} disabled={!formik.values.serial?.isValidLeft}>
                            {t("reports.explantsInvestigation.send")}
                        </Button>

                        <Box mt={3}>
                            <Typography variant="body2" fontSize={16} color="secondary" onClick={() => {
                                closeModal();
                            }}
                                        sx={{
                                            cursor: 'pointer',
                                            color: '#6e0072',
                                            textTransform: 'uppercase'
                                        }}>{t("edit.cancel")}</Typography>
                        </Box>
                    </Box>
                </form>
            </CustomModal>}
        </ExplantsInvestigationReportContainer>
    );
};
