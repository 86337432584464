import { Row, Col } from "../styles";
import { DataDetail } from "../../../data";

export const TableRows = ({
  data = [],
  columns = [],
  Wrapper = {},
  format = {},
}) => {
  return (
      <>
      {data.map((row, r) => (
        <Row key={r}>
          {columns.map((v, c) => (
            <Col key={c}>
              <DataDetail
                name={v}
                data={row[v]}
                rowData={row}
                format={v in format && format[v]}
                Wrapper={v in Wrapper && Wrapper[v]}
              />
            </Col>
          ))}
        </Row>
      ))}
    </>
  );
};
