import React from "react";
import { SimpleDropdown } from "../base/SimpleDropdown";
import { useFormValue } from "./Form";

export const SimpleContextDropdown = ({ options, name, value, autoSubmit, ...props }) => {
  const { formValue, setFormValue } = useFormValue(name, value);

  const handleSelect = (value) => {
    setFormValue(value);
  };

  return (
    <>
      <SimpleDropdown
        selected={formValue}
        onSelect={(value) => handleSelect(value)}
        options={options}
        {...props}
      />
    </>
  );
};
