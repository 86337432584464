import React, { useContext, } from "react";
import { SimpleContextDropdown } from "./SimpleContextDropdown";
import { NavigationContext } from "../../contexts/NavigationContext";

export const ImplantSourceSelect = ({ name, data, ...props  }) => {
  const {
    objectOptions: { implantSource },
  } = useContext(NavigationContext);

  return (
    <SimpleContextDropdown name={name} value={data.toString()} options={implantSource} {...props} />
  );
};
