import React from "react";
import styled from "styled-components";

const Svg = styled.svg.attrs({
  version: "1.1",
  xmlns: "http://www.w3.org/2000/svg",
  xmlnsXlink: "http://www.w3.org/1999/xlink",
})``;

const Logo = ({ className }) => (
  <Svg
    id="Logo_Motiva"
    data-name="Logo Motiva"
    xmlns="http://www.w3.org/2000/svg"
    width="149"
    height="36.881"
    className={className}
    viewBox="0 0 149 36.881"
  >
    <g id="Group_956" data-name="Group 956" transform="translate(37.118 7.434)">
      <path
        id="Path_153"
        data-name="Path 153"
        d="M686.084,1535.38c.185-1.5.315-2.618.4-3.337a21.119,21.119,0,0,0,.119-2.3l1.012-16.462.067-4.483v-1.776l3.951-.008,9.345,21.423,9.031-21.415h3.946v.722c0,.671.014,1.479.046,2.429s.071,1.991.126,3.112l.978,16.456.208,2.491.22,2.338.165,1.448h-3.931v-.843q0-1.247-.066-2.839t-.119-2.594l-1.16-18.591-9.206,21.668h-2.092l-9.416-21.519-1.1,18.442-.1,5.549v.727H686Z"
        transform="translate(-686.002 -1507.019)"
        fill="currentColor"
      />
      <path
        id="Path_154"
        data-name="Path 154"
        d="M936.875,1568.069a7.667,7.667,0,0,1,6.268,2.859,11.782,11.782,0,0,1,2.334,7.688,11.966,11.966,0,0,1-2.334,7.728,8.264,8.264,0,0,1-12.545.017,11.936,11.936,0,0,1-2.327-7.745,11.781,11.781,0,0,1,2.327-7.688,7.679,7.679,0,0,1,6.278-2.859m-5.284,10.547a18.729,18.729,0,0,0,.364,4.083,8.378,8.378,0,0,0,1.573,3.142,3.971,3.971,0,0,0,3.347,1.592,4.182,4.182,0,0,0,2.012-.548,5.217,5.217,0,0,0,1.75-1.569,7.307,7.307,0,0,0,1.129-2.636,16.93,16.93,0,0,0,.381-3.868,19.605,19.605,0,0,0-.361-4.224,6.936,6.936,0,0,0-1.149-2.618,5.6,5.6,0,0,0-1.75-1.573,4.212,4.212,0,0,0-2.012-.522,3.946,3.946,0,0,0-3.387,1.591,8.782,8.782,0,0,0-1.533,3.122,18.254,18.254,0,0,0-.364,4.029"
        transform="translate(-895.414 -1559.79)"
        fill="currentColor"
      />
      <path
        id="Path_155"
        data-name="Path 155"
        d="M1064.434,1527.337h3.392v-2.976l-.175-3.746h3.283v6.722h4.243v1.583h-4.243v13.71a5.638,5.638,0,0,0,.465,2.713,1.743,1.743,0,0,0,1.636.787,3.762,3.762,0,0,0,2.463-1.049v2.251a7.871,7.871,0,0,1-1.565.535,7.6,7.6,0,0,1-1.608.162q-4.5,0-4.5-4.628V1528.92h-3.392Z"
        transform="translate(-1013.111 -1518.771)"
        fill="currentColor"
      />
      <path
        id="Path_156"
        data-name="Path 156"
        d="M1162.035,1518.057a1.728,1.728,0,0,1,1.932,1.981q0,2.01-2.131,2.011-2.177,0-2.178-2.011,0-1.983,2.376-1.981m-2.09,26.814q.171-1.086.264-2.632c.056-1.031.088-2,.088-2.912V1528.57a12.429,12.429,0,0,0-.071-1.53c-.042-.314-.138-.944-.281-1.884h3.484v16.552l.158,3.162v.693H1159.9Z"
        transform="translate(-1095.421 -1516.56)"
        fill="currentColor"
      />
      <path
        id="Path_157"
        data-name="Path 157"
        d="M1212.989,1570.179c.564,2.115.889,3.356.986,3.716s.563,1.886,1.41,4.563,1.928,5.712,3.246,9.087q1.121-2.614,1.917-4.681c.536-1.383,1.01-2.708,1.421-3.984s.818-2.611,1.22-4.017.812-2.971,1.235-4.683h2.22q-1.042,3.9-1.48,5.371t-1.424,4.364c-.653,1.931-1.244,3.577-1.762,4.931s-1.289,3.279-2.3,5.773h-2.853c-.969-2.224-1.769-4.126-2.406-5.692s-1.32-3.422-2.077-5.554-1.717-5.2-2.9-9.192Z"
        transform="translate(-1138.45 -1561.613)"
        fill="currentColor"
      />
      <path
        id="Path_158"
        data-name="Path 158"
        d="M1360.618,1572.666a3.41,3.41,0,0,0-1.577-2.891,6.775,6.775,0,0,0-4.04-1.13,10.739,10.739,0,0,0-5.052,1.431v-2.725a30.343,30.343,0,0,1,5-.443,12.213,12.213,0,0,1,6.464,1.446,4.555,4.555,0,0,1,2.315,4.066v11.066a18.022,18.022,0,0,0,.16,2.7l.438,1.607H1360.9a5.1,5.1,0,0,1-.285-1.6v-.522a14.273,14.273,0,0,1-3.189,1.882,8.048,8.048,0,0,1-2.99.561,6.488,6.488,0,0,1-4.583-1.629,5.478,5.478,0,0,1-1.754-4.2,5.378,5.378,0,0,1,1.67-4,16.6,16.6,0,0,1,5.615-3.116l5.233-1.731Zm0,2.327-4.4,1.589q-4.979,2.139-4.983,5.366a3.746,3.746,0,0,0,1.229,2.926,4.585,4.585,0,0,0,3.2,1.109,7.381,7.381,0,0,0,4.951-2.011Z"
        transform="translate(-1258.306 -1558.785)"
        fill="currentColor"
      />
      <path
        id="Path_159"
        data-name="Path 159"
        d="M1468.108,1520.963a2.782,2.782,0,0,1,.23-1.129,2.954,2.954,0,0,1,1.547-1.547,2.879,2.879,0,0,1,2.258,0,2.952,2.952,0,0,1,1.547,1.547,2.878,2.878,0,0,1,0,2.258,2.954,2.954,0,0,1-1.547,1.547,2.879,2.879,0,0,1-2.258,0,2.956,2.956,0,0,1-1.547-1.547,2.783,2.783,0,0,1-.23-1.129m.422,0a2.414,2.414,0,0,0,.2.969,2.482,2.482,0,0,0,1.32,1.32,2.5,2.5,0,0,0,1.938,0,2.485,2.485,0,0,0,1.32-1.32,2.5,2.5,0,0,0,0-1.938,2.483,2.483,0,0,0-1.32-1.32,2.5,2.5,0,0,0-1.938,0,2.479,2.479,0,0,0-1.32,1.32,2.413,2.413,0,0,0-.2.969m1.851,1.664h-.422v-3.352h1.289a1.133,1.133,0,0,1,.812.238.927.927,0,0,1,.243.692,1.073,1.073,0,0,1-.066.4.8.8,0,0,1-.184.277.9.9,0,0,1-.27.18,1.475,1.475,0,0,1-.324.1l1,1.469h-.476l-1-1.469h-.6Zm0-1.844h.539c.115,0,.229,0,.344-.012a.938.938,0,0,0,.308-.07.54.54,0,0,0,.223-.172.528.528,0,0,0,.086-.324.5.5,0,0,0-.066-.27.509.509,0,0,0-.172-.168.786.786,0,0,0-.242-.09,1.385,1.385,0,0,0-.27-.027h-.75Z"
        transform="translate(-1362.038 -1516.56)"
        fill="currentColor"
      />
    </g>
    <g id="Group_957" data-name="Group 957">
      <path
        id="Path_160"
        data-name="Path 160"
        d="M515.4,1528.553a76.986,76.986,0,0,1-5.371,9.636,42.6,42.6,0,0,1,8.327.313c6.326-5.126,8.261-9.039,8.293-9.1v0a17.842,17.842,0,0,0-11.248-.843"
        transform="translate(-496.285 -1517.412)"
        fill="currentColor"
      />
      <path
        id="Path_161"
        data-name="Path 161"
        d="M473.464,1607.692a42.662,42.662,0,0,0-8.059-.19c-2.171,3.3-4.447,6.363-6.474,8.933a75.587,75.587,0,0,0,14.533-8.743"
        transform="translate(-452.116 -1586.227)"
        fill="currentColor"
      />
      <path
        id="Path_162"
        data-name="Path 162"
        d="M419.477,1483.356c3.657-4.363,11.091-10.189,17.177-11.5,2.589-5.765,2.66-8.653,2.661-8.675-11.422-1.485-18.19,14.838-19.838,20.173"
        transform="translate(-418.013 -1461.063)"
        fill="currentColor"
      />
      <path
        id="Path_163"
        data-name="Path 163"
        d="M421.439,1544.987a57.077,57.077,0,0,1,11.19-1.954,77.9,77.9,0,0,0,5.359-9.473c-8.051,2.251-14.416,8.953-16.549,11.426"
        transform="translate(-419.709 -1522.228)"
        fill="currentColor"
      />
      <path
        id="Path_164"
        data-name="Path 164"
        d="M473.4,1610.509a75.973,75.973,0,0,1-15.6,9.427,70.074,70.074,0,0,0,24.486-.67c0-1.016,0-4.8,0-5.879a26.775,26.775,0,0,0-8.88-2.879"
        transform="translate(-451.137 -1589.039)"
        fill="currentColor"
      />
      <path
        id="Path_165"
        data-name="Path 165"
        d="M415.752,1610.806a18.392,18.392,0,0,0,4.5,7.317c2.8-3.477,5.095-6.641,6.991-9.495a54.947,54.947,0,0,0-11.495,2.177"
        transform="translate(-414.793 -1587.301)"
        fill="currentColor"
      />
      <path
        id="Path_166"
        data-name="Path 166"
        d="M466.247,1685.447a73.194,73.194,0,0,1-10.921-.835,18.78,18.78,0,0,0,12.248,4.755v-3.935c-.441.008-.883.014-1.327.014"
        transform="translate(-449 -1653.092)"
        fill="currentColor"
      />
      <path
        id="Path_167"
        data-name="Path 167"
        d="M413.669,1460.775a26.78,26.78,0,0,1,7.089-8.5c-4.8-4.078-11.708-4.12-12.029-4.122.171,3.545-.486,19.609.568,22.744a53.648,53.648,0,0,1,4.372-10.125"
        transform="translate(-408.681 -1448.156)"
        fill="currentColor"
      />
      <path
        id="Path_168"
        data-name="Path 168"
        d="M551.054,1681.538a18.823,18.823,0,0,0,11.839,3.954v-5.367a64.417,64.417,0,0,1-11.838,1.413"
        transform="translate(-531.745 -1649.214)"
        fill="currentColor"
      />
      <path
        id="Path_169"
        data-name="Path 169"
        d="M552.536,1473.361c-.127.308-.26.616-.4.925a18.5,18.5,0,0,1,10.636.775,16.048,16.048,0,0,0-8.142-8.945,26.448,26.448,0,0,1-2.1,7.245"
        transform="translate(-532.685 -1463.68)"
        fill="currentColor"
      />
      <path
        id="Path_170"
        data-name="Path 170"
        d="M508.425,1449.542a22.155,22.155,0,0,0-6.941-1.385v3.762a13.027,13.027,0,0,1,6.941-2.377"
        transform="translate(-488.898 -1448.157)"
        fill="currentColor"
      />
      <path
        id="Path_171"
        data-name="Path 171"
        d="M580.844,1550.73q-1.341,1.3-2.88,2.577a27.254,27.254,0,0,1,8.189,2.685c-.051-2.919.244-8.321-.534-11.007a30.169,30.169,0,0,1-4.775,5.744"
        transform="translate(-555.006 -1532.133)"
        fill="currentColor"
      />
    </g>
  </Svg>
);

const Isotype = ({ className }) => (
  <Svg viewBox="0 0 69.16 72.3" className={className}>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Screen_1" data-name="Screen 1">
        <path
          fill="currentColor"
          d="M37,22A151.27,151.27,0,0,1,26.56,40.72c1.68-.09,3.41-.15,5.18-.15a78.3,78.3,0,0,1,11,.75C55,31.39,58.72,23.8,58.78,23.68h0A34.15,34.15,0,0,0,45.56,21,37.13,37.13,0,0,0,37,22Z"
        />
        <path
          fill="currentColor"
          d="M41.3,42.44a77.91,77.91,0,0,0-9.56-.57c-2.07,0-4.1.07-6.07.2-4.21,6.41-8.62,12.34-12.55,17.32A146.4,146.4,0,0,0,41.3,42.44Z"
        />
        <path
          fill="currentColor"
          d="M2.75,43.22A89.35,89.35,0,0,1,13.81,32.66,67.25,67.25,0,0,1,28,23.78a47.11,47.11,0,0,1,8.06-2.85c5-11.18,5.16-16.78,5.16-16.82h0A23,23,0,0,0,38.61,4C17.47,4,6,33.35,2.75,43.22Z"
        />
        <path
          fill="currentColor"
          d="M3.26,44.61A110.53,110.53,0,0,1,25,40.82,152.59,152.59,0,0,0,35.35,22.46C19.74,26.82,7.4,39.81,3.26,44.61Z"
        />
        <path
          fill="currentColor"
          d="M43.08,42.69c-2.5,2-5.18,4-8,5.88A161,161,0,0,1,12.82,61a139.48,139.48,0,0,0,20.53,1.53,125.77,125.77,0,0,0,26.94-2.82V48.27A51.79,51.79,0,0,0,43.08,42.69Z"
        />
        <path
          fill="currentColor"
          d="M1.77,46.42c.1.31.2.61.31.92l0,.06c.16.45.34.9.51,1.34l0,0c.18.44.36.88.56,1.31a.61.61,0,0,1,.05.12l.08.16c.11.25.23.49.35.73l.09.18.25.5.11.22.09.16.16.29c.08.15.16.3.25.45l.06.12.08.13a36.25,36.25,0,0,0,3.77,5.33l.08.09c.2.24.4.47.61.7l.1.11c.23.25.46.5.7.74h0l.26.27.11.11.11.1c5.42-6.74,9.88-12.87,13.55-18.41A106.27,106.27,0,0,0,1.77,46.42Z"
        />
        <path
          fill="currentColor"
          d="M33.35,63.79a141.68,141.68,0,0,1-21.18-1.62,36.39,36.39,0,0,0,23.75,9.22V63.76C35.06,63.78,34.21,63.79,33.35,63.79Z"
        />
        <path
          fill="currentColor"
          d="M9.58,24.46A55.49,55.49,0,0,1,21.32,9.58C22,9,22.65,8.49,23.32,8,14.05.1.61,0,0,0H0V35.06a37.1,37.1,0,0,0,1.1,9A104,104,0,0,1,9.58,24.46Z"
        />
        <path
          fill="currentColor"
          d="M37.34,63.73A36.5,36.5,0,0,0,60.29,71.4V61A124.88,124.88,0,0,1,37.34,63.73Z"
        />
        <path
          fill="currentColor"
          d="M38.39,18.77c-.24.6-.5,1.19-.76,1.79a38,38,0,0,1,7.93-.86,35.54,35.54,0,0,1,12.69,2.36C55.13,13.36,48.87,8,42.46,4.72,42.32,6.29,41.63,11,38.39,18.77Z"
        />
        <path
          fill="currentColor"
          d="M37.76,2.69A42.74,42.74,0,0,0,24.31,0V7.29A25.18,25.18,0,0,1,37.76,2.69Z"
        />
        <path
          fill="currentColor"
          d="M50,36.6c-1.73,1.68-3.6,3.35-5.58,5A52.61,52.61,0,0,1,60.29,46.8V34.66a39.53,39.53,0,0,0-1-9.2A58.57,58.57,0,0,1,50,36.6Z"
        />
        <path
          fill="currentColor"
          d="M62.35,68.9a3.25,3.25,0,0,1,.27-1.32,3.67,3.67,0,0,1,.73-1.08,3.49,3.49,0,0,1,1.08-.73,3.33,3.33,0,0,1,1.33-.27,3.28,3.28,0,0,1,1.32.27,3.49,3.49,0,0,1,1.08.73,3.67,3.67,0,0,1,.73,1.08,3.36,3.36,0,0,1,0,2.64,3.67,3.67,0,0,1-.73,1.08,3.49,3.49,0,0,1-1.08.73,3.28,3.28,0,0,1-1.32.27A3.33,3.33,0,0,1,64.43,72a3.49,3.49,0,0,1-1.08-.73,3.67,3.67,0,0,1-.73-1.08A3.25,3.25,0,0,1,62.35,68.9Zm.5,0A2.9,2.9,0,0,0,63.7,71a2.93,2.93,0,0,0,.92.62,2.92,2.92,0,0,0,2.27,0,2.85,2.85,0,0,0,1.77-2.68,2.85,2.85,0,0,0-.22-1.13,3,3,0,0,0-.63-.93,2.93,2.93,0,0,0-.92-.62,2.92,2.92,0,0,0-2.27,0,2.93,2.93,0,0,0-.92.62,2.81,2.81,0,0,0-.62.93A2.69,2.69,0,0,0,62.85,68.9ZM65,70.85h-.49V66.93H66a1.35,1.35,0,0,1,1,.27,1.09,1.09,0,0,1,.28.81,1.29,1.29,0,0,1-.07.47,1,1,0,0,1-.22.33,1.41,1.41,0,0,1-.31.21,1.66,1.66,0,0,1-.38.11l1.17,1.72h-.56l-1.17-1.72H65Zm0-2.16h1a1.06,1.06,0,0,0,.36-.09.56.56,0,0,0,.36-.58.59.59,0,0,0-.08-.31.64.64,0,0,0-.2-.2.85.85,0,0,0-.28-.1,1.75,1.75,0,0,0-.32,0H65Z"
        />
      </g>
    </g>
  </Svg>
);

export { Logo, Isotype };
