import React from "react";
import { DataFormatter } from "../DataFormatter";
import {isEmpty} from 'lodash';

export const DataDetail = ({ name, format, data, Wrapper, Attributes, ...props }) => {

  //The attributes are consumed by field and are set in the Wrapper, individually
  const obj = !isEmpty(Attributes) && Object.fromEntries(Attributes.map((attribute => (!attribute.fields.includes(name)) ? [] : [attribute.name, attribute.value])));

  Wrapper = Wrapper || DataFormatter;
  return <Wrapper data={data} format={format} name={name} {...obj} {...props} />;
};
