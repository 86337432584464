import { useTranslation } from "react-i18next";
import React, { useContext, useEffect } from "react";
import { isEmpty } from "lodash";
import { DataDict, DataEntries, TextBig } from "../../../../data";
import {
  Form,
  ImplantSideSelect,
  TextInput,
  ImplantStatusSelect,
  ImplantSourceSelect,
  SubmitOrCancel,
  Switch,
  DatePicker,
  ToggleSwitch,
  TextArea,
} from "../../../../form";
import { PatientContext } from "../../../../../contexts/PatientContext";
import { UserContext } from "../../../../../contexts/UserContext";
import {
  dateFormat,
  numberWithUnits,
  translateWithPrefix,
  yesNo,
} from "../../../../../formatters";
import { useEditable } from "../../../../../hooks/useEditable";
import { AzureAuthContext } from "../../../../../contexts/AzureAuthContext";
import { useDispatch, useSelector } from "react-redux";
import { setDeviceTrackingStatus } from "../../../../../store/slices/deviceTrackingStatusSlice";
import { FormAlert } from "../../../../form/FormAlert";

export const ImplantDetailCard = ({ data }) => {
  const { t } = useTranslation();
  const { userProfile, confirmPassword } = useContext(AzureAuthContext);
  const { canEditImplant, canAccessDeviceTrackingPMS } =
    useContext(UserContext);
  const { postImplant } = useContext(PatientContext);
  const dispatch = useDispatch();

  const formState = useSelector((state) => state.formSlice?.formValues);
  const featureFlag = useSelector((state) => state.featureFlagSlice.features);
  let isFeatureDeviceTrackingActive =
    featureFlag &&
    featureFlag.filter((feat) => feat.feature === "device_tracking")[0].status;

  const side = translateWithPrefix("implant.side");
  const status = translateWithPrefix("implant.status");
  const source = translateWithPrefix("implant.source");
  const projection = numberWithUnits("cm");
  const volume = numberWithUnits("cc");
  const { editable, EditToggle, setEditable } = useEditable();

  const canEdit = () => editable && canEditImplant();
  const canDeviceTrackingEdit = () => editable && canAccessDeviceTrackingPMS();

  const displayTrackingStatus =
    data.deviceStatus &&
    isFeatureDeviceTrackingActive === 1 &&
    canAccessDeviceTrackingPMS();

  const statusTracking = () => {
    return (
      <div className="col-md-12">
        <h4 className="text-primary" style={{ fontSize: "18px" }}>
          {t("implant.subTitle")}
        </h4>
      </div>
    );
  };

  useEffect(() => {
    dispatch(
      setDeviceTrackingStatus({
        canChangeStatusTo: data.deviceStatus?.canChangeStatusTo,
      })
    );
  }, [data]);

  const handleSubmit = async (formData) => {
    if (displayTrackingStatus) {
      await confirmPassword();
    }

    await postImplant(data.id, formData);
  };

  const isStatus = (status) => {
    return (
      formState?.status === status.toString() ||
      (!editable && data.deviceStatus?.status === status)
    );
  };

  return (
    <Form
      onSubmit={handleSubmit}
      onFinishEdit={() => setEditable(false)}
      data={{
        source: 0,
        status: "",
        ...data,
        ...data.deviceStatus,
        user: userProfile?.mail,
      }}
      fields={[
        "side",
        "isBlocked",
        "isTracked",
        "status",
        "reason",
        "date",
        "user",
        "surgeonName",
        "originalImplantDate",
        "originalImplantPhysician",
        "deviceContributorRemoval",
        "source",
      ]}
    >
      <DataDict
        title={t("implant.title")}
        topRight={
          (canEditImplant() || canAccessDeviceTrackingPMS()) && <EditToggle />
        }
        footer={
          (canEdit() || canDeviceTrackingEdit()) && (
            <SubmitOrCancel
              disableIfUnchanged
              disableEdit={
                typeof data.deviceStatus !== "undefined" &&
                isFeatureDeviceTrackingActive &&
                ((formState?.status === "0" ||
                  formState?.status === "3" ||
                  formState?.status === "4") &&
                isEmpty(formState?.reason)
                  ? true
                  : false)
              }
            />
          )
        }
      >
        <DataEntries
          data={data}
          translationKey={"implant.data"}
          format={{
            projection,
            volume,
            side,
            isRegistered: yesNo,
            isBlocked: yesNo,
            isTracked: yesNo,
            isExplanted: yesNo,
          }}
          Wrapper={{
            side: canEdit() && ImplantSideSelect,
            validationCode: TextBig,
            isBlocked: canEdit() && Switch,
            isTracked: canEdit() && Switch,
            isStatus: canEdit() && ImplantSideSelect,
          }}
          keys={[
            "validationCode",
            "serialNumber",
            "electronicSerialNumber",
            "reference",
            "side",
            "volume",
            "projection",
            "surface",
            "isBlocked",
            "isRegistered",
            "isTracked",
            "isExplanted",
            "distributor_country_name",
          ]}
        />

        {displayTrackingStatus && (
          <DataEntries
            statusTracking={statusTracking()}
            data={data.deviceStatus}
            translationKey={"implant.data.deviceStatus"}
            format={{
              status,
              source,
              date: dateFormat,
              originalImplantDate: dateFormat,
              deviceContributorRemoval: yesNo,
            }}
            Wrapper={{
              status: canDeviceTrackingEdit() && ImplantStatusSelect,
              date: canDeviceTrackingEdit() && DatePicker,
              surgeonName: canDeviceTrackingEdit() && TextInput,
              originalImplantDate: canDeviceTrackingEdit() && DatePicker,
              originalImplantPhysician: canDeviceTrackingEdit() && TextInput,
              deviceContributorRemoval: canDeviceTrackingEdit() && ToggleSwitch,
              reason: canDeviceTrackingEdit() && TextArea,
              source: canDeviceTrackingEdit() && ImplantSourceSelect,
            }}
            keys={
              isStatus(2)
                ? [
                    "status",
                    "date",
                    "originalImplantPhysician",
                    "reason",
                    "source",
                  ]
                : isStatus(3)
                ? [
                    "status",
                    "date",
                    "surgeonName",
                    "originalImplantDate",
                    "originalImplantPhysician",
                    "deviceContributorRemoval",
                    "reason",
                    "source",
                  ]
                : ["status", "date", "reason", "source"]
            }
          />
        )}

        <FormAlert />
      </DataDict>
    </Form>
  );
};
