import {
  InstructionsTitle,
  InstructionsContainer,
  InstructionsSteps,
  InstructionsStep,
} from "./styles";
import { useTranslation } from "react-i18next";

export const ReportUploadInstructions = () => {
  const { t } = useTranslation();

  return (
    <InstructionsContainer>
      <InstructionsTitle>How does it work?</InstructionsTitle>
      <InstructionsSteps>
        <InstructionsStep>{t("reports.customerCare.steps.1")}</InstructionsStep>
        <InstructionsStep>{t("reports.customerCare.steps.2")}</InstructionsStep>
        <InstructionsStep>{t("reports.customerCare.steps.3")}</InstructionsStep>
      </InstructionsSteps>
    </InstructionsContainer>
  );
};
