import React, { createContext, useContext, useEffect, useState } from "react";
import { pick, isEqual } from "lodash";
import { Form as BootstrapForm } from "react-bootstrap";

export const FormContext = createContext({});

export const useFormValue = (name, value) => {
  const { initFormValue, setFormValue: setFormValueInContext } =
    useContext(FormContext);

  const [formValue, setFormValue] = useState(value);

  useEffect(() => initFormValue(name, value), []); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    formValue,
    setFormValue(value) {
      setFormValue(value);
      setFormValueInContext(name, value);
    },
  };
};

export class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = { formValues: {}, savedData: { ...props.data }, msg: {} };
  }

  async handleSubmit() {
    const submitValues = this.mergedValues(this.state.formValues);

    if (!this.props.validate || this.props.validate(submitValues)) {
      // Callback
      this.props.callbackFn && this.props.callbackFn();

      // Get the final data of the submit
      const data = this.props.fields
        ? pick(submitValues, this.props.fields)
        : submitValues;

      try {
        // Submit the data and wait for the response
        const response = await this.props.onSubmit(data);

        // Set the success message
        if (response && response.message) {
          this.setState(() => ({
            msg: {
              message: response.message,
              status: 200,
            },
          }));
        }

        // Call the finishEdit callback
        await (this.props?.onFinishEdit && this.props.onFinishEdit());

        // Update the saved data
        this.setState(() => ({ savedData: submitValues }));
      } catch (e) {
        const message =
          e.response?.data?.error ||
          e.response?.data?.message?.error_message ||
          e.message;
        const status =
          e.response?.data?.error ||
          e.response?.data?.message?.error_code ||
          500;

        this.setState(() => ({
          msg: {
            message: message,
            status: status,
          },
        }));
      } finally {
        setTimeout(() => {
          this.setState(() => ({
            msg: {},
          }));
        }, 5000);
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState(() => ({
        savedData: this.props.data,
      }));
    }
  }

  resetFormValues() {
    this.setState(({ formValues }) => ({
      formValues: { ...formValues, ...this.state.savedData },
    }));
  }

  mergedValues(values) {
    return { ...this.state.savedData, ...values };
  }

  setFormValue(name, value, runTriggers) {
    this.setState(
      ({ formValues, ...prevState }) => ({
        formValues: { ...formValues, [name]: value },
      }),
      () => {
        if (runTriggers) {
          if (this.props.autoSubmit) {
            this.handleSubmit();
          }
        }
      }
    );
  }

  hasChanged() {
    return !isEqual(
      this.state.savedData,
      this.mergedValues(this.state.formValues)
    );
  }

  render() {
    const hasChanged = () => this.hasChanged();
    const handleSubmit = () => this.handleSubmit();
    const handleCancel = () => this.resetFormValues();
    const setFormValue = (name, value) => this.setFormValue(name, value, true);
    const initFormValue = (name, value) =>
      this.setFormValue(name, value, false);
    const finishEdit = () =>
      this.props?.onFinishEdit && this.props.onFinishEdit();
    const formValue = (name) => this.state?.formValues[name];
    const formValues = this.state.formValues;
    const alertMessage = this.state.msg;

    return (
      <FormContext.Provider
        value={{
          formValue,
          formValues,
          initFormValue,
          setFormValue,
          hasChanged,
          handleSubmit,
          handleCancel,
          finishEdit,
          alertMessage,
        }}
      >
        <BootstrapForm>{this.props.children}</BootstrapForm>
      </FormContext.Provider>
    );
  }
}
