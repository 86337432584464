import { useTranslation } from "react-i18next";
import React, { useEffect, useContext, useState } from "react";

import { PatientContext } from "../../contexts/PatientContext";
import { StyledBaseModal } from "./styles";
import { Table } from "../../components/base/Table";
import { dateFormat, translateWithPrefix } from "../../formatters";

export const PatientErrors = ({ show, ...props }) => {
  const { t } = useTranslation();
  const { getPatientErrors } = useContext(PatientContext);
  const [patientErrors, setPatientErrors] = useState(null);

  const formatErrorCode = translateWithPrefix("patientErrors.errors");
  const formatProcess = translateWithPrefix("patientErrors.process");

  const loadPatientErrors = () => {
    getPatientErrors().then((res) => {
      setPatientErrors(res.results);
    });
  };

  useEffect(() => {
    if (show) loadPatientErrors();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return (
    <StyledBaseModal show={show} {...props} title={t("action.patientErrors")}>
      {patientErrors && (
        <Table
          sortable
          description={t("patientErrors.description")}
          data={patientErrors}
          columns={["date", "code", "comment", "process"]}
          format={{
            date: dateFormat,
            code: formatErrorCode,
            process: formatProcess,
          }}
          headingTranslationKey="patientErrors.data"
        />
      )}
    </StyledBaseModal>
  );
};
