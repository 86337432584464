import React from "react";
import {useFormValue} from "../Form";
import {StyledSwitch} from "./styles";

export const ToggleSwitch = ({name, data, values = [0, 1]}) => {
    const {formValue, setFormValue} = useFormValue(
        name,
        !!data ? values[1] : values[0]
    );

    const handleOnChange = () => {
        setFormValue(formValue === values[0] ? values[1] : values[0]);
    };

    return (
        <StyledSwitch onClick={handleOnChange}>
            <div className="toggle-switch">
                <input type="checkbox" className="checkbox"
                       name={name}
                       onChange={handleOnChange}
                       checked={formValue === values[1]}/>
                <label className="label" htmlFor={name}>
                    <span className="inner"/>
                    <span className="switch"/>
                </label>
            </div>
        </StyledSwitch>
    );
};
