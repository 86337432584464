import { FileInfoContainer, FileFeature } from "./styles";
import { Button } from "../../../form";
import React from "react";
import { useTranslation } from "react-i18next";

export const FileInfo = ({ file, onUpload }) => {
  const { t } = useTranslation();
  const fileSize = (size) => {
    if (size === 0) return "0 Bytes";
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const k = 1024;
    const i = Math.floor(Math.log(size) / Math.log(k));

    return `${parseFloat((size / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
  };
  return (
    <FileInfoContainer>
      <FileFeature name={t("fileInfo.name")}>{file.name}</FileFeature>
      <FileFeature name={t("fileInfo.size")}>{fileSize(file.size)}</FileFeature>
      <Button primary upload onClick={onUpload} />
    </FileInfoContainer>
  );
};
