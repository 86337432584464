import React, { useContext, useEffect, useState } from "react";
import { APIContext } from "../../../../contexts/APIContext";

import { DeviceTrackingReportContainer } from "./styles";
import { DeviceTrackingReportFilter } from "./DeviceTrackingReportFilter";
import fileDownload from "js-file-download";
import { TableComponent } from "../../../base/TableComponent";
import { isEmpty } from "lodash";

export const DeviceTrackingReport = () => {
  const { getDeviceTrackingReport, getDownloadPMS } = useContext(APIContext);
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [response, setResponse] = useState([]);

  const [payload, setPayload] = useState(null);

  const handleOnSubmit = async (data) => {
    setPayload(data);
  };

  const getData = (page) => {
    setLoading(true);
    let payloadData = {
      page: page,
      countryId: payload?.country,
      distributorId: payload?.distributor || "",
      dateStart: payload?.imported_start,
      dateEnd: payload?.imported_end,
    };
    getDeviceTrackingReport(payloadData)
      .then((response) => {
        setResponse(response);
      })
      .catch(() => setResponse([]))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!isEmpty(payload)) {
      getData(1);
    }
  }, [payload]);

  const downloadReport = () => {
    setDownloading(true);
    let body = {
      countryId: payload?.country,
      distributorId: payload?.distributor || "",
      dateStart: payload?.imported_start,
      dateEnd: payload?.imported_end,
    };

    getDownloadPMS(body)
      .then((response) => fileDownload(response, `Device tracking.csv`))
      .finally(() => setDownloading(false));
  };

  const columns = [
    {
      label: "Serial",
      keyValue: "serial",
      minWidth: "150px",
      link: "/search?serialNumber=",
    },
    { label: "Device Catalog", keyValue: "device_catalog" },
    { label: "Type", keyValue: "type" },
    { label: "Invoice Number", keyValue: "invoice_number" },
    { label: "Current Status", keyValue: "status" },
    { label: "Status Date", keyValue: "status_date" },
    { label: "Clinic Name", keyValue: "clinic_name" },
    { label: "Explant Surgeon Name", keyValue: "explant_surgeon_name" },
    { label: "Implant Surgeon Name", keyValue: "implant_surgeon_name" },
    { label: "Implant Date", keyValue: "implant_date" },
    { label: "Reason", keyValue: "reason" },
    { label: "Patient Name", keyValue: "patient_name" },
    { label: "Address", keyValue: "device_address" },
    { label: "SS Number", keyValue: "ss_number" },
    { label: "Email", keyValue: "patient_email", link: "/search?email=" },
    { label: "Source", keyValue: "source" },
  ];

  return (
    <DeviceTrackingReportContainer>
      <DeviceTrackingReportFilter onSubmit={handleOnSubmit} />
      <TableComponent
        rows={response}
        columns={columns}
        fetchData={getData}
        isLoading={loading}
        tableHeight={400}
        downloading={downloading}
        downloadAction={downloadReport}
        download={true}
      />
    </DeviceTrackingReportContainer>
  );
};
