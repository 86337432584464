import React from "react";
import { DropZone } from "./styles";
import { useTranslation } from "react-i18next";
import { Instruction, Link } from "../../../../base";

import { UploadAreaContainer } from "./styles";
import fileDownload from "js-file-download";

export const ReportUploadArea = ({ onUpload }) => {
  const { t } = useTranslation();

  const handleDownloadTemplate = () => {
    fileDownload('"Serial Number"\n"000000000-00"', "template.csv");
  };

  return (
    <UploadAreaContainer>
      <Instruction>{t("reports.customerCare.instruction")}</Instruction>
      <DropZone
        onUpload={onUpload}
        name="report"
        icon="cloud-upload-alt"
        instructions={t("reports.customerCare.upload.instructions")}
        chooseFileInstructions={t(
          "reports.customerCare.upload.chooseFileInstructions"
        )}
        validTypes={["text/csv"]}
      />
      <Instruction small>
        {t("reports.customerCare.upload.templateInstruction")}&nbsp;
        <Link onClick={handleDownloadTemplate}>here</Link>
      </Instruction>
    </UploadAreaContainer>
  );
};
