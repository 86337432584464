import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  @font-face {
    font-family: Avenir Book;
    src: url("/fonts/Avenir-Book.otf");
    font-display: auto;
  }

  @font-face {
    font-family: Avenir Book;
    src: url("/fonts/Avenir-Book.otf");
    font-weight: bold;
  }

  --font-style-default: Avenir Book;

  p, div, span {
    font-family: var(--font-style-default);
    font-size: 16px;
  }
  p{
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

body {
  padding: 0px;
  margin: 0px;
}

.line__height--1{
  line-height: 1;
}
.font__weight--700 {
  font-weight: 700;
  color: #939597;
}
.c-pointer{
  cursor: pointer;
}
.page-item.active .page-link {
  color: #fff !important;
  background-color: #6e0072 !important;
  border-color: #6e0072 !important;
}
.page-link {
  color: #6e0072 !important;
}
.list__implants{
  overflow-y: scroll;
  height: 34vh;
}

.bgColor-primary{
  background-color: #6e0072 !important;
  color: white;
}
.custom-select {
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.5rem center;
  background-size: 1em;
}
.custom-input {
  border-radius: 36px;
  border: 0;
  background: #f3f3f4;
  height: 45px;
  padding-left: 15px;
  outline: none;
  width: 100%;
  font-family: 'Avenir'
}
  .custom-button {
    height: 45px;
    padding: 0 25px; 
    border: 0;
    border-radius: 50px;
    text-transform: uppercase;
  }
  .react-datepicker-popper{
    z-index: 999999;
  }
`;


