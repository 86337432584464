import React, {useContext} from "react";
import { SimpleContextDropdown } from "./SimpleContextDropdown";
import { NavigationContext } from "../../contexts/NavigationContext";

export const PatientTypeSelect = ({ name, data, ...props  }) => {
  const {
    objectOptions: { patientType },
  } = useContext(NavigationContext);

  return (
    <SimpleContextDropdown name={name} value={data.toString()} options={patientType} {...props} />
  );
};
