import {makeStyles} from "@mui/styles";

export const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
    row: {
        color: "black",
        borderBottom: "1px solid #E6E8F0 !important",
    },
    sticky: {
        position: "sticky",
        left: 0,
        backgroundColor: 'white',
        width: (props) => props.minWidth,
        borderBottom: "1px solid #E6E8F0 !important",
        borderRight: "1px solid #E6E8F0 !important",
        boxSizing: "border-box",
    }
});