import React, {useContext, useEffect, useState} from "react";
import { useTranslation } from "react-i18next";

import {
    BIAnnualReportFilterContainer,
    FilterLabel,
    RangeStart,
    RangeEnd,
    Country,
    Distributor,
    Error, ImplantType, IsRegistered, NotBlocked, IsTracked, IsQInside, Lot, Status, FamilyType
} from "./styles";

import { Form, FormContext, SubmitButton } from "../../../../form";
import {APIContext} from "../../../../../contexts/APIContext";

const FormFields = () => {
  const { t } = useTranslation();
  const { formValues } = useContext(FormContext);
    const { getCatalogByGroup } = useContext(APIContext);

    const optionsType = ["Breasts","Gluteal"];
    const optionsBoolean = ["false","true"];
    const [optionsStatus, setOptionsStatus] = useState([]);
    const optionsFamilyType = ["","Round","Ergonomix® Round","Ergonomix® Oval","Anatomical TrueFixation®", "GlutealArmonic®","Ergonomix2®"];

    useEffect(()=>{
        getCatalog();
    },[])
    const getCatalog = async () => {
        await getCatalogByGroup({"group":"IMPLANT_STATUS"})
        .then((response) => {
            setOptionsStatus(response.map(item => item.value));
        })
    }

  return (
    <>
        <div className="row">
            <div className="col-12">
                <FilterLabel>{t("reports.distributor.filter.label")}:</FilterLabel>
            </div>
        </div>
        <div className="row my-2">
            <div className="col-2">
            </div>
        </div>
    </>
  );
};

export const BIAnnualReportFilter = ({ onSubmit, validate }) => {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const handleValidation = ({ country }) => {
    if (country === undefined) {
      setError(t("reports.distributor.filter.error.countryRequired"));
      return false;
    } else {
      setError(null);
      return true;
    }
  };
  return (
    <Form validate={handleValidation} onSubmit={onSubmit}>
      <BIAnnualReportFilterContainer>
          <FormFields />
      </BIAnnualReportFilterContainer>
      {error && <Error>{error}</Error>}
    </Form>
  );
};
