import React from "react";

import { PatientSearchContainer } from "./styles";
import { PatientSearchResults } from "./PatientSearchResults";
import { ShowLoading } from "../../../alerts/ShowLoading";
import SearchBox from "../../base/SearchBox";
import useSearch from "../../../hooks/useSearch";

export const PatientSearchBox = (props) => {
    const {doSearch} = useSearch();
    return <SearchBox onSearch={doSearch} criteria={props.criteria}/>;
};

export const PatientSearch = () => {
  const { loading, criteria, currentPatient } = useSearch();
  return (
    <PatientSearchContainer>
      <ShowLoading loading={loading} />
      <PatientSearchResults patient={currentPatient} criteria={criteria} />
    </PatientSearchContainer>
  );
};

export default PatientSearch;
