import React from "react";

import Layout from "../../layouts/Layout";
import { UsersComponent } from "../../components/users/UsersComponent";

export default function Users() {
  return (
    <Layout scrollable>
      <UsersComponent />
    </Layout>
  );
}
