import ReactDatePicker from "react-datepicker";
import React, { forwardRef, useEffect } from "react";
import { useFormValue } from "./Form";
import { DefaultTextInput } from "./DefaultTextInput";
import { format } from "date-fns";

export const DatePicker = ({
  name,
  placeholder,
  data,
  onChange = () => {},
  ...props
}) => {
  const newDate = new Date();
  const { formValue, setFormValue } = useFormValue(
    name,
    data || format(newDate, 'yyyy-MM-dd hh:mm:ss')
  );

  const CustomInput = forwardRef(({ value, onClick, onChange, ...props}, ref) => (
    <DefaultTextInput
      onClick={onClick}
      ref={ref}
      value={value}
      onChange={onChange}
      {...props}
    />
  ));

  const handleOnChange = (value) => {
    setFormValue(format(value, 'yyyy-MM-dd hh:mm:ss'));
    onChange(value);
  };

  useEffect(() => {
    if (data !== undefined) setFormValue(data);
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <ReactDatePicker
        name={name}
        selected={formValue ? new Date(formValue) : new Date()}
        onChange={handleOnChange}
        customInput={<CustomInput {...props} />}
        placeholderText={placeholder}
        {...props}
      />
    </div>
  );
};
