import styled from "styled-components";

import { CardContainer } from "../../../card";
import { Link } from "../../../base";

export const PatientCardContainer = styled.div`
  display: grid;
  grid-template-areas:
    ". action action"
    "implants implants implants";

  grid-template-columns: repeat(3, minmax(400px, 1fr));
  grid-template-rows: 1fr;
  grid-gap: 20px;
  width: calc(100% - 40px);
`;

export const PatientCardShortcutsContainer = styled(CardContainer)`
  grid-area: action;
`;

export const PatientCardShortcutContainer = styled.div``;

export const Shortcut = styled(Link)`
  display: flex;
  align-items: center;
  gap: 15px;
  background-color: ${(props) => props.theme["$primary"]};
  border-radius: 50px !important;
  padding: 20px;
  text-decoration: none;
  cursor: pointer;
  font-size: 18px !important;
  text-transform: uppercase;
  color: #ffffff !important;
  font-weight: 700;
  line-height: 0.25;
  height: 50px;

  &:hover {
    color: ${(props) => props.theme["$primary-background"]};
  }
  
  &:before {
    height: 100%;
    vertical-align: middle;
    display: inline-block;
  }

  .icon {
    width: 50px;
    height: 50px;
  }

  .text {
    white-space: nowrap;
    font-size: 0.7em;
  }
`;

export const DetailsSection = styled.div`
  grid-area: implants;
`;
