import React from "react";
import {Box, IconButton, Modal, Typography} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import Alert from "@mui/material/Alert";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    backgroundColor: "white",
    boxShadow: 24,
    p: 8,
    outline: 'none'
};

export const ModalComponent = ({open, close, title, content, actions, errorMessage}) => {
    return (
        <Modal open={open} onClose={close}>
            <>
                <Box sx={style}>
                    {errorMessage &&
                        <Box sx={{
                            position: "relative",
                            top: "-30px"
                        }}>
                            <Alert
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    font: "normal normal 900 20px/20px Avenir"
                                }}
                                icon={false}
                                severity={"error"}>{errorMessage}</Alert>
                        </Box>
                    }
                    <Box mb={6} display="flex" justifyContent="space-between">
                        <Typography variant="h5">
                            {title}
                        </Typography>
                        <IconButton aria-label="close" onClick={close}>
                            <CloseIcon/>
                        </IconButton>
                    </Box>
                    <Box>
                        {content}
                    </Box>
                </Box>
            </>
        </Modal>
    )
}