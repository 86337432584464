import { useTranslation } from "react-i18next";
import React from "react";

import { Button } from "./Button";

export const SeeMore = ({ ...props }) => {
  const { t } = useTranslation();

  return (
    <Button small outlined {...props}>
      {t("general.more")}
    </Button>
  );
};
