import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../contexts/UserContext";
import ActionDialog from "../base/ActionDialog";

export default function withAuthorization(WrappedComponent) {
  return (props) => {
    const { t } = useTranslation();
    const { isAuthorized, isAuthorizationReady } = useContext(UserContext);

    return isAuthorized ? (
      <WrappedComponent {...props} />
    ) : isAuthorizationReady ? (
      <ActionDialog
        title={t("dialog.notAuthorized.title")}
        subtitle={t("dialog.notAuthorized.subtitle")}
      />
    ) : (
      // LOADING AUTHORIZATION
      <div />
    );
  };
}
