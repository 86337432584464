import React, { useState,  useContext } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "../../../components/base/Table";
import { Button } from "../../../components/base";

import { APIContext } from "../../../contexts/APIContext";
import { UserContext } from "../../../contexts/UserContext";
import { useUsers } from "../../../hooks/useUsers";
import { dateFormat } from "../../../formatters";

export const UsersLog = () => {
  const [logs, setLogs] = useState(null);
  const { t } = useTranslation();
  const { getUsersActivityLog } = useContext(APIContext);
  const { canViewUserActivityLog } = useContext(UserContext);

  const { getUser } = useUsers();

  const userId = (id) => getUser(id)?.fullName;
  const handleLoadActivity = () => {
    getUsersActivityLog().then(setLogs);
  };

  return (
    <>
      {canViewUserActivityLog() &&
        (!logs ? (
          <Button onClick={handleLoadActivity}>
            {t("user.activityLog.load")}
          </Button>
        ) : (
          <Table
            title={t("user.activityLog.title")}
            data={logs}
            columns={["userId", "action", "description", "createdAt"]}
            format={{
              userId,
              createdAt: dateFormat,
            }}
            headingTranslationKey="user.activityLog.data"
          />
        ))}
    </>
  );
};
