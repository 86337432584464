import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    canChangeStatusTo: []
};

export const deviceTrackingStatusSlice = createSlice({
    name: 'deviceTrackingStatus',
    initialState,
    reducers: {
        setDeviceTrackingStatus: (state, action) => {
            return {
                ...state,
                ...action.payload
            }
        },
        cleanDeviceTrackingStatus: (state) => {
            return {
                ...state,
                ...initialState
            }
        }
    }
})

export const { setDeviceTrackingStatus, cleanDeviceTrackingStatus } = deviceTrackingStatusSlice.actions;

export default deviceTrackingStatusSlice.reducer;