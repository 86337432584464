import styled from "styled-components";

export const DataEntryTitle = styled(Text).attrs({ as: "dt" })`
  flex-basis: 50%;
  flex-shrink: 0;
  box-sizing: border-box;
  color: ${(props) => props.theme["$light-text"]};
  vertical-align: bottom;
`;

export const DataEntryDescription = styled(Text).attrs({ as: "dd" })`
  flex-basis: 50%;
  flex-grow: 1;
  padding: 0px;
  margin: 0px;
`;
