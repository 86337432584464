import { H3 } from "../../../../base/Content";

import styled from "styled-components";

export const InstructionsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InstructionsSteps = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
`;
export const InstructionsStep = styled.li`
  width: 80%;
  line-height: 1.4em;

  list-style: none;
  display: flex;
  align-items: center;
  min-height: 30px;

  &::before {
    margin: auto 0px;
    content: "\\29BE";
    font-size: 3em;
    height: 100%;
    line-height: 1em;
    vertical-align: center;
    padding-right: 10px;
    display: inline-block;
    font-weight: 100;
    color: ${({ theme }) => theme["$lightest-text"]};
  }
`;

export const InstructionsTitle = styled(H3)``;
