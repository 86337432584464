import React from "react";
import styled from "styled-components";

import { CardContainer } from "../../../card";
import ImplantCard from "../ImplantCard";

export const ImplantsCard = ({
  title,
  className,
  implants,
  type,
  selectImplant,
}) => {
  const filteredImplants = implants
    ?.filter((implant) => implant.implantType === type)
    // TODO: Customize order
    .sort((i) => (i.isExplanted ? 1 : -1));

  return (
    <>
      {filteredImplants?.length > 0 && (
        <CardContainer columns={3} title={title} className={className}>
          {filteredImplants.map((i, idx) => (
            <ImplantCard
              selectImplant={() => selectImplant(i)}
              key={idx}
              data={i}
            />
          ))}
        </CardContainer>
      )}
    </>
  );
};

export const BreastImplantsCard = styled(ImplantsCard).attrs({
  type: "B",
})``;

export const GlutealImplantsCard = styled(ImplantsCard).attrs({
  type: "G",
})``;
