import React from "react";

import { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { AzureAuthContext } from "../../contexts/AzureAuthContext";
import { NavigationContext } from "../../contexts/NavigationContext";

export default function withAuthentication(WrappedComponent) {
  return (props) => {
    const { loginPath } = useContext(NavigationContext);
    const { isAuthenticated } = useContext(AzureAuthContext);
    const [authLoaded, setAuthLoaded] = useState(false);
    const router = useHistory();

    useEffect(() => {
      const handleLoginStatus = async () => {
        if (await isAuthenticated()) {
          setAuthLoaded(true);
        } else {
          router.push({ pathname: loginPath, query: { r: router.pathname } });
        }
      };
      handleLoginStatus();
    }, [loginPath, router, isAuthenticated]);

    return authLoaded ? <WrappedComponent {...props} /> : <div />;
  };
}
