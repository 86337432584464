import { BaseModal } from "../../components/modal";
import styled from "styled-components";

export const StyledBaseModal = styled(BaseModal)`
  width: 100vw;
  height: 100vh;
  margin: 0px !important;

  .modal-dialog {
    margin: auto auto;
    max-height: 90vh !important;
    max-width: 80vw !important;
  }

  .modal-content {
    min-width: 80vw;
    min-height: 50vh;
    max-height: 90vh;
  }
`;
