import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    maintenanceMode: false,
}

export const uiSlice = createSlice({
    name: 'iu',
    initialState,
    reducers: {
        setMaintenanceMode: (state, action) => {
            return {
                ...state,
                ...action.payload
            }
        },
    }
})

export const {setMaintenanceMode} = uiSlice.actions;
export default uiSlice.reducer;
