import React from "react";

import { PatientCardView } from "./PatientCardView";
import { PatientContextProvider } from "../../../contexts/PatientContext";

export const PatientCard = ({ data }) => {
  return (
    <PatientContextProvider data={data}>
      <PatientCardView />
    </PatientContextProvider>
  );
};
