import React from "react";
import { CardBlock, CardText} from "../../base/Content.jsx";

import {
  DataCardView,
  DataCardTitleArea,
  DataCardTitle,
  Clickable,
  DataCardTitleRight,
} from "./styles";

const Title = ({ title, onClick }) => {
  const Return = <DataCardTitle>{title}</DataCardTitle>;

  return onClick ? <Clickable onClick={onClick}>{Return}</Clickable> : Return;
};

export const DataCard = ({ title, topRight, children, onClick }) => {
  return (
    <DataCardView>
      <CardBlock>
        <DataCardTitleArea>
          {(title || topRight) && (
            <>
              <Title title={title} onClick={onClick} />
              <DataCardTitleRight>{topRight}</DataCardTitleRight>
            </>
          )}
        </DataCardTitleArea>
        <CardText tag="div">{children}</CardText>
      </CardBlock>
    </DataCardView>
  );
};
