import { Row } from "../styles";
import { ColumnHeader } from "./styles";
import { translateWithPrefix } from "../../../../formatters";

export const TableHeading = ({
  columns,
  translationKey,
  onClick,
  sortConfig,
  sortable,
}) => {
  const translateHeading = translateWithPrefix(translationKey);
  return (
    <Row>
      {columns.map((heading) => (
        <ColumnHeader
          direction={
            sortConfig.field === heading &&
            (sortConfig.direction === 1 ? "ascending" : "descending")
          }
          sortable={sortable}
          onClick={() => onClick(heading)}
        >
          {translateHeading(heading)}
        </ColumnHeader>
      ))}
    </Row>
  );
};
