import React, {  useEffect, useState } from "react";

import { useFormValue } from "../Form";
import { StyledTypeahead } from "./styles";

export const DefaultTypeahead = ({ name, data, entries, placeholder, disabled = false}) => {
  const { setFormValue } = useFormValue(name, data);
  const [selected, setSelected] = useState(data);

  const onBlur = () => {
    if (selected?.length < 1) setSelected(data);
  };

  useEffect(() => {
    if (selected?.length > 0) {
      setFormValue(selected[0].id);
    }
  }, [selected]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StyledTypeahead
      id="country-typeahead"
      allowNew={false}
      onBlur={onBlur}
      placeholder={placeholder}
      emptyLabel=""
      options={Object.entries(entries).map(([id, label]) => ({ id, label }))}
      onChange={setSelected}
      selected={selected}
      inputProps={{ autoComplete: "new-password" }}
    />
  );
};
