import styled from "styled-components";

export const DataFormatter = ({ data, format, className }) => (
  <span className={className}>{!!format ? format(data) : data}</span>
);

export const TextBig = styled(DataFormatter)`
  font-size: 2em;
  font-weight: bold;
  display: inline-block;
  width: 100%;
  line-height: 0.5em;
`;
