import {configureStore} from '@reduxjs/toolkit';
import uiSlice from "./slices/uiSlice";
import formSlice from "./slices/formSlice";
import featureFlagSlice from "./slices/featureFlagSlice";
import deviceTrackingStatusSlice from "./slices/deviceTrackingStatusSlice";

export const store = configureStore({
    reducer: {
        uiSlice,
        formSlice,
        featureFlagSlice,
        deviceTrackingStatusSlice
    },
});
