import styled from "styled-components";

import { CardContainer } from "../../../card";
import { Link } from "../../../base";

export const ImplantDetailContainer = styled(CardContainer).attrs({
  columns: 3,
})``;

export const ImplantDetailControlsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ImplantShortcutContainer = styled.div``;

export const Shortcut = styled(Link)``;
