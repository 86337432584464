import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    formValues: {
        status: 0
    },
}

export const formSlice = createSlice({
    name: 'form',
    initialState,
    reducers: {
        setFormValues: (state, action) => {
            return {
                ...state,
                ...action.payload
            }
        },
    }
})

export const {setFormValues} = formSlice.actions;
export default formSlice.reducer;
