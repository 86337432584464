import React, { useContext } from "react";

import { ImplantDetailCard } from "./ImplantDetailCard";
import {
  ImplantDetailContainer,
  ImplantDetailControlsContainer,
  ImplantShortcutContainer,
  Shortcut,
} from "./styles";
import { Link } from "../../../base";
import { NavigationContext } from "../../../../contexts/NavigationContext";
import { SurgeryDetailCard } from "./SurgeryDetailCard";
import { WarrantyDetailCard } from "./WarrantyDetailCard";

export default function ImplantDetail({ implant, clearImplant }) {
  const { implantActions } = useContext(NavigationContext);
  return (
    <>
      <ImplantDetailControlsContainer>
        <Link
          back
          onClick={(e) => {
            e.preventDefault();
            clearImplant();
          }}
        />
        <ImplantShortcutContainer>
          {implantActions.map((action, idx) => (
            <Shortcut action={action} key={idx} />
          ))}
        </ImplantShortcutContainer>
      </ImplantDetailControlsContainer>
      <ImplantDetailContainer>
        <ImplantDetailCard data={implant} />
        {implant.surgery && <SurgeryDetailCard data={implant} />}
        <WarrantyDetailCard data={implant} implant={implant} />
      </ImplantDetailContainer>
    </>
  );
}
