export const addDays =(date, day)=> {
    date.setDate(date.getDate() + day);
    return date;
}

export function getMask(typeDevice) {
    switch (typeDevice) {
        case 'lot':
            return Array(8).fill(/[0-9]/);
        case 'injector':
            return Array(5).fill(/[0-9]/).concat([/[a-zA-Z]/]).concat(["-"]).concat(Array(3).fill(/[0-9]/)); //20709B-534
        case 'ESN':
            return Array(15).fill(/[0-9]/);  // 986100000664250
        case 'SN':
            return Array(8).fill(/[0-9]/).concat(["-"]).concat(Array(2).fill(/[0-9]/));  // 10090025-03
        default:
            console.log(`Not support ${typeDevice} Device`);
    }
}

export function getQueryParam(name) {
    const url = window.location.href;
    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2]);
}