import React, { useContext, useState } from "react";
import { APIContext } from "../../../../contexts/APIContext";

import { DistributorReportContainer } from "./styles";
import { DistributorReportFilter } from "./DistributorReportFilter";
import { ShowLoading } from "../../../../alerts/ShowLoading";

import fileDownload from "js-file-download";
import {isEmpty} from "lodash";

export const DistributorReport = () => {
  const { getDistributorReport } = useContext(APIContext);
  const [loading, setLoading] = useState(false);

  const handleOnSubmit = (data) => {
    setLoading(true);

    let payload = {
      ...data,
      distributor: isEmpty(data.distributor) ? -1 : data.distributor
    }
    return getDistributorReport(payload)
      .then((response) => fileDownload(response, "report.csv"))
      .then(() => setLoading(false)); // fix
  };

  return (
    <DistributorReportContainer>
      <ShowLoading loading={loading} />
      <DistributorReportFilter onSubmit={handleOnSubmit} />
    </DistributorReportContainer>
  );
};
