import React, { useContext } from "react";
import styled from "styled-components";

import { AzureAuthContext } from "../../contexts/AzureAuthContext";
import { Link } from "../base";
import { Logout } from "../icons";

const UserContainer = styled.div`
  display: inline-flex;
  align-items: center;
`;

const UserInfoContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 1000px) {
    display: none;
  }
`;

const PrimaryUserInfo = styled.div`
  display: block;
  font-weight: bold;
`;
const SecondaryUserInfo = styled.div`
  display: block;
  font-size: 0.8em;
`;

const LogoutButton = styled(Link).attrs({ name: <Logout /> })`
  background-color: ${(props) => props.theme["$primary-background"]};
  padding: 8px;
  border-radius: 100%;
  line-height: 1em;
  margin: 5px;
  text-align: center;
  vertical-align: middle;
`;

/*
const UserAvatar = styled(User)`
  color: ${(props) => props.theme["$primary"]}; 
  font-size: 40px;
  width: 30px;
  height: 30px;
  margin: 5px;
  border-radius: 100%;
`
*/

const UserInfo = () => {
  const { userProfile, logout } = useContext(AzureAuthContext);

  return (
    <UserContainer>
      <UserInfoContainer>
        <PrimaryUserInfo>{userProfile.displayName}</PrimaryUserInfo>
        <SecondaryUserInfo>{userProfile.userPrincipalName}</SecondaryUserInfo>
      </UserInfoContainer>
      <LogoutButton onClick={logout} />
    </UserContainer>
  );
};
export default UserInfo;
