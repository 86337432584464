import React, {useContext, useEffect, useState} from "react";
import { useTranslation } from "react-i18next";

import {
    ImplantReportFilterContainer,
    FilterLabel,
    RangeStart,
    RangeEnd,
    Country,
    Distributor,
    Error, ImplantType, IsRegistered, NotBlocked, IsTracked, IsQInside, Lot, Status, FamilyType
} from "./styles";

import { Form, FormContext, SubmitButton } from "../../../../form";
import {APIContext} from "../../../../../contexts/APIContext";

const FormFields = () => {
  const { t } = useTranslation();
  const { formValues } = useContext(FormContext);
    const { getCatalogByGroup } = useContext(APIContext);

    const optionsType = ["Breasts","Gluteal"];
    const optionsBoolean = ["false","true"];
    const [optionsStatus, setOptionsStatus] = useState([]);
    const optionsFamilyType = ["","Round","Ergonomix® Round","Ergonomix® Oval","Anatomical TrueFixation®", "GlutealArmonic®","Ergonomix2®"];

    useEffect(()=>{
        getCatalog();
    },[])
    const getCatalog = async () => {
        await getCatalogByGroup({"group":"IMPLANT_STATUS"})
        .then((response) => {
            setOptionsStatus(response.map(item => item.value));
        })
    }

  return (
    <>
        <div className="row">
            <div className="col-12">
                <FilterLabel>{t("reports.distributor.filter.label")}:</FilterLabel>
            </div>
        </div>
        <div className="row my-2">
            <div className="col-2">
                <label>{t("reports.implants.data.startDate")}</label>
                <RangeStart
                    name="imported_start"
                />

            </div>
            <div className="col-2">
                <label>{t("reports.implants.data.endDate")}</label>
                <RangeEnd
                    name="imported_end"
                />
            </div>
            <div className="col-2">
                <label>{t("reports.implants.data.country")}</label>
                <Country
                    name="country"

                />
            </div>
            <div className="col-2">
                <label>{t("reports.implants.data.distributor")}</label>
                <Distributor
                    name="distributor"
                    country={formValues["country"]}
                />
            </div>
            <div className="col-2">
                <label>{t("reports.implants.data.type")}</label>
                <ImplantType
                    name="type"
                    value="0"
                    options={optionsType}
                />
            </div>
            <div className="col-2">
                <label>{t("reports.implants.data.family_type")}</label>
                <FamilyType
                    name="family_type"
                    options={optionsFamilyType}
                />
            </div>

        </div>
        <div className="row">
            <div className="col-2">
                <label>{t("reports.implants.data.lot")}</label>
                <Lot
                    name="lot"
                />
            </div>
            <div className="col-2">
                <label>{t("reports.implants.data.is_registered")}</label>
                <IsRegistered
                    name="is_registered"
                    options={optionsBoolean}
                />
            </div>
            <div className="col-2">
                <label>{t("reports.implants.data.is_tracked")}</label>
                <IsTracked
                    name="is_tracked"
                    options={optionsBoolean}
                />
            </div>
            <div className="col-2">
                <label>{t("reports.implants.data.not_blocked")}</label>
                <NotBlocked
                    name="not_blocked"
                    options={optionsBoolean}
                />
            </div>
            <div className="col-2">
                <label>{t("reports.implants.data.is_q_inside")}</label>
                <IsQInside
                    name="is_q_inside"
                    options={optionsBoolean}
                />
            </div>
            <div className="col-2">
                <label>{t("reports.implants.data.status")}</label>
                <Status
                    name="status"
                    options={optionsStatus && optionsStatus}
                />
            </div>
            <div className="col-2 p-4">
                <SubmitButton>{t("general.filter")}</SubmitButton>
            </div>
        </div>

    </>
  );
};

export const ImplantReportFilter = ({ onSubmit, validate }) => {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const handleValidation = ({ country }) => {
    if (country === undefined) {
      setError(t("reports.distributor.filter.error.countryRequired"));
      return false;
    } else {
      setError(null);
      return true;
    }
  };
  return (
    <Form validate={handleValidation} onSubmit={onSubmit}>

      <ImplantReportFilterContainer>
          <FormFields />
      </ImplantReportFilterContainer>
      {error && <Error>{error}</Error>}
    </Form>
  );
};
