import React from "react";
import { useContext } from "react";
import { NavigationContext } from "../../contexts/NavigationContext";

export const ActionList = ({ actions, ItemComponent }) => {
  const { actions: actionsFromContext } = useContext(NavigationContext);
  const canDoAction = (action) => {
    return (
      action in actionsFromContext &&
      (!actionsFromContext[action].access ||
        actionsFromContext[action].access())
    );
  };

  return actions
    .filter(canDoAction)
    .map((action, idx) => <ItemComponent action={action} key={idx} />);
};
