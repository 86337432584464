import React, { useContext, } from "react";
import { SimpleContextDropdown } from "./SimpleContextDropdown";
import { NavigationContext } from "../../contexts/NavigationContext";

export const IncisionSelect = ({ name, data }) => {
  const {
    objectOptions: { incisionKind },
  } = useContext(NavigationContext);

  return (
    <SimpleContextDropdown name={name} value={data} options={incisionKind} />
  );
};
