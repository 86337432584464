import styled from "styled-components";

export const DataEntriesContainer = styled.dl`
  display: flex;
  flex-flow: row wrap;
  align-items: flex-end;
  margin: 0px;
  &:not(:first-child) {
    border-top: 1px dotted black;
    padding-top: 20px;
    margin-top: 20px;
  }
`;
