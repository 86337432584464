import React, {useContext} from "react";
import {Container, Grid, Box, Typography} from "@mui/material";
import {Logo} from "../../components/base/MotivaLogo";
import styled from "styled-components";
import {Button} from "../../components/base";
import MaintenanceBackground from "../../images/maintenance.png";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {NavigationContext} from "../../contexts/NavigationContext";


const MotivaLogo = styled(Logo)`
  width: 128px;
  color: ${(props) => props.theme.$primary};
`;

export default function MaintenancePage() {
    const { defaultUrl } = useContext(NavigationContext);
    const { t } = useTranslation();
    const router = useHistory();
    const returnUrl = defaultUrl;

    return (
        <Grid container>
            <Grid item md={6} sx={{
                backgroundImage: `url(${MaintenanceBackground})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: "cover",
                height: '100vh',
                width: '100vw',
                display: {xs: 'none', md:'block'}
            }}>
            </Grid>
            <Grid item xs={12} md={6} display="flex" justifyContent="center" alignItems="center" flexDirection="column"
                  height={'100vh'}>
                <Box my={8}>
                    <MotivaLogo/>
                </Box>
                <Box>
                    <Typography variant="subtitle2" sx={{font: "normal normal 900 42px/42px Avenir"}}>{t("maintenance.title")}</Typography>
                </Box>
                <Box mt={5}>
                    <Typography variant="subtitle2" textAlign="center" sx={{font: "normal normal 900 32px/32px Avenir"}}>{t("maintenance.subTitle")}</Typography>
                </Box>
                <Box mt={1}>
                    <Typography variant="h6" textAlign="center">{t("maintenance.description")}</Typography>
                </Box>
                <Box mt={6}>
                    <Button action outlined onClick={()=>router.go()}>
                        <Typography variant="subtitle2" fontSize={18}>{t("maintenance.actionButton")}</Typography>
                    </Button>
                </Box>
            </Grid>
        </Grid>
    );
}
