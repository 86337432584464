import React from "react";

import Layout from "../../layouts/Layout";
import PatientSearch, {
  PatientSearchBox,
} from "../../components/patient/PatientSearch";
import { SearchContextProvider } from "../../hooks/useSearch";
import {getQueryParam} from "../../util";

export default function Search(props) {

    const serialNumber = getQueryParam("serialNumber");
    const email = getQueryParam("email");
    return (
        <SearchContextProvider>
            <Layout topComponent={<PatientSearchBox criteria={{serialNumber, email}}/>}>
                <PatientSearch/>
            </Layout>
        </SearchContextProvider>
    );
}
