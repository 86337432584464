import { useTranslation } from "react-i18next";
import React, { useContext, } from "react";
import Button from "./Button";
import { FormContext } from "./Form";

export const CancelButton = ({ children, disableIfUnchanged = false }) => {
  const { finishEdit, hasChanged, handleCancel } = useContext(FormContext);
  const { t } = useTranslation();

  const handleOnClick = (e) => {
    e.preventDefault();
    handleCancel();
    finishEdit && finishEdit();
  };

  return (
    <Button
      disabled={disableIfUnchanged && !hasChanged()}
      onClick={handleOnClick}
    >
      {children || t("edit.cancel")}
    </Button>
  );
};
