import React, { useContext, } from "react";
import { SimpleContextDropdown } from "./SimpleContextDropdown";
import { NavigationContext } from "../../contexts/NavigationContext";

export const PlacementSelect = ({ name, data }) => {
  const {
    objectOptions: { implantPlacement },
  } = useContext(NavigationContext);

  return (
    <SimpleContextDropdown
      name={name}
      value={data}
      options={implantPlacement}
    />
  );
};
