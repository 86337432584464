import React, { createContext } from "react";

export const ConfigContext = createContext({});

export const ConfigContextProvider = ({ children }) => {
  return (
    <ConfigContext.Provider
      value={{
        searchFeature: true,
        editFeature: true,
        shortcutsFeature: true,
        sendEmailFeature: false,
        extendWarrantyFeature: true,
        defaultWarrantyExtendDays: 7,
        minWarrantyExtendDays: 1,
        maxWarrantyExtendDays: 30,
        reportFeature: true,
        userAdminFeature: true,
        userActivityLogFeature: true,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};
