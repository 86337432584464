import styled from "styled-components";

import { H2 } from "../../../base/Content";

export const PatientSearchResultsContainer = styled.div``;

export const ResponseContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  overflow-y: auto;
`;

export const Title = styled(H2)`
  padding: 10px 0px;
`;

export const Content = styled.div`
  position: relative;
  flex-shrink: 1;
`;
