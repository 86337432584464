import React, { useState, useEffect } from "react";
import { StyledAlert, StyledBackdrop } from "./style";

export const Alert = ({ children, blocking, show = true, dismiss = 0 }) => {
  const [shown, setShown] = useState(show);

  useEffect(() => {
    let timeout;
    if (show) {
      setShown(true);
      if (dismiss !== 0) {
        timeout = setTimeout(() => setShown(false), dismiss);
      }
    } else {
      setShown(show);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);
  return (
    <>
      {shown && blocking && <StyledBackdrop />}
      {shown && <StyledAlert>{children}</StyledAlert>}
    </>
  );
};
