import styled from "styled-components";
import { H2, Text } from "./Content";

export const CenteredFull = styled.div`
  display: flex;
  margin: auto;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`;

const Message = styled.div`
  margin: 30%;
`;

const Title = styled(H2)`
  color: ${(props) => props.theme["$primary"]};
  text-align: center;
  display: block;
`;
const Subtitle = styled(Text)`
  text-align: center;
  display: block;
`;

const SpecialElement = styled(Text)`
  text-align: center;
  display: block;
  color: #6e0072;
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
`;

export const CenteredFullMessage = ({ title, subtitle, specialElement }) => (
  <CenteredFull>
    <Message>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
        <SpecialElement> {specialElement}</SpecialElement>
    </Message>
  </CenteredFull>
);
