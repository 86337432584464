import {Button, Form, Input, InputGroup, InputGroupButton,} from "@bootstrap-styled/v4";
import {Search} from "react-bootstrap-icons";
import React, {useContext, useEffect, useState} from "react";
import styled from "styled-components";

import {NavigationContext} from "../../contexts/NavigationContext";
import {SimpleDropdown} from "./SimpleDropdown";
import {useKeyboardShortcut} from "../../hooks/useKeyboardShortcut";

const Group = styled(InputGroup)`
  margin: 3px;
  flex-wrap: nowrap;

  button {
    background-color: ${(props) =>
        props.theme["$shaded-background"]} !important;
    border: 0px !important;

    color: ${(props) => props.theme["$primary"]} !important;
    padding: 1.5em !important;
    outline: none !important;
  }

  &
  > .input-group-btn:not(:first-child)
  > button[type="submit"].btn.btn-primary:focus:first-child {
    color: red;
    outline: none !important;
    outline-color: none !important;
    border-color: none !important;
  }

  & *:first-child button {
    border-radius: 30px 0px 0px 30px !important;
  }
  & *:last-child button {
    border-radius: 0px 30px 30px 0px !important;
  }

  .dropdown-menu button {
    border-radius: 0px !important;
    transition: 0.2s;
  }

  .dropdown-menu {
    background-color: ${(props) =>
      props.theme["$shaded-background"]} !important;
    border: 0px;
    border-radius: 10px;
    box-shadow: 5px 5px ${(props) => props.theme["$dark-shaded-background"]};
  }
  .dropdown-menu button:hover {
    background-color: ${(props) => props.theme["$primary"]} !important;
    color: ${(props) => props.theme["$primary-contrast"]} !important;
  }
`;

const SearchInput = styled(Input)`
  background-color: ${(props) => props.theme["$shaded-background"]} !important;
  border: 0px !important;

  button {
    color: red !important;
  }
`;

export default function SearchBox({onSearch, criteria}) {

  const {
    search: {criteriasPatterns, criterias, defaultCriteria},
  } = useContext(NavigationContext);

  const [searchCriteria, setSearchCriteria] = useState(defaultCriteria);
  const [searchValue, setSearchValue] = useState("");

  const handleValueChange = (e) => {
    const {value} = e.target;
    Object.entries(criteriasPatterns).forEach(([criteria, pattern]) => {
      if (pattern.test(value)) {
        setSearchCriteria(criteria);
      }
    });

    setSearchValue(value);
  };


  useEffect(() => {
    const {email, serialNumber} = criteria;
    const searchCriteria = email ? "email" : "serial";
    const searchValue = email || serialNumber;
    onSearch({[searchCriteria]: searchValue});
    setSearchCriteria(searchCriteria);
    setSearchValue(searchValue);
  }, []);


  const handleSearch = (e) => {
    e.preventDefault();
    onSearch({[searchCriteria]: searchValue});

    return false;
  };


  const handleSearch2 = () => {
    onSearch({[searchCriteria]: searchValue});
    return false;
  };

  const onKeyPress = (e) => {
    if (e.key === "Enter") return handleSearch(e);
  };

  const takeFocus = (e) => {
    e.preventDefault();
    const input = document.getElementById("searchInput");
    input.focus();
  };

  useKeyboardShortcut({ code: "Slash" }, takeFocus);

  return (
    <Form onSubmit={handleSearch}>
      <Group>
        <SimpleDropdown
          selected={searchCriteria}
          onSelect={setSearchCriteria}
          options={criterias}
        />
        <SearchInput
          id="searchInput"
          onChange={handleValueChange}
          value={searchValue}
          onKeyPress={onKeyPress}
        />
        <InputGroupButton>
          <Button type="submit" grouped="true">
            <Search />
          </Button>
        </InputGroupButton>
      </Group>
    </Form>
  );
}
