import React from "react";

import Layout from "../../layouts/Layout";
import { ReportsComponent } from "../../components/reports/ReportsComponent";

export default function Reports() {
  return (
    <Layout scrollable>
      <ReportsComponent />
    </Layout>
  );
}
