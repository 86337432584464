import React, { useState } from "react";
import styled from "styled-components";

import { Icon } from "../components/icons";

const StyledButton = styled.button`
  border: 0px;
  background-color: transparent;
  color: ${(props) =>
    props.highlight ? props.theme.$primary : props.theme["$light-text"]};
`;

const StyledToggle = styled(Icon).attrs({ icon: "edit" })``;

export const useEditable = () => {
  const [editable, setEditable] = useState(false);

  const handleClick = () => {
    setEditable((v) => !v);
  };
  const EditToggle = () => (
    <StyledButton highlight={!editable} onClick={handleClick}>
      <StyledToggle onClick={handleClick} />
    </StyledButton>
  );

  return { setEditable, editable, EditToggle };
};
