import React, {useRef, useState} from "react";
import styled from "styled-components";
import {Button, Form, Input, InputGroup, InputGroupButton} from "@bootstrap-styled/v4";
import {SimpleDropdown} from "./SimpleDropdown";
import {Search} from "react-bootstrap-icons";
import {useKeyboardShortcut} from "../../hooks/useKeyboardShortcut";

const Group = styled(InputGroup)`
    margin-top: 15px;
    margin-left: 3px;
    margin-right: 3px;
    flex-wrap: nowrap;

    button {
        background-color: ${(props) =>
                props.theme["$shaded-background"]} !important;
        border: 0px !important;

        color: ${(props) => props.theme["$primary"]} !important;
        padding: 1.5em !important;
        outline: none !important;
    }

    &
    > .input-group-btn:not(:first-child)
    > button[type="submit"].btn.btn-primary:focus:first-child {
        color: red;
        outline: none !important;
        outline-color: none !important;
        border-color: none !important;
    }

    & *:first-child button {
        border-radius: 30px 0px 0px 30px !important;
    }
    & *:last-child button {
        border-radius: 0px 30px 30px 0px !important;
    }

    .dropdown-menu button {
        border-radius: 0px !important;
        transition: 0.2s;
    }

    .dropdown-menu {
        background-color: ${(props) =>
                props.theme["$shaded-background"]} !important;
        border: 0px;
        border-radius: 10px;
        box-shadow: 5px 5px ${(props) => props.theme["$dark-shaded-background"]};
        z-index: 1050; 
        position: absolute;
    }
    .dropdown-menu button:hover {
        background-color: ${(props) => props.theme["$primary"]} !important;
        color: ${(props) => props.theme["$primary-contrast"]} !important;
    }
`;

const SearchInput = styled(Input)`
    background-color: ${(props) => props.theme["$shaded-background"]} !important;
    border: 0px !important;

    button {
        color: red !important;
    }
`;


export default function SearchBoxUsers({ onSearch, availableCriterias }) {
  const [selectedCriteria, setSelectedCriteria] = useState(availableCriterias[0]); // Default to the first criteria
  const [searchValue, setSearchValue] = useState("");
  const inputRef = useRef(null); // Crear una referencia para el input

    const handleSearch = (e) => {
        e.preventDefault();
        // Pass the selected key and value to onSearch
    onSearch({ [selectedCriteria.key]: searchValue });
  };

    const onKeyDown = (e) => {
        if (e.key === "Enter") return handleSearch(e);
    };

    const takeFocus = (e) => {
        e.preventDefault();
        inputRef.current?.focus();
    };

    useKeyboardShortcut({ code: "Slash" }, takeFocus);

    const dropdownOptions = Array.isArray(availableCriterias)
        ? availableCriterias.reduce((acc, criteria) => {
            acc[criteria.key] = criteria.label;
            return acc;
        }, {}) : {};

    return (
      <Form onSubmit={handleSearch}>
          <Group>
              <SimpleDropdown selected={selectedCriteria.key}
                              options={dropdownOptions}
                              onSelect={(selectedKey) => {
                                  const newCriteria = availableCriterias.find((c) => c.key === selectedKey);
                                  setSelectedCriteria(newCriteria);
                              }}
              />
              <SearchInput
                  ref={inputRef}
                  type="text"
                  value={searchValue}
                  placeholder={`Search by ${selectedCriteria.label}`}
                  onChange={(e) => setSearchValue(e.target.value)}
                  onKeyDown={onKeyDown}
              />
              <InputGroupButton>
                  <Button type="submit" grouped="true">
                      <Search />
                  </Button>
              </InputGroupButton>
          </Group>
      </Form>
)
    ;
}
