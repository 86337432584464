import { Table as ReactTable } from "react-bootstrap";

import styled from "styled-components";
import { H3, Text } from "../Content";

export const TableContainer = styled.div`
  width: 100%;
  padding: ${props => props.padding ? props.padding : '30px'};
`;
export const Title = styled(H3)`
  color: ${(props) => props.theme.$primary};
`;

export const Description = styled(Text)`
  margin: 0px 0px 40px 0px;
`;

export const StyledTable = styled(ReactTable)`
  border-sizing: border-box;
  border-collapse: collapse;
  border-spacing: 10px 20px;
`;

export const Row = (styled.tr`
  border-bottom: 1px solid ${(props) => props.theme["$lightest-text"]};
`.displayName = "tr");

export const Col = (styled.td`
  padding: 20px 0px;

  span {
    padding: 0px;
  }
`.displayName = "td");
