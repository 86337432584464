import styled from "styled-components";

import Menu from "../../components/menu/Menu";
import UserInfo from "../../components/menu/UserInfo";

export { Menu, UserInfo };

export const DashboardContainer = styled.div`
  display: flex;
  height: 100vh;
  background-color: ${(props) => props.theme["$shaded-background"]};
`;

export const MenuContainer = styled.div`
  width: 240px;
  height: 100vh;
  margin-left: 0px;
  position: fixed;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border-radius: 30px;
  padding: 30px;
  background-color: ${(props) => props.theme["$clear-background"]};

  margin: 30px 30px 30px 240px;
  width: calc(100% - 250px);

  .sm {
    margin: 30px 30px 30px 100px;
    width: calc(100% - 100px);
  }

  .expanded {
    margin: 30px 30px 30px 30px;
    width: calc(100%);
  }
`;

export const ContentView = styled.div`
  flex-shrink: 1;
  position: relative;
  height: 100%;
  overflow-x: hidden;
  overflow-y: ${({ scrollable }) => (scrollable ? "auto" : "hidden")};
`;

export const TopNav = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: row-reverse;
  margin-bottom: 10px;
`;

export const TopComponent = styled.div`
  flex-grow: 1;
`;
