import {CustomUserModal} from "../../components/modal";
import styled from "styled-components";

export const StyledCustomModal = styled(CustomUserModal)`
  width: 100vw;
  height: 100vh;
  margin: 0px !important;

  .modal-dialog {
    margin: auto auto;
    max-height: 90vh !important;
    max-width: 40vw !important;
  }

  .modal-content {
    min-width: 40vw;
    min-height: 50vh;
    max-height: 90vh;
    border: 0px solid rgba(0,0,0,.2);
    border-radius: 0;
  }
`;
