import React, { useState, useContext, createContext, useEffect } from "react";

import { APIContext } from "../contexts/APIContext";

export const SearchContext = createContext({});

export const SearchContextProvider = ({ children }) => {
  const [results, setResults] = useState(null);
  const [criteria, setCriteria] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentPatient, setCurrentPatient] = useState(null);
  const { patientSearch } = useContext(APIContext);

  const doSearch = (query) => {
    setLoading(true);
    patientSearch(query).then(({ results }) => {
      setCriteria(query);
      setResults(results);
      setCurrentPatient(results[0]);
      setLoading(false);
    });
  };

  const refreshSearch = () => {
    doSearch(criteria);
  };

  const clearSearch = () => {
    setResults(null);
  };

  useEffect(() => {
    clearSearch();
  }, []);

  return (
    <SearchContext.Provider
      value={{
        loading,
        currentPatient,
        results,
        doSearch,
        refreshSearch,
        criteria,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

const useSearch = () => {
  const { loading, currentPatient, doSearch, refreshSearch, criteria } =
    useContext(SearchContext);

  return { loading, currentPatient, doSearch, refreshSearch, criteria };
};

export default useSearch;
