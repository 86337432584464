import styled from "styled-components";
import { Text } from "../../Content";

export const ColumnHeader = styled(Text).attrs({ as: "th" })`
  font-weight: 100;
  border-bottom: 1px solid ${({ theme }) => theme["$light-text"]};
  ${({ sortable }) => sortable && "cursor: pointer;"}

  &::after {
    width: 1ch;
    margin: 0px 1ch;
    display: inline-block;
    content: "${({ direction }) =>
      direction
        ? direction === "ascending"
          ? "\\2191"
          : "\\2193"
        : "\\00A0"}";
  }
`;
