import { SubmitButton } from "./SubmitButton";
import { CancelButton } from "./CancelButton";
import React  from "react";

export const SubmitOrCancel = ({ disableIfUnchanged, disableEdit }) => (
  <>
    <SubmitButton
      disableIfUnchanged={disableIfUnchanged}
      disableEdit={disableEdit}
    />
    <CancelButton disableEdit={disableEdit} />
  </>
);
