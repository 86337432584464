import React from "react";
import styled from "styled-components";

import { Button } from "./Button";
import { CenteredFull } from "./CenteredFull";

const Header = styled.div``;

const Title = styled.h1`
  text-align: center;
`;

const Subtitle = styled.p`
  text-align: center;
`;

const ButtonsContainer = styled.div`
  text-align: center;
`;

const Dialog = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border-radius: 30px;
`;

function ActionDialog({
  header,
  title,
  subtitle,
  action,
  onAction,
  className,
}) {
  return (
    <CenteredFull>
      <Dialog className={className}>
        <Header>{header}</Header>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
        {action && (
          <ButtonsContainer>
            <Button primary onClick={onAction}>
              {action}
            </Button>
          </ButtonsContainer>
        )}
      </Dialog>
    </CenteredFull>
  );
}

export default ActionDialog;
