import React from "react";
import { useTranslation } from "react-i18next";

import { Alert } from "../../components/alert";

export const ShowSaving = ({ saving }) => {
  const { t } = useTranslation();
  return (
    <Alert blocking show={saving}>
      {t("general.saving")}
    </Alert>
  );
};
