import { useCallback, useEffect } from "react";

const blackListedTagElements = ["INPUT", "TEXTAREA"];

export const useKeyboardShortcut = (keys, callback) => {
  const keyDownListener = useCallback(
    (keys) => (event) => {
      if (
        !blackListedTagElements.includes(event.target.tagName?.toUpperCase())
      ) {
        if (event.code === keys.code) {
          callback(event);
        }
      }
    },
    [] //eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    window.addEventListener("keydown", keyDownListener(keys));
  }, []); //eslint-disable-line react-hooks/exhaustive-deps
};
