import i18n from "../localization";

const translateWithPrefix = (prefix) => (value) => i18n.t(`${prefix}.${value}`);
const dateFormat = (value) => {
  const d = new Date(value);
  return `${d.getDate()} ${translateWithPrefix("date.month")(
    d.getMonth()
  )} ${d.getFullYear()}`;
};

const yesNo = (value) => i18n.t(value ? "general.yes" : "general.no");

const numberWithUnits = (unit) => (n) => `${n} ${unit}`;

export { translateWithPrefix, dateFormat, yesNo, numberWithUnits };
