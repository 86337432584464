import React from "react";

export const Select = ({ options, onSelect, selected }) => {

    const handleSelect = (option) => (e) => {
        e.preventDefault();
        onSelect(e.target.value);
    };

    return (
        <select className="custom-select" onChange={handleSelect()} value={selected}>
            {Object.entries(options).map(([option, label], index) => (
                <>
                    {label &&
                        <option key={index} value={label}>
                            {label}
                        </option>
                    }
                </>
            ))}
        </select>
    );
};