import { Modal } from "react-bootstrap";

import styled from "styled-components";

export const StyledModal = styled(Modal).attrs({ style: "" })`
  .close {
    border: 0px;
    background-color: transparent;
    color: ${(props) => props.theme["$light-text"]};
  }

  display: flex;
  justify-content: space-around;
  align-items: center;

  .modal-body {
    overflow: auto;
    height: 100%;
  }
`;
