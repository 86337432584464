import { useTranslation } from "react-i18next";
import React from "react";

import { BreastImplantsCard, GlutealImplantsCard } from "../ImplantsCard";

export const ImplantsSummary = ({ selectImplant, data }) => {
  const { t } = useTranslation();
  return (
    <>
      <BreastImplantsCard
        implants={data?.implants}
        selectImplant={selectImplant}
        title={t("patient.card.implants.breast.title")}
      />
      <GlutealImplantsCard
        implants={data?.implants}
        selectImplant={selectImplant}
        title={t("patient.card.implants.gluteal.title")}
      />
    </>
  );
};
