import React from "react";
import { useFormValue } from "../Form";
import { StyledSwitch } from "./styles";

export const Switch = ({ name, data, values = [0, 1] }) => {
  const { formValue, setFormValue } = useFormValue(
    name,
    !!data ? values[1] : values[0]
  );

  const handleOnChange = () => {
    setFormValue(formValue === values[0] ? values[1] : values[0]);
  };

  return (
    <StyledSwitch onClick={handleOnChange}>
      <input
        type="checkbox"
        name={name}
        onChange={handleOnChange}
        className="switch-checkbox"
        checked={formValue === values[1]}
      />
      <label className="switch-label" htmlFor={name}>
        <span className="switch-inner" />
        <span className="switch-switch" />
      </label>
    </StyledSwitch>
  );
};
