import { useFormik } from "formik";
import { CustomModal } from "../../components/modal";
import * as Yup from "yup";
import { useContext, useEffect, useState } from "react";
import { APIContext } from "../../contexts/APIContext";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { SimpleSerialComponent } from "../../components/form/SimpleSerialComponent";

const bottonStyle = {
  height: 45,
  padding: "0px 25px",
  border: 0,
  borderRadius: 50,
  textTransform: "uppercase",
  color: "#fff",
  backgroundColor: "#6e0072",
};

export const AddImplantModal = ({ show, close, searchSerial }) => {
  const { t } = useTranslation();
  const [success, setSuccess] = useState(false);
  const [alertMessage, setAlertMessage] = useState({});
  const { postAddImplant, countriesArray } = useContext(APIContext);

  const validationSchema = Yup.object({
    refCode: Yup.string().required("is required"),
    serial: Yup.object({
      SNLeft: Yup.string().required("is required"),
    }).required("is required"), // Assuming serial is also required
  });

  const handleClose = (_success = undefined) => {
    formik.resetForm();
    close(_success ?? success);
  };

  const formik = useFormik({
    initialValues: {
      countryId: null,
      refCode: "",
      serial: {
        SNLeft: "",
        VCLeft: "",
        electronicSerial: "01",
        isValidLeft: false,
      },
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let payload = {
        countryId: values.countryId?.id || 0,
        refCode: values.refCode,
        serial: values.serial.SNLeft,
      };

      postAddImplant(payload)
        .then((data) => {
          setAlertMessage({
            message: data.message,
            status: 200,
          });
          formik.resetForm();
          setSuccess(true);

          setTimeout(() => {
            setAlertMessage({});
            handleClose(true);
          }, 3000);
        })
        .catch((e) => {
          console.log(e.response);
          setAlertMessage({
            message: e.response?.data?.message,
            status: e.response?.status,
          });
        });
    },
  });

  const textFieldStyle = {
    borderRadius: "36px !important",
    border: 0,
    background: "#f3f3f4",
    width: "360px !important",
    height: "45px !important",
    outline: "none",
    fontFamily: "Avenir",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "transparent",
      },
      "&:hover fieldset": {
        borderColor: "transparent",
      },
      "&.Mui-focused": {
        "& fieldset": {
          borderColor: "transparent",
        },
      },
      "&.MuiOutlinedInput-input": {
        backgroundColor: "transparent",
        "&:focus": {
          outline: "none",
        },
      },
    },
  };

  const data = [
    {
      label: "Country",
      component: (props) =>
        countriesArray ? (
          <Autocomplete
            options={countriesArray}
            getOptionLabel={(option) => option.name}
            name="countryId"
            id="countryId"
            value={formik.getFieldMeta("countryId").value || null}
            onChange={(_event, newValue) => {
              if (newValue) {
                formik.setFieldValue("countryId", newValue);
              } else {
                const unknownCountry = countriesArray.find(
                  (country) => country.id === 0
                );
                formik.setFieldValue("countryId", unknownCountry);
              }
            }}
            renderInput={(params) => (
              <TextField {...params} size="small" sx={textFieldStyle} />
            )}
          />
        ) : null,
    },
    {
      label: "Code Reference",
      component: (props) => (
        <TextField
          name="refCode"
          size="small"
          onChange={formik.handleChange}
          sx={textFieldStyle}
        />
      ),
    },
    {
      label: "Serial Number",
      component: (props) => (
        <SimpleSerialComponent serial="serial.SNLeft" formik={props.formik} />
      ),
    },
  ];

  useEffect(() => {
    if (searchSerial && show) {
      formik.setFieldValue("serial.SNLeft", searchSerial);
      setSuccess(false);
      setAlertMessage({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, searchSerial]);

  return (
    <CustomModal
      show={show}
      title={t("search.messages.notFound.modal.title")}
      alertMessage={alertMessage}
      formik={formik}
      size="lg"
      close={handleClose}
    >
      <form onSubmit={formik.handleSubmit}>
        <Box pt={4}>
          {data.map((item, index) => (
            <Grid
              container
              my={2}
              display="flex"
              alignItems="center"
              key={index}
            >
              <Grid xs={12} md={4.5}>
                <Typography fontWeight={600}>{item.label}</Typography>
              </Grid>
              <Grid item xs={12} md={7.5}>
                {item.component
                  ? item.component({ formik })
                  : formik.values[item.value]}
              </Grid>
            </Grid>
          ))}
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          mt={10}
        >
          <Button
            variant="primary"
            type="submit"
            disabled={!formik.isValid}
            style={{
              ...bottonStyle,
              backgroundColor: !formik.isValid ? "#e0e0e0" : "#6e0072",
            }}
          >
            {t("general.add")}
          </Button>

          <Box mt={3}>
            <Typography
              variant="body2"
              fontSize={16}
              color="secondary"
              onClick={() => {
                handleClose();
              }}
              sx={{
                cursor: "pointer",
                color: "#6e0072",
                textTransform: "uppercase",
              }}
            >
              {t("edit.cancel")}
            </Typography>
          </Box>
        </Box>
      </form>
    </CustomModal>
  );
};
