import React from "react";

import {
  Menu,
  UserInfo,
  ContentView,
  TopNav,
  TopComponent,
  Content,
  MenuContainer,
  DashboardContainer
} from './style.jsx';

export default function DashboardLayout({
  scrollable,
  topComponent,
  children,
}) {
  return (
    <DashboardContainer>
      <MenuContainer>
        <Menu />
      </MenuContainer>
      <Content>
        <TopNav>
          <UserInfo />
          {topComponent && <TopComponent>{topComponent}</TopComponent>}
        </TopNav>
        <ContentView scrollable={scrollable}>{children}</ContentView>
      </Content>
    </DashboardContainer>
  );
}
