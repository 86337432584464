import { useTranslation } from "react-i18next";
import React, { useContext } from "react";

import { NavigationContext } from "../../../contexts/NavigationContext";
import { withModal } from "../../modal";

import {
  PrimaryButton,
  CancelButton,
  OutlinedButton,
  DefaultButton,
  StyledIcon,
} from "./styles";

export const Button = ({
  icon,
  children,
  grouped,
  submit,
  cancel,
  primary,
  secondary,
  outlined,
  style,
  modal,
  action,
  upload,
  ...props
}) => {
  const { t } = useTranslation();
  const { actions } = useContext(NavigationContext);
  let Component =
    ((primary || submit) && PrimaryButton) ||
    (cancel && CancelButton) ||
    (outlined && OutlinedButton) ||
    DefaultButton;

  let innerText;

  if (upload) {
    innerText = t("general.upload");
  }

  if (submit) {
    innerText = t("general.submit");
  }

  if (cancel) {
    innerText = t("general.cancel");
  }

  if (action in actions) {
    ({ name: innerText, icon, modal } = actions[action]);
  }

  if (modal) {
    Component = withModal(Component, modal);
  }

  return (
    <Component {...props}>
      {icon && <StyledIcon icon={icon} />}
      {children || innerText}
    </Component>
  );
};

export default Button;
