import React, { useEffect, useState } from "react";

import { StyledTable, TableContainer, Title, Description } from "./styles";

import { TableRows } from "./TableRows";
import { TableHeading } from "./TableHeading";

export const Table = ({
  title,
  description,
  data,
  headingTranslationKey,
  columns,
  format,
  sortable = false,
  Wrapper,
  padding
}) => {
  const [sortConfig, setSortConfig] = useState({ field: null, direction: 1 });
  const [sortedData, setSortedData] = useState(data);

  const sorter = (config) => (a, b) => {
    return (a[config.field] > b[config.field] ? 1 : -1) * config.direction;
  };

  const updateSortConfig = (field) =>
    sortable &&
    setSortConfig((config) => ({
      field: field,
      direction: config.field === field ? config.direction * -1 : 1,
    }));

  useEffect(() => {
    sortable
      ? setSortedData(data.slice().sort(sorter(sortConfig)))
      : setSortedData(data);
  }, [data, sortConfig]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <TableContainer padding={padding}>
      {title && <Title>{title}</Title>}
      {description && <Description>{description}</Description>}
      <StyledTable>
        <TableHeading
          sortConfig={sortConfig}
          sortable={sortable}
          onClick={updateSortConfig}
          columns={columns}
          translationKey={headingTranslationKey}
        />
        <TableRows
          format={format}
          Wrapper={Wrapper}
          data={sortedData}
          columns={columns}
        />
      </StyledTable>
    </TableContainer>
  );
};
