import { AsyncTypeahead } from "react-bootstrap-typeahead";
import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";

import { APIContext } from "../../contexts/APIContext";
import { FormContext } from "../form";

const StyledAsyncTypeahead = styled(AsyncTypeahead)`
  .rbt-menu {
    background-color: white;
    z-index: 1;

    a {
      text-decoration: none;
    }
  }
`;

export const DoctorSelect = ({ idName, idData, name, data }) => {
  const { surgeonSearch } = useContext(APIContext);
  const { setFormValue } = useContext(FormContext);
  const [isLoading, setIsLoading] = useState(false);
  const option = { id: idData, label: data || "" };
  const [options, setOptions] = useState([option]);
  const [selected, setSelected] = useState(option);

  const updateFormValues = (value, id) => {
    setFormValue(idName, id || 0);
    setFormValue(name, value);
  };

  const handleOnSearch = (search) => {
    updateFormValues(search, 0);

    if (search.length >= 2) {
      setIsLoading(true);
      surgeonSearch({ search }).then((items) => {
        setOptions(
          items.map(({ id, firstName, lastName }) => ({
            id,
            label: `${firstName} ${lastName}`,
            value: id,
          }))
        );
        setIsLoading(false);
      });
    }
  };

  useEffect(() => {
    if (selected.length > 0) {
      updateFormValues(selected[0].label, selected[0].id);
    }
  }, [selected]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StyledAsyncTypeahead
      id={`select-${name}-typeahead`}
      emptyLabel=""
      minLength={0}
      isLoading={isLoading}
      defaultSelected={[option]}
      labelKey="label"
      options={options}
      onChange={setSelected}
      onSearch={handleOnSearch}
    />
  );
};
