import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { FormContext } from "./Form";
import Button from "./Button";

export const SubmitButton = ({
  children,
  disableIfUnchanged = false,
  disableEdit = false,
}) => {
  const { hasChanged, handleSubmit } = useContext(FormContext);
  const { t } = useTranslation();

  const handleOnClick = async (e) => {
    e.preventDefault();
    await handleSubmit();
  };

  return (
    <Button
      disabled={(disableIfUnchanged && !hasChanged()) || disableEdit}
      primary
      onClick={handleOnClick}
    >
      {children || t("edit.save")}
    </Button>
  );
};
