import {Modal} from "react-bootstrap";
import React, {useState} from "react";
import {Button} from "../base";
import {StyledModal} from "./styles";
import {ArrowBack} from "../icons";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";

export const CustomUserModal = ({
                                    title,
                                    children,
                                    onSubmit,
                                    showCancel,
                                    ...props
                                }) => (
    <StyledModal {...props} centered backdrop="static" keyboard={false}>
        <Box>
            {props.alertMessage.message &&
                <Alert sx={{display: 'flex', justifyContent: 'center', font: "normal normal 900 20px/20px Avenir"}}
                       icon={false}
                       severity={props.alertMessage.status === 200 ? "success" : "error"}>{props.alertMessage.message}</Alert>}
        </Box>
        <Box m={3}>
            <Modal.Title>{title}</Modal.Title>
        </Box>
        <Modal.Body>{children}</Modal.Body>
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-end',
            flexDirection: 'column',
            m: 4
        }}>
            <Box mb={3}>
                {showCancel && <Button cancel onClick={() => {
                    props.setUsersInValid();
                    props.onHide();
                }}/>}
                {onSubmit && (
                    <Button
                        submit
                        onClick={() => {
                            onSubmit();
                        }}
                    />
                )}
            </Box>
        </Box>
    </StyledModal>
);

export const CustomModal = ({
    title,
    children,
    onSubmit,
    showCancel,
    formik,
    ...props
}) => (
    <StyledModal {...props} centered backdrop="static" keyboard={false}>
        <Box>
            {props.alertMessage.message &&
                <Alert sx={{display: 'flex', justifyContent: 'center', font: "normal normal 900 20px/20px Avenir"}} icon={false} severity={props.alertMessage.status === 200 ? "success" : "error"}>{props.alertMessage.message}</Alert>}
        </Box>
        <Box p={8}>
            <Box display="flex" alignItems="center">
                <Box mt={1} mr={3} onClick={()=>{
                    props.close ? props.close() : props.onHide();
                    formik.resetForm();
                }}>
                    <ArrowBack />
                </Box>
                <Box>
                    <Modal.Title>{title}</Modal.Title>
                </Box>
            </Box>
            <Box>
                {children}
            </Box>
        </Box>
    </StyledModal>
);


export const BaseModal = ({
                              title,
                              children,
                              onSubmit,
                              showCancel,
                              ...props
                          }) => (
    <StyledModal {...props} centered>
        <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer>
            {showCancel && <Button cancel onClick={props.onHide}/>}
            {onSubmit && (
                <Button
                    submit
                    onClick={() => {
                        onSubmit() && props.onHide();
                    }}
                />
            )}
        </Modal.Footer>
    </StyledModal>
);

export const withModal = (Component, Modal) => {
    return (props) => {
        const [modalShow, setModalShow] = useState(false);
        const onShow = () => setModalShow(true);
        const onHide = () => setModalShow(false);

        return (
            <>
                <Component {...props} onClick={onShow}/>
                <Modal onHide={onHide} show={modalShow} data={props.data}/>
            </>
        );
    };
};
