import styled from "styled-components";
import { Text } from "../../components/base/Content";

export const Container = styled.div`
  margin: 20px 0px;
`;

export const Description = styled(Text)`
  margin-bottom: 40px;
`;

export const ActionFooter = styled.div`
  margin: 10px 0px 0px 0px;
  text-align: right;
`;
