import React, {useContext, useEffect, useState} from 'react';
import {Box, Grid, Typography} from "@mui/material";

import {useTranslation} from "react-i18next";
import MaskedInput from "react-text-mask";

import {getMask} from "../../../util";
import "./style.modules.scss"
import {isUndefined} from "lodash";
import {APIContext} from "../../../contexts/APIContext";
import Spinner from "react-bootstrap/Spinner";
import {SuccessValidationCheckmark} from "../../icons";

export const SerialNumber = (props) => {

    const {SN, formik, serial, vc, side, isExplantedFlow, autoMask} = props;

    //const estaApiKit = new EstaAPIKit();
    const {postValidateDevice} = useContext(APIContext);
    const {t} = useTranslation();
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const serialNumber = serial && serial.includes(".") ? formik.values[serial.split(".")[0]][serial.split(".")[1]] : formik.values[serial];
    const validationCode = vc && vc.includes(".") ? formik.values[vc.split(".")[0]][vc.split(".")[1]] : formik.values[vc];

    let validName = `serial.isValid${side}`;
    const isValid = validName.includes(".") ? formik.values[validName.split(".")[0]][validName.split(".")[1]] : formik.values[validName];

    const autoDetectType =()=>{
        return serialNumber.startsWith('9') ? 'ESN' : 'SN'
    }

    const validImplant = async () => {
        setIsLoading(true);
        let payload = {
            implants: {
                type: "B",
                left: {
                    serialNumber: (autoDetectType() === 'SN' && side === "Left") ? serialNumber : null,
                    validationCode: (autoDetectType() === 'SN' && side === "Left") ? validationCode : null,
                    electronicSerial: (autoDetectType() === 'ESN' && side === "Left") ? serialNumber : null
                },
                right: {
                    serialNumber: (SN && side === "Right") ? serialNumber : null,
                    validationCode: (SN && side === "Right") ? validationCode : null,
                    electronicSerial: (!SN && side === "Right") ? serialNumber : null
                }
            },
            tools: {
                injector: null,
                balloon: null
            }
        }

        if(!isUndefined(isExplantedFlow)){
            payload = {
                ...payload,
                isExplantedFlow : isExplantedFlow
            }
        }

        await postValidateDevice(payload).then((data) => {
            setError("");
            formik.setFieldValue(validName, true);
        }).catch((e) => {
            setError(e.response?.data?.result?.code_description);
            formik.setFieldValue(validName, false);
            setIsLoading(false)
        });

        setIsLoading(false);
    }

    function handleOnBlur() {
        const valueSN = serialNumber && serialNumber.replace(/^[\s_-]|[\s_-]/gm, "");

        if((isExplantedFlow && valueSN && valueSN.trim().length === 10)){
            validImplant();
        }

        if (valueSN && (valueSN.trim().length === 10 || valueSN.trim().length === 15) && (validationCode && validationCode.length === 1)) {
            validImplant();
        } else if (valueSN && (valueSN.trim().length < 10 || (validationCode && validationCode.length === 1)) || valueSN.trim().length < 15) {
            formik.setFieldValue(validName, false);
        }
    }

    useEffect(() => {
        setError("");
        const value = serialNumber && serialNumber.replace(/^[\s_-]|[\s_-]/gm, "");

        if ((autoMask && value && value.length === 10) || (value && value.length === 10 && validationCode)) {
            validImplant();
        }

        if (value && value.length === 15) {
            validImplant();
        }
    }, [serialNumber, validationCode]);

    useEffect(()=>{
        formik.setFieldValue(serial, "");
        formik.setFieldValue(vc, "");
    },[formik.values.typeSerialNumber])


    let col1Width = SN ? "6fr" : "7fr";
    let col2Width = SN ? "1fr" : "";
    let col3Width = isExplantedFlow || validationCode || (serialNumber && (serialNumber.length === 15)) ? "1fr" : "";

    return (
        <Box>
            <Grid container sx={{
                display: "grid",
                gridTemplateColumns: `${col1Width} ${col2Width} ${col3Width}`,
                gridGap: 10,
                alignItems: "start",
            }}>
                <Grid item>
                    <Box>
                        <MaskedInput
                            className="form-control serial-number-input custom-input"
                            name={serial}
                            type="text"
                            mask={getMask("SN")}
                            render={(ref, props) => {
                                return (
                                    <input placeholder={"00000000-00"} ref={ref} {...props}
                                           style={{width: "100%"}}/>
                                );
                            }}
                            onChange={formik.handleChange}
                            value={serialNumber}
                            onBlur={(e) => {
                                handleOnBlur();
                                formik.handleBlur(e);
                            }}
                        />
                        <Box>
                            <Typography variant={"caption"} sx={{color: "red"}}>
                                {error && t(error)}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>

                {SN && <Grid item>
                    <input
                        className="form-control validation-number-input"
                        name={vc}
                        type="text"
                        placeholder="X"
                        maxLength={1}
                        style={{width: "100%"}}
                        onChange={formik.handleChange}
                        value={validationCode}
                        onBlur={() => handleOnBlur()}
                    />
                </Grid>
                }

                {((isExplantedFlow && (serialNumber && serialNumber.length === 11)) || validationCode || (serialNumber && serialNumber.length === 15)) &&
                    <Grid item sx={{
                        height: 40,
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <Box className="successfull-validation-checkmark">
                            {isValid &&
                                <SuccessValidationCheckmark/>}
                            {!isValid && isLoading && (
                                <Spinner animation="border" variant="primary"/>
                            )}

                        </Box>
                    </Grid>}
            </Grid>
        </Box>
    );
}