import { useTranslation } from "react-i18next";
import React from "react";

import { CenteredFullMessage } from "../../../base/CenteredFull";
import {
  PatientSearchResultsContainer,
  Content,
  ResponseContainer,
  Title,
} from "./styles";
import { PatientCard } from "../../PatientCard";
import { PatientSearchCriteria } from "../PatientSearchCriteria";
import { AddImplantModal } from "../../../../modals/AddImplantModal";
import useSearch from "../../../../hooks/useSearch";

export const PatientSearchResults = ({ patient, criteria }) => {
  const { t } = useTranslation();
  const { refreshSearch } = useSearch();
  const [openModal, setOpenModal] = React.useState(false);
  const searchSerial = criteria?.serial;

  const newItem = () => {
    setOpenModal(true);
  };

  const closeModal = (success = false) => {
    setOpenModal(false);
    if (success) {
      refreshSearch();
    }
  };

  if (patient) {
    return (
      <ResponseContainer>
        <PatientSearchResultsContainer>
          <PatientSearchCriteria criteria={criteria} />
          <Title>{t("search.title")}</Title>
          <PatientCard data={patient} />
        </PatientSearchResultsContainer>
      </ResponseContainer>
    );
    // If criteria is set, but no patient,
    // is because a search was done with no results
  } else if (criteria) {
    return (
      <>
        <ResponseContainer>
          <Title>{t("search.title")}</Title>
          <Content>
            <CenteredFullMessage
              title={t("search.messages.notFound.title")}
              subtitle={t("search.messages.notFound.subtitle")}
              specialElement={
                searchSerial !== "" &&
                patient === undefined &&
                searchSerial !== null &&
                searchSerial !== undefined && (
                  <a onClick={() => newItem()}>
                    + {t("search.messages.notFound.addImplant")}
                  </a>
                )
              }
            />
          </Content>
        </ResponseContainer>
        <AddImplantModal
          show={openModal}
          close={closeModal}
          searchSerial={searchSerial}
        />
      </>
    );
  } else {
    return (
      <ResponseContainer>
        <Title>{t("search.title")}</Title>
        <Content>
          <CenteredFullMessage
            // title={t("search.messages.welcome.title")}
            subtitle={t("search.messages.welcome.subtitle")}
          />
        </Content>
      </ResponseContainer>
    );
  }
};
