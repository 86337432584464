import React, { useContext, useState } from "react";
import { APIContext } from "../../../../contexts/APIContext";

import { ReportUploadArea } from "./ReportUploadArea";
import { ReportUploadInstructions } from "./ReportUploadInstructions";

import { CustomerCareReportContainer } from "./styles";
import { ShowLoading } from "../../../../alerts/ShowLoading";

import fileDownload from "js-file-download";

export const CustomerCareReport = () => {
  const { getCustomerCareReport } = useContext(APIContext);
  const [loading, setLoading] = useState(false);
  const handleOnUpload = (data) => {
    setLoading(true);
    getCustomerCareReport(data)
      .then((data) => fileDownload(data, "report.csv"))
      .then(() => setLoading(false));
  };

  return (
    <CustomerCareReportContainer>
      <ShowLoading loading={loading} />
      <ReportUploadArea onUpload={handleOnUpload} />
      <ReportUploadInstructions />
    </CustomerCareReportContainer>
  );
};
