import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import React from "react";
import styled from "styled-components";

library.add(fas);

const Svg = styled.svg.attrs({
  version: "1.1",
  xmlns: "http://www.w3.org/2000/svg",
  xmlnsXlink: "http://www.w3.org/1999/xlink",
})``;

const Reports = ({ className }) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.192"
    height="21.33"
    viewBox="0 0 17.192 21.33"
    className={className}
  >
    <path
      id="_5-reports-hover"
      data-name="5-reports-hover"
      d="M19.33,46.345V37.128A2.128,2.128,0,0,0,17.2,35H.128A2.128,2.128,0,0,0-2,37.128V50.064A2.128,2.128,0,0,0,.128,52.192H13.483a2.128,2.128,0,0,0,1.5-.623l3.719-3.719A2.128,2.128,0,0,0,19.33,46.345Zm-5.675,3.547V46.517H17.03ZM17.2,37.128v7.261H12.591a1.064,1.064,0,0,0-1.064,1.064v4.611H.128V37.128Z"
      transform="translate(-35 19.33) rotate(-90)"
      fill="currentColor"
    />
  </Svg>
);
const User = ({ className }) => (
  <Svg
    id="_7-users-hover"
    data-name="7-users-hover"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    className={className}
  >
    <path id="Path_3509" data-name="Path 3509" d="M0,0H24V24H0Z" fill="none" />
    <path
      id="Path_3510"
      data-name="Path 3510"
      d="M12,12A4,4,0,1,0,8,8,4,4,0,0,0,12,12Zm0,2c-2.67,0-8,1.34-8,4v1a1,1,0,0,0,1,1H19a1,1,0,0,0,1-1V18C20,15.34,14.67,14,12,14Z"
      fill="currentColor"
    />
  </Svg>
);

const Tickets = ({ className }) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="24.419"
    height="24.419"
    viewBox="0 0 24.419 24.419"
    className={className}
  >
    <g
      id="_4-tickets-hover"
      data-name="4-tickets-hover"
      transform="translate(22484.977 7191.612) rotate(-45)"
    >
      <path
        id="Path_3488"
        data-name="Path 3488"
        d="M339,237.663a.286.286,0,0,0,.572,0v-.877a.286.286,0,0,0-.572,0Z"
        transform="translate(-11149.878 -21204.492)"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        id="Path_3490"
        data-name="Path 3490"
        d="M109.842,102.911a.7.7,0,0,0,.577-.693V99.549a1.05,1.05,0,0,0-1.049-1.049h-4.232a1.313,1.313,0,0,0-1.03.5c-.241-.3,1.446-.5,1.029-.5H94.786a.286.286,0,1,0,0,.572h8.293a.744.744,0,0,1,.744.744v.457a.286.286,0,1,0,.572,0v-.457a.744.744,0,0,1,.744-.744h4.232a.477.477,0,0,1,.477.477v2.669a.132.132,0,0,1-.107.131,2.193,2.193,0,0,0,0,4.314.132.132,0,0,1,.107.131v2.724a.286.286,0,1,0,.572,0v-2.724a.7.7,0,0,0-.577-.693,1.621,1.621,0,0,1,0-3.189Z"
        transform="translate(-10914.7 -21071.756)"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        id="Path_3491"
        data-name="Path 3491"
        d="M19.236,108.967a.286.286,0,0,0-.286.286v.21a.477.477,0,0,1-.477.477H14.241a.744.744,0,0,1-.744-.744v-.5a.286.286,0,0,0-.572,0v.5a.744.744,0,0,1-.744.744H1.049a.477.477,0,0,1-.477-.477v-2.669a.132.132,0,0,1,.107-.131,2.193,2.193,0,0,0,0-4.314.132.132,0,0,1-.107-.131V99.549a.477.477,0,0,1,.477-.477H5.22a.286.286,0,1,0,0-.572H1.049A1.05,1.05,0,0,0,0,99.549v2.669a.7.7,0,0,0,.577.693,1.621,1.621,0,0,1,0,3.189.7.7,0,0,0-.577.693v2.669a1.05,1.05,0,0,0,1.049,1.049H14.241c.417,0-1.271-.195-1.03-.5a1.313,1.313,0,0,0,1.03.5h4.232a1.05,1.05,0,0,0,1.049-1.049v-.21a.286.286,0,0,0-.286-.286Z"
        transform="translate(-10823.804 -21071.756)"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </g>
  </Svg>
);

const Errors = ({ className }) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="22.608"
    height="20.223"
    viewBox="0 0 22.608 20.223"
    className={className}
  >
    <g
      id="Group_3643"
      data-name="Group 3643"
      transform="translate(-32.696 -297.888)"
    >
      <g
        id="warning_1_"
        data-name="warning (1)"
        transform="translate(33.971 299.139)"
      >
        <path
          id="Path_3497"
          data-name="Path 3497"
          d="M19.881,44.19l-8.714-15.1a1.314,1.314,0,0,0-2.275,0L.177,44.19a1.315,1.315,0,0,0,1.138,1.971H18.743A1.315,1.315,0,0,0,19.881,44.19Z"
          transform="translate(0 -28.439)"
          fill="none"
          stroke="currentColor"
          strokeWidth="2.5"
        />
      </g>
      <path
        id="Path_3511"
        data-name="Path 3511"
        d="M32.379,46.917a1,1,0,0,1,.994,1.023l-.164,5.733a.834.834,0,0,1-1.667,0l-.16-5.733A1,1,0,0,1,32.379,46.917Zm-.012,10.369A1.035,1.035,0,1,1,33.4,56.251,1.036,1.036,0,0,1,32.366,57.286Z"
        transform="translate(11.634 256.951)"
        fill="currentColor"
      />
    </g>
  </Svg>
);

const Email = ({ className }) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="16"
    viewBox="0 0 20 16"
    className={className}
  >
    <path
      id="Path_3513"
      data-name="Path 3513"
      d="M20,4H4A2,2,0,0,0,2.01,6L2,18a2.006,2.006,0,0,0,2,2H20a2.006,2.006,0,0,0,2-2V6A2.006,2.006,0,0,0,20,4ZM19,18H5a1,1,0,0,1-1-1V8l6.94,4.34a1.981,1.981,0,0,0,2.12,0L20,8v9A1,1,0,0,1,19,18Zm-7-7L4,6H20Z"
      transform="translate(-2 -4)"
      fill="currentColor"
    />
  </Svg>
);

const Implant = ({ className }) => (
  <Svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="23.097"
    height="13.379"
    viewBox="0 0 23.097 13.379"
  >
    <path
      id="XMLID_509_"
      d="M299.8,45.673a12.627,12.627,0,0,0-21.555,0,4.915,4.915,0,0,0-.268,4.81,4.361,4.361,0,0,0,3.9,2.521h13.264a1.11,1.11,0,0,0,0-2.215H281.872a2.279,2.279,0,0,1-2.061-1.335,2.543,2.543,0,0,1,.138-2.522,10.628,10.628,0,0,1,18.145,0,2.543,2.543,0,0,1,.138,2.522,2.279,2.279,0,0,1-2.06,1.335h-2.283a1.11,1.11,0,0,0,0,2.215h2.283a4.361,4.361,0,0,0,3.9-2.521A4.915,4.915,0,0,0,299.8,45.673Z"
      transform="translate(-277.474 -39.625)"
      fill="currentColor"
    />
  </Svg>
);

const Edit = ({ className }) => (
  <Svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      id="Path_7"
      data-name="Path 7"
      d="M3,17.25V21H6.75L17.81,9.94,14.06,6.19ZM20.71,7.04a1,1,0,0,0,0-1.41L18.37,3.29a1,1,0,0,0-1.41,0L15.13,5.12l3.75,3.75,1.83-1.83Z"
      fill="currentColor"
    />
  </Svg>
);

const Warranty = ({ className }) => (
  <Svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g data-name="6-warranty-hover" transform="translate(0 -1.5)">
      <g id="verified_black_24dp" transform="translate(0 1.5)">
        <g id="Group_3638" data-name="Group 3638">
          <rect
            id="Rectangle_3450"
            data-name="Rectangle 3450"
            width="24"
            height="24"
            fill="none"
          />
          <rect
            id="Rectangle_3451"
            data-name="Rectangle 3451"
            width="24"
            height="24"
            fill="none"
          />
        </g>
        <g id="Group_3639" data-name="Group 3639">
          <path
            id="Path_3514"
            data-name="Path 3514"
            d="M23,12c0-1.4-2.44-2.79-2.44-2.79s1.157-2.562.34-3.69-3.61-.82-3.61-.82-.567-2.765-1.89-3.2S12,2.96,12,2.96,9.923,1.067,8.6,1.5,6.71,4.69,6.71,4.69,3.918,4.372,3.1,5.5s.34,3.7.34,3.7S1,10.6,1,12s2.44,2.79,2.44,2.79-1.157,2.57-.34,3.7,3.61.82,3.61.82.568,2.76,1.89,3.19S12,21.03,12,21.03s2.077,1.892,3.4,1.46,1.89-3.19,1.89-3.19,2.793.308,3.61-.82-.34-3.69-.34-3.69S23,13.4,23,12Z"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
          />
        </g>
      </g>
      <path
        id="Path_3517"
        data-name="Path 3517"
        d="M9.38,16.01,7,13.61A1,1,0,0,1,7,12.2l.07-.07a1.008,1.008,0,0,1,1.42,0l1.61,1.62,5.15-5.16a1.008,1.008,0,0,1,1.42,0l.07.07a1,1,0,0,1,0,1.41l-5.92,5.94A1.032,1.032,0,0,1,9.38,16.01Z"
        transform="translate(0.131 1.199)"
        fill="currentColor"
      />
    </g>
  </Svg>
);

export const ArrowBack = ({ className }) => (
  <Svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
  >
    <g id="Layer%201">
      <path d="M 2.0417 9.9607 C 1.762 10.2508 1.762 10.7215 2.052 11.0203 L 8.8694 17.7879 C 9.1692 18.0681 9.6399 18.0681 9.9387 17.7879 C 10.2288 17.4881 10.2288 17.0183 9.9387 16.7283 L 4.4619 11.3035 L 19.8293 11.3035 C 20.2437 11.3035 20.5793 10.9677 20.5793 10.5535 C 20.5793 10.1393 20.2437 9.8035 19.8293 9.8035 L 4.3418 9.8035 L 9.9231 4.3162 C 10.2131 4.0164 10.2131 3.5467 9.9231 3.2566 C 9.6243 2.9666 9.1536 2.9666 8.8538 3.2566 L 2.0417 9.9607 Z" fill="#707070"/>
    </g>
  </Svg>
);

export const SuccessValidationCheckmark = ({ className }) => (
    <div className="val-check">
      <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="15.53"
          viewBox="0 0 20 15.53"
      >
        <path
            id="check-icon"
            d="M7.658,15.637a1.021,1.021,0,0,1-1.444,0L.449,9.87a1.531,1.531,0,0,1,0-2.166l.722-.722a1.531,1.531,0,0,1,2.166,0l3.6,3.6L16.663.855a1.531,1.531,0,0,1,2.166,0l.722.722a1.531,1.531,0,0,1,0,2.166Zm0,0"
            transform="translate(0 -0.406)"
            fill="#42d15f"
        />
      </svg>
    </div>
);

const localIcons = {
  Warranty,
  Edit,
  Reports,
  Errors,
  User,
  Implant,
  Email,
  Tickets,
  ArrowBack
};

export const Icon = ({ icon, className }) => {
  const iconName = icon.charAt(0).toUpperCase() + icon.slice(1);

  if (iconName in localIcons) {
    const Component = localIcons[iconName].bind(this);
    return <Component />;
  } else {
    return <FontAwesomeIcon icon={icon} className={className} />;
  }
};

export const Logout = styled(FontAwesomeIcon).attrs({ icon: "sign-out-alt" })``;
