import { useTranslation } from "react-i18next";
import React from "react";

import { Alert } from "../../components/alert";

export const ConfirmSaved = ({ saved }) => {
  const { t } = useTranslation();
  return (
    <Alert show={saved} dismiss={3000}>
      {t("general.saved")}
    </Alert>
  );
};
