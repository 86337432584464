import React, { useContext, } from "react";
import { APIContext } from "../../contexts/APIContext";
import { DefaultTypeahead } from "./DefaultTypeahead";

export const CountrySelect = ({ name, data, placeholder, disabled = false }) => {
  const { countries } = useContext(APIContext);
  const fromData = (data) => [
    {
      id: data,
      label: countries[data] || "",
    },
  ];


  return (
    <DefaultTypeahead
      name={name}
      data={fromData(data)}
      entries={countries}
      placeholder={placeholder}
      disabled={disabled}
    />
  );
};
