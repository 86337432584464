import React, { useContext, } from "react";
import { SimpleContextDropdown } from "./SimpleContextDropdown";
import { APIContext } from "../../contexts/APIContext";
import { translateWithPrefix } from "../../formatters";

export const RoleSelect = ({ name, data, autoSubmit }) => {
  const { roles } = useContext(APIContext);
  const roleName = translateWithPrefix("user.role");

  return (
    <SimpleContextDropdown
      name={name}
      value={data}
      options={Object.fromEntries(
        roles.map((role, index) => [index, roleName(role)])
      )}
    />
  );
};
