import { DataCard } from "../DataCard";
import { DataTable, DataCardFooter } from "./styles";

export const DataDict = ({ title, topRight, onClick, footer, children }) => {
  return (
    <DataCard title={title} topRight={topRight} onClick={onClick}>
      <DataTable>{children}</DataTable>
      {footer && <DataCardFooter>{footer}</DataCardFooter>}
    </DataCard>
  );
};
