import React from "react";
import Layout from "../../../layouts/Layout";
import DistributorCatalog from "../../../components/maintenance/DistributorCatalog";

export default function DistributorMaintenance() {
    return (
        <Layout topComponent={<>
            <h2 className="titleCatalogs">Distributor maintenance</h2>
        </>}>
            <DistributorCatalog />
        </Layout>
    );
}