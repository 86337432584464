import { useTranslation } from "react-i18next";
import { StyledCustomModal } from "./styles";
import { DataDict, DataEntries } from "../../components/data";

import { useUsers } from "../../hooks/useUsers";

import {
  Form,
  RoleSelect,
  TextInput,
  FormContext,
} from "../../components/form";
import React, { useContext } from "react";
import { StyledModal } from "../../components/modal/styles";
import { Modal } from "react-bootstrap";
import { Button } from "../../components/base";

const UserDetailModalContents = ({ data, show, ...props }) => {
  const { t } = useTranslation();
  const { handleSubmit, alertMessage } = useContext(FormContext);
  return (
    <StyledCustomModal
      show={show}
      {...props}
      title={t("user.add.title")}
      onSubmit={handleSubmit}
      alertMessage={alertMessage}
      showCancel={true}
    >
      <DataDict>
        <DataEntries
          data={data}
          keys={["fullName", "roleId", "email", "department"]}
          translationKey="user.data"
          Wrapper={{
            fullName: TextInput,
            email: TextInput,
            department: TextInput,
            roleId: RoleSelect,
          }}
        />
      </DataDict>
    </StyledCustomModal>
  );
};

export const UserDetailsModal = ({
  data = { fullName: "", email: "", roleId: 0, department: "" },
  ...props
}) => {
  const { updateUser, postUser, setUsersInValid } = useUsers();
  const handleSubmit = data.id ? updateUser.bind(null, data.id) : postUser;

  return (
    <Form
      onSubmit={handleSubmit}
      data={data}
      fields={["fullName", "roleId", "email", "department"]}
    >
      <UserDetailModalContents
        data={data}
        {...props}
        setUsersInValid={setUsersInValid}
      />
    </Form>
  );
};

export const DeleteUserModal = ({
  data = { fullName: "", email: "" },
  ...props
}) => {
  const { t } = useTranslation();
  const { deleteUser, setUsersInValid } = useUsers();

  const handleSubmit = async () => {
    await deleteUser(data.email);
    setUsersInValid();
    props.onHide();
  };

  return (
    <StyledModal {...props} centered backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>{t("user.deleteModal.title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {t("user.deleteModal.body", { name: data.fullName, email: data.email })}
      </Modal.Body>
      <Modal.Footer>
        <Button cancel onClick={props.onHide} />
        <Button submit onClick={handleSubmit}>
          {t("general.delete")}
        </Button>
      </Modal.Footer>
    </StyledModal>
  );
};
