import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Button } from "./Button";
import { Text } from "./Content";

const StyledCopyText = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 20px;
  padding: 10px;
  margin: 10px 0px;
  border: 1px solid ${(props) => props.theme["$primary"]};
`;

const StyledButton = styled(Button)`
  transition-duration: 0.5s;
  &:active {
    background-color: ${(props) => props.theme["$light-primary"]};
    color: ${(props) => props.theme["$primary"]};
  }
`;

export const CopyText = ({ children, className }) => {
  const { t } = useTranslation();
  const copy = () => {
    navigator.clipboard.writeText(children);
  };
  return (
    <StyledCopyText className={className}>
      <Text>{children}</Text>
      <StyledButton primary icon="copy" onClick={copy}>
        {t("general.copyText")}
      </StyledButton>
    </StyledCopyText>
  );
};
