import { Nav, NavItem, Navbar } from "@bootstrap-styled/v4";
import React, { useContext } from "react";
import styled from "styled-components";
import { Link } from "../../components/base";
import { ActionList } from "../../components/actions/ActionList";
import { NavigationContext } from "../../contexts/NavigationContext";
import { Logo } from "../base/MotivaLogo";

const SideNavbar = styled(Navbar)`
  display: flex;
  height: 100vh;
  justify-content: flex-start;
  align-items: center;
`;

const SideNav = styled(Nav)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

const MenuBrand = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 50%;
  width: 180px;
  height: 180px;
`;

const MotivaLogoMenu = styled(Logo)`
  color: ${(props) => props.theme.$primary};
  width: 100%;
  height: 100%;
  margin: auto;
`;

const MenuLink = styled(Link)`
  padding: 3px 10px;
  margin-bottom: 10px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  svg {
    margin: 0px 5px;
  }

  &.active {
    background-color: ${(props) => props.theme["$primary-background"]};
  }
`;

const StyledNavItem = styled(NavItem)`
  min-width: 165px;
  display: block;
  &:before {
    content: "";
    font-size: 1.5em;
    color: ${(props) => props.theme["$primary"]};
  }
`;

const MenuGroup = ({ children, className, ...props }) => (
  <SideNav {...props}>{children}</SideNav>
);

const MenuItem = ({ action }) => (
  <StyledNavItem>
    <MenuLink nav action={action} />
  </StyledNavItem>
);

export default function Menu() {
  const { menuItems } = useContext(NavigationContext);
  return (
    <SideNavbar>
      <MenuBrand>
        <Link action="home">
          <MotivaLogoMenu />
        </Link>
      </MenuBrand>
      <MenuGroup>
        <ActionList actions={menuItems} ItemComponent={MenuItem} />
      </MenuGroup>
    </SideNavbar>
  );
}
