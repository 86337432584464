import React, { useContext, } from "react";
import { NavigationContext } from "../../contexts/NavigationContext";
import { SimpleContextDropdown } from "./SimpleContextDropdown";

export const IndicationSelect = ({ name, data }) => {
  const {
    objectOptions: { implantIndication },
  } = useContext(NavigationContext);

  return (
    <SimpleContextDropdown
      name={name}
      value={data}
      options={implantIndication}
    />
  );
};
