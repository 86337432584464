import styled from "styled-components";

export const StyledRadio = styled.div`
  position: relative;
  width: 2em;
  height: 1.5em;
  display: inline-block;
  vertical-align: middle;
  text-align: left;

  .radio-input {
    display: none;
  }
  .radio-inner {
    position: absolute;
    top: 0;
    display: block;
    background-color: ${(props) => props.theme["$clear-background"]};
    border-radius: 20px;
    width: 1.5em;
    height: 1.5em;
    transition: all 0.2s ease-in 0s;
  }

  .radio-switch {
    position: absolute;
    display: block;
    top: 0;
    right: 0.5em;
    margin: 0.25em;
    border: 0.25em solid ${(props) => props.theme["$dark-shaded-background"]};
    background-color: ${(props) => props.theme["$primary-background"]};
    width: 1em;
    height: 1em;
    border-radius: 20px;
    transition: all 0.2s ease-in 0s;
  }

  .radio-input:checked + .radio-label {
    .radio-switch {
      margin-left: 0;
      background-color: ${(props) => props.theme["$primary"]};
      border: 0.25em solid ${(props) => props.theme["$primary-background"]};
    }
    .radio-inner {
      margin-left: 0;
      background-color: ${(props) => props.theme["$clear-background"]};
    }
  }
`;
