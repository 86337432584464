import React, { useContext, useEffect } from "react";

import { AzureAuthContext } from "../../contexts/AzureAuthContext";
import ActionDialog from "../../components/base/ActionDialog";
import Layout from "../../layouts/Layout";
import { useHistory } from "react-router-dom";
import { NavigationContext } from "../../contexts/NavigationContext";

import LoginBackground from "../../images/bg-image.png";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Logo } from "../../components/base/MotivaLogo";

const MotivaLogo = styled(Logo)`
  width: 128px;
  color: ${(props) => props.theme.$primary};
`;

const LoginDialog = styled(ActionDialog)`
  background-color: rgba(255, 255, 255, 0.3) !important;
  backdrop-filter: blur(10px);
  width: 70vw;
  height: 40vh;
  padding: 100px 0px;
  margin: 30px;
  @media only screen and (min-width: 700px) {
    width: 500px;
    height: 47vh;
  }

  & > h1 {
    margin-Top: 20px;
    font-size: 28px;
    max-width: 65%;
    line-height: 40px;
    color: white;
    @media only screen and (min-width: 700px) {
      max-width: 400px;
      margin: 40px 30px;
      line-height: 70px;
      font-size: 50px
    }
  }

  button {
    border-radius: 50px !important;
    font-size: 18px !important;
    height: 50px;
    width: 124px;
    text-transform: uppercase;
    @media only screen and (min-width: 700px) {
      font-size: 20px !important;
      height: 60px;
      width: 164px;
    }
  }
`;

export default function Login({ query }) {
  const { login, isAuthenticated } = useContext(AzureAuthContext);
  const { defaultUrl } = useContext(NavigationContext);
  const router = useHistory();
  const returnUrl = query?.r || defaultUrl;
  const { t } = useTranslation();

  const checkAuth = async (reload = false) => {
    if (await isAuthenticated(reload)) {
      router.push(returnUrl);
    }
  };

  const doLogin = async () => {
    try {
      await login();
      await checkAuth(true);
    } catch (_e) {
      // do nothing
    }
  };

  useEffect(() => {
    checkAuth();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout login background={LoginBackground}>
      <LoginDialog
        header={<MotivaLogo />}
        title={t("login.title")}
        action={t("login.loginAction")}
        onAction={doLogin}
      />
    </Layout>
  );
}

Login.getInitialProps = ({ query }) => {
  return { query };
};
