import React, {useContext, useEffect, useState} from "react";

import DashboardLayout from "./DashboardLayout";
import FullScreenLayout from "./FullScreenLayout";
import withAuthentication from "../components/auth/withAuthentication";
import withAuthorization from "../components/auth/withAuthorization";

import {useDispatch} from "react-redux";
import {setMaintenanceMode} from '../store/slices/uiSlice';
import {APIContext} from "../contexts/APIContext";
import MaintenancePage from "../pages/MaintenancePage";
import {setFeatureFlag} from "../store/slices/featureFlagSlice";

export default function Layout({login, children, ...props}) {

    const {getVersion, getFeatures} = useContext(APIContext);
    let Component;
    const dispatch = useDispatch();
    const [maintenanceModeState, setMaintenanceModeState] = useState(null)

    const maintenance = () => {
        getVersion().then((data) => {
            setMaintenanceModeState(data.Version.maintenance_mode);
            dispatch(setMaintenanceMode({
                maintenanceMode: data.Version.maintenance_mode
            }))
        });
    }

    useEffect(() => {
        maintenance();
        loadFeatures();
    }, []);

    const loadFeatures = ()=>{
        getFeatures().then((data) =>
            dispatch(setFeatureFlag({
                features: data.message && data.message.map((feat)=> ({feature: feat.feature, status: feat.status}))
            }))
        );
    }

    if (login) {
        Component = FullScreenLayout;
    } else {
        Component = withAuthentication(withAuthorization(DashboardLayout));
    }

    if (maintenanceModeState === 1) {
        return <MaintenancePage/>;
    }

    return <Component {...props}>{children}</Component>;
}
