import React, { useState } from "react";
import { StyledDropZone } from "./styles";
import { FileInfo } from "./FileInfo";
import { Instructions } from "./Instructions";

export const DropZone = ({
  name,
  className,
  icon,
  instructions,
  chooseFileInstructions,
  validTypes = [],
  onUpload,
}) => {
  const [selectedFile, setSelectedFile] = useState();

  const validateFile = (file) => {
    return validTypes.length === 0 || validTypes.includes(file.type);
  };

  /*
  const handleOnUpload = () => {
    const formData = new FormData();
    formData.append("file", selectedFile);
    axios.post(url, formData);
  };
  */

  const onDragEnter = (e) => {
    e.preventDefault();
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const onDragLeave = (e) => {
    e.preventDefault();
  };

  const onDrop = (e) => {
    e.preventDefault();

    const { files } = e.dataTransfer;

    if (files[0] && validateFile(files[0])) {
      setSelectedFile(files[0]);
    }
  };

  const fileSelected = (file) => {
    setSelectedFile(file);
  };

  return (
    <StyledDropZone
      onDragEnter={onDragEnter}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
      className={className}
    >
      {selectedFile ? (
        <FileInfo
          file={selectedFile}
          onUpload={() => onUpload({ [name]: selectedFile })}
        />
      ) : (
        <Instructions
          text={instructions}
          icon={icon}
          uploadButton={chooseFileInstructions}
          onUpload={fileSelected}
        />
      )}
    </StyledDropZone>
  );
};
