import React, {useContext, useEffect, useState} from "react";
import {APIContext} from "../../../../contexts/APIContext";

import {BIAnnualReportContainer} from "./styles";
import {BIAnnualReportFilter} from "./BIAnnualReportFilter";
import {ShowLoading} from "../../../../alerts/ShowLoading";
import {Button} from "../../../form";
import {useTranslation} from "react-i18next";
import fileDownload from "js-file-download";

export const BIAnnualReport = () => {
    const {getBIAnnualReport} = useContext(APIContext);
    const [loading, setLoading] = useState(false);
    const {t} = useTranslation();

    const handleOnSubmit = (type) => {
        setLoading(true);

        let payload = {
            year: "2024",
            reportType: type
        }
        return getBIAnnualReport(payload)
            .then((response) => {
                fileDownload(response, "report.csv")
            })
            .then(() => setLoading(false)); // fix
    };

    return (
        <BIAnnualReportContainer>
            <ShowLoading loading={loading}/>
            <BIAnnualReportFilter onSubmit={handleOnSubmit}/>
            <div className="list__implants">
                <div className="row">
                    <div className="col-6">
                        <div className="col-8 pt-3">
                            <Button primary onClick={() => handleOnSubmit(1)}>{t("reports.BIAnnual.1")}</Button>
                        </div>
                        <div className="col-8 pt-3">
                            <Button primary onClick={() => handleOnSubmit(2)}>{t("reports.BIAnnual.2")}</Button>
                        </div>
                        <div className="col-8 pt-3">
                            <Button primary onClick={() => handleOnSubmit(5)}>{t("reports.BIAnnual.5")}</Button>
                        </div>
                        <div className="col-8 pt-3">
                            <Button primary onClick={() => handleOnSubmit(6)}>{t("reports.BIAnnual.6")}</Button>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="col-8 pt-3">
                            <Button primary onClick={() => handleOnSubmit(4)}>{t("reports.BIAnnual.4")}</Button>
                        </div>
                        <div className="col-8 pt-3">
                            <Button primary onClick={() => handleOnSubmit(3)}>{t("reports.BIAnnual.3")}</Button>
                        </div>
                    </div>
                </div>
            </div>
        </BIAnnualReportContainer>
    );
};
