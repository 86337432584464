import React from "react";

import { Table } from "../../../components/base/Table";
import { Form, ToggleInput, RoleSelect } from "../../../components/form";
import { Button } from "../../../components/base";

import { useUsers } from "../../../hooks/useUsers";

export const UsersList = () => {
  const { users, updateUser } = useUsers();

  const RoleSelectSubmit = ({ rowData, ...props }) => (
    <Form
      autoSubmit
      fields={["id", "fullName", "department", "email", "roleId", "isActive"]}
      data={{ ...rowData }}
      onSubmit={(data) => updateUser(rowData.id, data)}
    >
      <RoleSelect {...props} />
    </Form>
  );

  const CheckboxSubmit = ({ rowData, ...props }) => (
    <Form
      autoSubmit
      fields={["id", "fullName", "department", "email", "roleId", "isActive"]}
      data={{ ...rowData }}
      onSubmit={(data) => updateUser(rowData.id, data)}
    >
      <ToggleInput {...props} />
    </Form>
  );

  const EditUser = ({ rowData }) => (
    <Button primary action="userEdit" data={rowData} id={rowData.id} />
  );

  const DeleteUser = ({ rowData }) => (
    <Button primary action="deleteUser" data={rowData} id={rowData.id} />
  );

  return (
    <>
      {users && (
        <Table
          data={users}
          columns={[
            "fullName",
            "email",
            "roleId",
            "department",
            "isActive",
            "id",
            "delete",
          ]}
          Wrapper={{
            roleId: RoleSelectSubmit,
            isActive: CheckboxSubmit,
            id: EditUser,
            delete: DeleteUser,
          }}
          headingTranslationKey="user.data"
        />
      )}
    </>
  );
};
