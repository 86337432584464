import React, {useContext, useEffect, useState} from "react";
import {Button} from "../../form";
import {TableComponent} from "../../base/TableComponent";
import {APIContext} from "../../../contexts/APIContext";
import {ModalComponent} from "../../base/ModalComponent";
import {useFormik} from "formik";
import * as Yup from "yup";
import {FormComponent} from "../../dynamic/form";
import {formDistributorCatalog} from "../../../forms";
import {useTranslation} from "react-i18next";
import {isNumber} from "lodash";

export default function DistributorCatalog() {
    const {t} = useTranslation();
    const {
        getAllDistributors,
        updateDistributor,
        createDistributor,
        deleteDistributor,
        getCountries
    } = useContext(APIContext);
    const [distributors, setDistributors] = React.useState([]);
    const [countries, setCountries] = React.useState([]);
    const [openModal, setOpenModal] = React.useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        getAllCountries();
    }, []);

    const getData = (page) => {
        setLoading(true)
        getAllDistributors(page).then((data) => {
            setDistributors(data);
        }).finally(() => setLoading(false));
    }

    const getAllCountries = () => {
        getCountries().then((data) => setCountries(data));
    }

    const newItem = () => {
        setOpenModal(true);
    }

    const editItem = (row) => {
        setOpenModal(true);
        formik.setFieldValue('id', row.id);
        formik.setFieldValue('code', row.code);
        formik.setFieldValue('name', row.name);
        formik.setFieldValue('country', row.country_id);
        formik.setFieldValue('isTesting', row.is_testing === "true" ? 1 : 0);
    }

    const deleteItem = async (id) => {
        let payload = {
            id: id
        }
        await deleteDistributor(payload).then((data) => {
            setDistributors([]);
            getData();
            closeModal();
        }).catch((e) => {
            setErrorMessage("An error occurred while deleting the distributor");

            setTimeout(()=> {
                setErrorMessage(null);
            }, 4000)
        });
    }

    const closeModal = () => {
        setOpenModal(false);
        formik.resetForm();
    }

    const columns = [
        {label: "Id", keyValue: "id", minWidth: "80px"},
        {label: "Code", keyValue: "code"},
        {label: "Name", keyValue: "name"},
        {label: "Country", keyValue: "country_name"},
        {label: "Data source", keyValue: "datasource"},
        {label: "Is testing", keyValue: "is_testing"},
    ];

    const Schema = Yup.object({
        code: Yup.string().required(),
        name: Yup.string().required(),
        country: Yup.string().required(),
    });

    const formik = useFormik({
        initialValues: {
            id: "",
            code: "",
            name: "",
            country: "",
            isTesting: "",
        },
        validationSchema: Schema,
        onSubmit: (values) => {

            let payload = {
                id: values.id,
                code: values.code,
                name: values.name,
                country_id: values.country,
                is_testing: values.isTesting
            }

            if (isNumber(values.id)) {
                updateDistributor(payload).then((data) => {
                    setDistributors([]);
                    getData();
                    closeModal();
                }).catch((e) => console.log(e));
            } else {
                createDistributor(payload).then((data) => {
                    setDistributors([]);
                    getData();
                    closeModal();
                }).catch((e) => console.log(e));
            }
        },
    });

    const actions = {
        close: closeModal,
        delete: deleteItem
    }

    return (
        <>
            <Button onClick={() => newItem()} primary>{t("general.new")}</Button>
            {distributors && <TableComponent rows={distributors} columns={columns} editRow={editItem} fetchData={getData} autoLoad={true} isLoading={loading}/>}
            {openModal && <ModalComponent open={openModal} close={closeModal} errorMessage={errorMessage} title={`${formik.values.id === "" ? t("settings.distributor.new") : t("settings.distributor.edit")}`}
            content={<FormComponent formFields={formDistributorCatalog(t, countries, formik)} formik={formik} action={actions} />}/>}
        </>
    );
}