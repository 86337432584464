import { useTranslation } from "react-i18next";
import React, { useContext } from "react";

import { APIContext } from "../../../../../contexts/APIContext";
import {
  CountrySelect,
  DatePicker,
  Form,
  IncisionSelect,
  IndicationSelect,
  PlacementSelect,
  SubmitOrCancel,
} from "../../../../form";
import { DataDict, DataEntries } from "../../../../data";
import { DoctorSelect } from "../../../../editor/DoctorSelect";
import { PatientContext } from "../../../../../contexts/PatientContext";
import { UserContext } from "../../../../../contexts/UserContext";
import {
  dateFormat,
  translateWithPrefix,
  yesNo,
} from "../../../../../formatters";
import { useEditable } from "../../../../../hooks/useEditable";

export const SurgeryDetailCard = ({ data: { surgery: data } }) => {
  const { canEditSurgery } = useContext(UserContext);
  const { editable, EditToggle, setEditable } = useEditable();
  const { countryById } = useContext(APIContext);
  const { postSurgery } = useContext(PatientContext);
  const incisionKind = translateWithPrefix("implant.incisionKind");
  const implantPlacement = translateWithPrefix("implant.placement");
  const indication = translateWithPrefix("implant.indication");

  const { t } = useTranslation();
  const canEdit = () => editable && canEditSurgery();
  return (
    <Form
      onSubmit={postSurgery.bind(this, data.id)}
      onFinishEdit={() => setEditable(false)}
      data={data}
      fields={[
        "dateOfSurgery",
        "surgeryCountry",
        "surgeonId",
        "surgeonName",
        "incisionKind",
        "implantPlacement",
        "indication",
      ]}
    >
      <DataDict
        title={t("surgery.title")}
        topRight={canEditSurgery() && <EditToggle />}
        footer={canEdit() && <SubmitOrCancel disableIfUnchanged />}
      >
        <DataEntries
          data={data}
          translationKey={"surgery.data"}
          format={{
            dateOfSurgery: dateFormat,
            dateOfRegistration: dateFormat,
            isActive: yesNo,
            incisionKind,
            implantPlacement,
            indication,
            surgeryCountry: countryById,
          }}
          Wrapper={{
            dateOfSurgery: canEdit() && DatePicker,
            surgeryCountry: canEdit() && CountrySelect,
            surgeonName:
              canEdit() &&
              ((props) => (
                <DoctorSelect
                  idName="surgeonId"
                  idData={data.surgeonId}
                  {...props}
                />
              )),
            incisionKind: canEdit() && IncisionSelect,
            implantPlacement: canEdit() && PlacementSelect,
            indication: canEdit() && IndicationSelect,
          }}
          keys={[
            "dateOfSurgery",
            "surgeryCountry",
            "surgeonName",
            "incisionKind",
            "implantPlacement",
            "indication",
            "dateOfRegistration",
          ]}
        />
      </DataDict>
    </Form>
  );
};
