import {useTranslation} from "react-i18next";
import React, {useContext} from "react";

import {DataDict, DataEntries} from "../../../../data";
import {Link} from "../../../../base";
import {dateFormat, numberWithUnits, translateWithPrefix, yesNo,} from "../../../../../formatters";
import {ConfigContext} from "../../../../../contexts/ConfigContext";
import {UserContext} from "../../../../../contexts/UserContext";
import {isNull} from "lodash";

export const WarrantyDetailCard = ({data}) => {
    const {t} = useTranslation();
    return (
        <DataDict title={t("implantWarranty.title")}>
            <WarrantyDetailEntries data={data}/>
        </DataDict>
    );
};

const WarrantyDetailEntries = ({data: {warranty: data, reference, additionalWarranties, isRegistered}}) => {
    const {t} = useTranslation();
    const {canEditImplantWarranty} = useContext(UserContext);
    const {defaultWarrantyExtendDays} = useContext(ConfigContext);
    const status = translateWithPrefix("implantWarranty.status");
    const statusWarranties = translateWithPrefix("implantWarranty.statusAdditionalWarranties");
    const extendedWarrantyBuyExtendedRejectionReason = translateWithPrefix(
        "implantWarranty.purchaseRejectionReason"
    );
    const hasOrder = () => !!data?.order?.id;

    const extendedDate = (data) => {
        return (
            <Link data={data} action="extendWarranty">
                {t("implantWarranty.action.extend")}
            </Link>
        );
    };
    const additionalWarrantiesE2 = () => {
        return (
            <div className="col-md-12">
                <p className="text-primary fw-bold">{t('implantWarranty.additionalWarranties')}</p>
            </div>
        )
    }

    const warrantyProperties = {
        currentProgram: t("implantWarranty.data.currentProgram"),
        coverage: t("implantWarranty.data.coverage"),
        startDate: t("implantWarranty.data.startDateNew"),
        endDate: t("implantWarranty.data.validUntil"),
        motivaProgram: t("implantWarranty.data.motivaProgram"),
        warrantyEligible: t("WarrantyEligible")
    }

    const selectedProgram = (warranty, elegible) => {
        const program = warranty?.program;
        const programSelected = `${warranty?.program} ${warrantyProperties["motivaProgram"]} ($${warranty?.coverage} ${warrantyProperties["coverage"]})`

        if (program === '5Y' || program === '2Y') {
            if (warranty?.warrantyStatus === 'inactive' && isNull(warranty?.orderId) && !warranty?.endDate) {
                return t("implantWarranty.program.0")
            }
            if (warranty?.orderId !== 0 && warranty?.endDate && !isNull(warranty?.endDate)) {
                return programSelected
            }
        }
        if (program === '5Y') {
            if ((warranty?.orderId === 0 || isNull(warranty?.orderId)) && warranty?.endDate) {
                return elegible ? programSelected : t("implantWarranty.program.1");
            }
        }
        if (program === '2Y') {
            if (warranty?.orderId === 0) {
                return elegible ? programSelected : t("implantWarranty.program.1");
            }
            if (isNull(warranty?.orderId)) {
                return elegible ? programSelected : t("implantWarranty.program.0");
            }
        }
        if (program === "Mia®") {
            return "Mia®"
        }
    }

    let warrantyCurrentProgram = {
        currentProgram: selectedProgram(data),
        ...(data.startDate && { startDateNew: data.startDate }),
        ...(data.endDate && { validUntil: data.endDate }),
        ...((data.orderId === 0 || isNull(data.orderId)) && data.warrantyStatus === 'active' && { warrantyEligible: selectedProgram(data, true) }),
    };
    const warrantyCurrentProgramKeys = Object.keys(warrantyCurrentProgram);

    return (

        <>
            <DataEntries
                data={{
                    extendedDate: defaultWarrantyExtendDays,
                    ...data,
                    ...warrantyCurrentProgram
                }}
                translationKey={"implantWarranty.data"}
                format={{
                    status,
                    startDate: dateFormat,
                    endDate: dateFormat,
                    startDateNew: dateFormat,
                    validUntil: dateFormat,
                    canBuyExtendedWarranty: yesNo,
                    extendedWarrantyBuyExtendedRejectionReason,
                    extendedDate,
                }}
                keys={data.startDate || isNull(data.orderId) ? [...warrantyCurrentProgramKeys, "canBuyExtendedWarranty",
                    "extendedWarrantyBuyExtendedRejectionReason",
                    "extendedDate"] : [
                    "status",
                    "program",
                    "startDate",
                    "endDate",
                    "canBuyExtendedWarranty",
                    "extendedWarrantyBuyExtendedRejectionReason",
                    "extendedDate",
                ]}
                visibility={{
                    extendedWarrantyBuyExtendedRejectionReason: (data) =>
                        !data.canBuyExtendedWarranty,
                    extendedDate: (data) =>
                        canEditImplantWarranty() &&
                        data.status !== 3 && // Inactive
                        data.extendedWarrantyBuyExtendedRejectionReason === 4, // After 90 days
                }}
            />
            {hasOrder() && <OrderDetailEntries data={data}/>}
            {reference.substring(0, 2) === 'E2' && isRegistered &&
                <DataEntries
                    adicionalWarranties={reference.substring(0, 2) === 'E2' && additionalWarrantiesE2()}
                    data={{
                        ...additionalWarranties,
                    }}
                    translationKey={"implantWarranty.data"}
                    format={{
                        status: statusWarranties,
                    }}
                    keys={[
                        "status",
                        "program"
                    ]}
                />
            }
        </>
    );
};

const OrderDetailEntries = ({data: {order: data}}) => {
    return (
        <DataEntries
            data={data}
            translationKey={"implantOrder.data"}
            format={{
                date: dateFormat,
                amount: numberWithUnits("$")
            }}
            keys={["date", "amount", "transaction_id"]}
        />
    );
};
