import React from "react";
import styled from "styled-components";

export const FileInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin: 20px;
`;

const FeatureItem = styled.dl`
  text-align: center;
`;

const FeatureName = styled.dt``;

const FeatureValue = styled.dd``;

export const FileFeature = ({ name, children }) => (
  <FeatureItem>
    <FeatureName>{name}</FeatureName>
    <FeatureValue>{children}</FeatureValue>
  </FeatureItem>
);
