import React, {useContext, useEffect} from "react";
import { useTranslation } from "react-i18next";

import { DataEntry } from "../DataEntry";
import { DataDetail } from "../DataDetail";
import { DataEntriesContainer } from "./styles";
import {FormContext} from "../../form";
import {useDispatch} from "react-redux";

import {setFormValues} from '../../../store/slices/formSlice';

export const DataEntries = ({
  adicionalWarranties,
  statusTracking,
  keys,
  data,
  translationKey,
  format = {},
  Wrapper = {},
  Attributes = [],
  visibility = {}
}) => {
    const { t } = useTranslation();

    const { formValues } = useContext(FormContext);
    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(setFormValues({
            formValues: {
                ...formValues,
                status: formValues?.status ? formValues.status : "0"
            }
        }))
    },[formValues]);

    return (
        <DataEntriesContainer>
            <div className="row">
                {adicionalWarranties}
                {statusTracking}
                {keys
                    .filter((k) => data && k in data)
                    .filter((k) => !(k in visibility) || visibility[k](data))
                    .map((k, idx) => (
                        <DataEntry
                            key={idx}
                            title={translationKey ? t(`${translationKey}.${k}`) : k}
                        >
                            <DataDetail
                                name={k}
                                format={k in format && format[k]}
                                data={data[k]}
                                Wrapper={k in Wrapper && Wrapper[k]}
                                Attributes={Attributes}
                            />
                        </DataEntry>
                    ))}
            </div>
        </DataEntriesContainer>
    );
};
