import { createContext, useContext, useState, useEffect } from "react";
import { APIContext } from "./APIContext";

export const UsersContext = createContext();

export const UsersContextProvider = ({ children }) => {
  const {
    getUsers,
    postUser: apiPostUser,
    updateUser: apiUpdateUser,
    deleteUser: apiDeleteUser,
  } = useContext(APIContext);

  const [areUsersValid, setAreUsersValid] = useState(false);
  const [users, setUsers] = useState(null);

  const setUsersValid = () => setAreUsersValid(true);
  const setUsersInValid = () => setAreUsersValid(false);

  const getUser = (id) => users.find((user) => user.id === id);

  const loadUsers = () => getUsers().then(setUsers).then(setUsersValid);

  function updateUser(id, data) {
    return apiUpdateUser(id, data);
  }

  function postUser(data) {
    return apiPostUser(data);
  }

  function deleteUser(id, email) {
    return apiDeleteUser(id, email);
  }

  useEffect(() => !areUsersValid && loadUsers(), [areUsersValid]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <UsersContext.Provider
      value={{
        users,
        updateUser,
        postUser,
        deleteUser,
        getUser,
        setUsersInValid,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};

export const withUsersContext = (Component) => (props) =>
  (
    <UsersContext.Provider>
      {" "}
      <Component {...props} />
    </UsersContext.Provider>
  );
