import React, { useRef } from "react";

import {
  InstructionsContainer,
  InstructionsIcon,
  InstructionsText,
  Button,
} from "./styles";

export const Instructions = ({ icon, text, uploadButton, onUpload }) => {
  const uploadInputRef = useRef();

  const fileSelected = () => {
    if (uploadInputRef.current.files.length) {
      onUpload(uploadInputRef.current.files[0]);
    }
  };

  const handleOnClickUpload = () => {
    uploadInputRef.current.click();
  };
  return (
    <InstructionsContainer>
      <input
        ref={uploadInputRef}
        type="file"
        style={{ display: "none" }}
        onChange={fileSelected}
      />
      {icon && <InstructionsIcon icon={icon} />}
      {text && <InstructionsText>{text}</InstructionsText>}
      {uploadButton && <Button onClick={handleOnClickUpload}primary>{uploadButton}</Button>}
    </InstructionsContainer>
  );
};
