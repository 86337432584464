import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    features: [
        { feature: "device_tracking", status: 0 }
    ]
};

export const featureFlagSlice = createSlice({
    name: 'featureFlags',
    initialState,
    reducers: {
        setFeatureFlag: (state, action) => {
            return {
                ...state,
                ...action.payload
            }
        },
        cleanFeatureFlag: (state) => {
            return {
                ...state,
                ...initialState
            }
        }
    }
})

export const { setFeatureFlag, cleanFeatureFlag } = featureFlagSlice.actions;

export default featureFlagSlice.reducer;