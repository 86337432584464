import React from "react";
import { useFormValue } from "../Form";
import { DefaultTextInput } from "../DefaultTextInput";

export const TextInput = ({ name, data, ...props }) => {
  const { formValue, setFormValue } = useFormValue(name, data);

  return (
    <DefaultTextInput
      name={name}
      value={formValue}
      onChange={(e) => setFormValue(e.target.value)}
      {...props}
    />
  );
};
