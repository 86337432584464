import {
  H1 as H1BS,
  H2 as H2BS,
  H3 as H3BS,
  H4 as H4BS,
  CardTitle as CardTitleBS,
  Card as CardBS,
  CardBlock as CardBlockBS,
  CardText as CardTextBS,
} from "@bootstrap-styled/v4";
import React from "react";
import styled from "styled-components";

export const H1 = styled(H1BS)`
  margin: 10px 0px;
  font-size: 48px;
  font-weight: 900;
  margin: 0px;
`;
export const H2 = styled(H2BS)`
  margin: 10px 0px;
  font-size: 24px;
  font-weight: 700;
`;
export const H3 = styled(H3BS)`
  margin: 10px 0px;
  font-size: 18px;
  font-weight: 700;
`;

export const H4 = styled(H4BS)`
  margin: 10px 0px;
  font-size: 18px;
  font-weight: 100;
`;

export const Text = styled.div`
  font-size: ${({ small }) => (small ? "10px" : "16px")};
  font-weight: 300;
`;

export const SmallText = styled.div`
  font-size: 12px;
  font-weight: 300;
`;

export const CardTitle = styled(CardTitleBS)`
  color: ${(props) => props.theme.$primary};
  font-size: 18px;
  display: inline-block;
  margin-bottom: 0px !important;
`;

export const Card = styled(CardBS)`
  border: 0px !important;
`;
export const CardBlock = styled(CardBlockBS)`
  background-color: ${(props) => props.theme["$shaded-background"]};
  border-radius: 10px;
`;
export const CardText = styled(CardTextBS)``;

const StyledInstruction = styled.div`
  margin: 5px 0px;
`;

export const Instruction = ({ title, small, children, ...props }) => {
  return (
    <StyledInstruction {...props}>
      {title && <H3>{title}</H3>}
      <Text small={small}>{children}</Text>
    </StyledInstruction>
  );
};
