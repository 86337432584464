import styled from "styled-components";
import { Icon } from "../../icons";

export const DefaultButton = styled.button`
  border: 0px;
  border-radius: 50px;
  padding: 5px 20px;
  font-size: ${(props) => (props.small ? 14 : 14)}px;
  text-transform: uppercase;
`;

export const PrimaryButton = styled(DefaultButton)`
  min-height: 40px;
  background-color: ${(props) =>
    !props.disabled
      ? props.theme["$primary"]
      : props.theme["$disabled-primary"]};
  color: ${(props) => props.theme["$primary-contrast"]};
`;

export const CancelButton = styled(DefaultButton)`
  background-color: transparent;
  color: ${(props) => props.theme["$light-text"]};
`;

export const OutlinedButton = styled(DefaultButton)`
  background-color: transparent;
  border: 1px solid ${(props) => props.theme["$primary"]};
  color: ${(props) => props.theme["$primary"]};
`;

export const StyledIcon = styled(Icon)`
  margin: 0px 5px;
`;
