import React from "react";
import Layout from "../../../layouts/Layout";
import ApprovalsCatalog from "../../../components/maintenance/ApprovalsCatalog";

export default function ApprovalsPage() {

    return (
        <Layout topComponent={<>
            <h2 className="titleCatalogs">Approvals Catalog</h2>
        </>}>
            <ApprovalsCatalog />
        </Layout>
    );
}