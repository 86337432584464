import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { makeTheme } from "bootstrap-styled";
import Color from "color";

const baseTheme = {
  ///"$dark-primary": "#540D5A",
  "$dark-primary": "#5e0d5a",
  $primary: "#6e0072",
  "$light-primary": "#F0F3F8",

  "$primary-contrast": "#fff",

  $danger: "#db1e2d",

  "$default-text": "#333",
  "$light-text": "#939597",
  "$lighter-text": "#58595C",
  "$lightest-text": "#babbbc",

  "$colored-background": "#F0F3F8",
  "$shaded-background": "#F3F3F4",
  "$dark-shaded-background": "#a0a0a155",

  "$clear-background": "#fff",

  "$primary-background": "#e5d6e7",
};

const customTheme = (v) => {
  v["$brand-primary"] = v["$primary"];
  v["$dropdown-bg"] = v["clear-background"];
  v["$dropdown-min-width"] = "100%";
  v["$disabled-primary"] = new Color(v["$primary"])
    .desaturate(0.9)
    .lighten(2)
    .toString();

  v["$lightest-text"] = new Color(v["$light-text"]).lighten(0.5);
  return makeTheme(v);
};

export default customTheme(baseTheme);
