import React, { useEffect } from "react";
import { Box, Grid } from "@mui/material";
import MaskedInput from "react-text-mask";
import { getMask } from "../../../util";

export const SimpleSerialComponent = ({ formik, serial }) => {
  const serialNumber =
    serial && serial.includes(".")
      ? formik.values[serial.split(".")[0]][serial.split(".")[1]]
      : formik.values[serial];

  useEffect(() => {
    formik.setFieldValue(serial, "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.typeSerialNumber]);

  return (
    <Box>
      <Grid
        container
        sx={{
          display: "grid",
          gridTemplateColumns: `7fr 1fr`,
          gridGap: 10,
          alignItems: "start",
        }}
      >
        <Grid item>
          <Box>
            <MaskedInput
              className="form-control custom-input"
              name={serial}
              type="text"
              mask={getMask("SN")}
              render={(ref, props) => {
                return (
                  <input
                    placeholder={"00000000-00"}
                    ref={ref}
                    {...props}
                    style={{ width: "100%" }}
                  />
                );
              }}
              onChange={formik.handleChange}
              value={serialNumber}
              onBlur={formik.handleBlur}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
