import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import {
    DeviceTrackingReportContainer,
    FilterLabel,
    RangeStart,
    RangeEnd,
    Country,
    Distributor,
    Error, DeviceType
} from "./styles";

import {Form, FormContext, SubmitButton} from "../../../../form";
const FormFields = () => {
    const {t} = useTranslation();
    const {formValues} = useContext(FormContext);

    return (
        <>
            <div className="row">
                <div className="col-1 d-flex align-items-center">
                    <FilterLabel>{t("reports.distributor.filter.label")}:</FilterLabel>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-2">
                    <label>{t("reports.deviceTracking.filters.startDate")}</label>
                    <RangeStart
                        name="imported_start"
                    />

                </div>
                <div className="col-2">
                    <label>{t("reports.deviceTracking.filters.endDate")}</label>
                    <RangeEnd
                        name="imported_end"
                    />
                </div>
                <div className="col-2">
                    <label>{t("reports.deviceTracking.filters.country")}</label>
                    <Country
                        name="country"
                        data={174}
                        disabled
                    />
                </div>
                <div className="col-2">
                    <label>{t("reports.deviceTracking.filters.distributor")}</label>
                    <Distributor
                        name="distributor"
                        country={formValues["country"]}
                    />
                </div>
                <div className="col-2 pt-4">
                    <SubmitButton>{t("general.filter")}</SubmitButton>
                </div>
            </div>
        </>
    );
};

export const DeviceTrackingReportFilter = ({onSubmit, validate}) => {
    const {t} = useTranslation();
    const [error, setError] = useState(null);
    const handleValidation = ({country}) => {
        if (country === undefined) {
            setError(t("reports.distributor.filter.error.countryRequired"));
            return false;
        } else {
            setError(null);
            return true;
        }
    };
    return (
        <div>
            <Form validate={handleValidation} onSubmit={onSubmit}>

                <DeviceTrackingReportContainer>
                    <FormFields/>
                </DeviceTrackingReportContainer>
                {error && <Error>{error}</Error>}
            </Form>
        </div>
    );
};
