import { useEffect } from 'react';

function useAwsRumClient() {
    useEffect(() => {
        const n = 'cwr';
        const i = process.env.REACT_APP_CWR_ID;
        const v = '1.0.0';
        const r = 'eu-central-1';
        const s = 'https://client.rum.us-east-1.amazonaws.com/1.14.0/cwr.js';

        const config = {
            sessionSampleRate: 1,
            guestRoleArn: process.env.REACT_APP_CWR_ROLE_ARN,
            identityPoolId: process.env.REACT_APP_CWR_POOL_ID,
            endpoint: 'https://dataplane.rum.eu-central-1.amazonaws.com',
            telemetries: ['performance', 'errors', 'http'],
            allowCookies: true,
            enableXRay: false,
        };

        const x = (window.AwsRumClient = {
            q: [],
            n: n,
            i: i,
            v: v,
            r: r,
            c: config,
        });

        window[n] = function (c, p) {
            x.q.push({ c: c, p: p });
        };

        const z = document.createElement('script');
        z.async = true;
        z.src = s;
        document.head.insertBefore(z, document.head.getElementsByTagName('script')[0]);
    }, []);

    return null;
}

export default useAwsRumClient;