import { useTranslation } from "react-i18next";
import React, { useContext } from "react";
import styled from "styled-components";

import { NavigationContext } from "../../contexts/NavigationContext";
import { Icon } from "../icons";
import { withModal } from "../modal";

const DefaultButton = styled.button`
  border: 0px;
  border-radius: 50px;
  padding: 5px 20px;
  font-size: ${(props) => (props.small ? 14 : 16)}px;
`;

const PrimaryButton = styled(DefaultButton)`
  background-color: ${(props) =>
    !props.disabled
      ? props.theme["$primary"]
      : props.theme["$disabled-primary"]} !important;
  color: ${(props) => props.theme["$primary-contrast"]};
`;

const CancelButton = styled(DefaultButton)`
  background-color: transparent;
  color: ${(props) => props.theme["$light-text"]};
`;

const OutlinedButton = styled(DefaultButton)`
  background-color: transparent;
  border: 1px solid ${(props) => props.theme["$primary"]};
  color: ${(props) => props.theme["$primary"]};
`;

const StyledIcon = styled(Icon)`
  margin: 0px 5px;
`;

export const Button = ({
  icon,
  children,
  grouped,
  submit,
  cancel,
  primary,
  secondary,
  outlined,
  style,
  modal,
  action,
  ...props
}) => {
  const { t } = useTranslation();
  const { actions } = useContext(NavigationContext);
  let Component =
    ((primary || submit) && PrimaryButton) ||
    (cancel && CancelButton) ||
    (outlined && OutlinedButton) ||
    DefaultButton;

  let innerText;

  if (submit) {
    innerText = t("general.submit");
  }

  if (cancel) {
    innerText = t("general.cancel");
  }

  if (action in actions) {
    ({ name: innerText, icon, modal } = actions[action]);
  }

  if (modal) {
    Component = withModal(Component, modal);
  }

  return (
    <Component {...props}>
      {icon && <StyledIcon icon={icon} />}
      {children || innerText}
    </Component>
  );
}
