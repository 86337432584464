import { Badge } from "@bootstrap-styled/v4";
import { useTranslation } from "react-i18next";
import React, { useContext } from "react";

import { DataDict, DataEntries, TextBig } from "../../../data";
import { ImplantSideSelect, Form, SubmitOrCancel } from "../../../form";
import { PatientContext } from "../../../../contexts/PatientContext";
import { SeeMore } from "../../../base/SeeMore";
import { UserContext } from "../../../../contexts/UserContext";
import { dateFormat, translateWithPrefix, yesNo } from "../../../../formatters";
import { useEditable } from "../../../../hooks/useEditable";
import {AzureAuthContext} from "../../../../contexts/AzureAuthContext";

export default function ImplantCard({ title, data, selectImplant }) {
  const { editable, EditToggle, setEditable } = useEditable();
  const {userProfile} = useContext(AzureAuthContext);
  const translateSide = translateWithPrefix("implant.side");
  const translateType = translateWithPrefix("implant.type");
  const { t } = useTranslation();
  const { canEditImplant } = useContext(UserContext);
  const { postImplant } = useContext(PatientContext);

  const canEdit = () => editable && canEditImplant();
  const isExplanted = (data) =>
    data.isExplanted && <Badge>{t("implant.data.explanted")}</Badge>;

  return (
    <Form
      onSubmit={postImplant.bind(null, data.id)}
      data={{
        ...data,
        ...data.deviceStatus,
        user : userProfile?.mail
      }}
      fields={["side", "isBlocked", "isTracked", "status", "reason", "date", "user"]}
      onFinishEdit={() => setEditable(false)}
    >
      <DataDict
        title={`${translateType(data.implantType)} ${t(
          "patient.card.implants.title"
        )} - ${data.side ? translateSide(data.side) : ""}`.replace(
          /[\s-]*$/,
          ""
        )}
        topRight={
          <>
            {isExplanted(data)}
            {canEditImplant() && <EditToggle />}
          </>
        }
        footer={
          canEdit() ? (
            <SubmitOrCancel disableIfUnchanged />
          ) : (
            <SeeMore onClick={selectImplant} />
          )
        }
      >
        <DataEntries
          data={data}
          translationKey={"implant.data"}
          format={{
            side: translateSide,
            dateOfSurgery: dateFormat,
            isActive: yesNo,
            isRegistered: yesNo,
            isBlocked: yesNo,
          }}
          Wrapper={{
            validationCode: TextBig,
            side: canEdit() && ImplantSideSelect,
          }}
          keys={[
            "validationCode",
            "isRegistered",
            "serialNumber",
            "side",
            "dateOfSurgery",
            "reference",
            "isBlocked",
          ]}
        />
      </DataDict>
    </Form>
  );
}
