import { useTranslation } from "react-i18next";
import React, { useContext } from "react";

import { CardContainer } from "../../../card";
import { ConfigContext } from "../../../../contexts/ConfigContext";
import { ConfirmSaved } from "../../../../alerts/ConfirmSaved";
import {
  DetailsSection,
  PatientCardContainer,
  PatientCardShortcutContainer,
  PatientCardShortcutsContainer,
  Shortcut,
} from "./styles";
import { ImplantsSummary } from "../ImplantsSummary";
import { NavigationContext } from "../../../../contexts/NavigationContext";
import { PatientContext } from "../../../../contexts/PatientContext";
import { ShowSaving } from "../../../../alerts/ShowSaving";
import ImplantDetail from "../ImplantDetail";
import PatientInfoCard from "../PatientInfoCard";

export const PatientCardView = () => {
  const { saved, loading, patient, implant, setImplant, clearImplant, setLoading } =
    useContext(PatientContext);
  const { t } = useTranslation();
  const { shortcutsFeature } = useContext(ConfigContext);
  const { patientActions } = useContext(NavigationContext);


  return (
    <PatientCardContainer>
      <ShowSaving saving={loading} />
        <ConfirmSaved saved={saved} />
      {patient?.id && (
        <>
          <CardContainer title={t("patient.container.title")}>
            <PatientInfoCard
                data={patient}
                title={t("patient.card.title")}
                setLoading={setLoading}
                loading={loading}
            />
          </CardContainer>
          <PatientCardShortcutsContainer
            columns={5}
            title={t("patient.container.shortcuts")}
          >
            {shortcutsFeature &&
              patientActions.map((action, idx) => (
                <PatientCardShortcutContainer key={idx}>
                  <Shortcut action={action} data={patient} />
                </PatientCardShortcutContainer>
              ))}
          </PatientCardShortcutsContainer>
        </>
      )}
      <DetailsSection>
        {implant ? (
          <ImplantDetail implant={implant} clearImplant={clearImplant} />
        ) : (
          <ImplantsSummary data={patient} selectImplant={setImplant} />
        )}
      </DetailsSection>
    </PatientCardContainer>
  );
};
