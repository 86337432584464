import React from "react";
import {useFormValue} from "../Form";
import {DefaultTextInput} from "../DefaultTextInput";

export const TextArea = ({name, data, ...props}) => {
    const {formValue, setFormValue} = useFormValue(name, data);

    return (
        <DefaultTextInput
            type="textarea"
            rows={3}
            name={name}
            value={formValue}
            onChange={(e) => setFormValue(e.target.value)}
            {...props}
        />
    );
};
