import { useTranslation } from "react-i18next";
import React, { useContext, useState, useEffect } from "react";

import { APIContext } from "../../contexts/APIContext";
import { PatientContext } from "../../contexts/PatientContext";
import { StyledBaseModal } from "./styles";
import { Title } from "./styles";
import { Table } from "../../components/base/Table";
import { dateFormat } from "../../formatters";

export const FullTransactionHistory = ({ show, ...props }) => {
  const { t } = useTranslation();
  const { countryById } = useContext(APIContext);
  const { getImplantTransactions } = useContext(PatientContext);
  const [transactions, setTransactions] = useState(null);
  const [distributorInfo, setdistributorInfo] = useState(null);

  const loadTransactions = () => {
    getImplantTransactions().then((res) => {
      setTransactions(res.results);
      setdistributorInfo(res.distributorInfo);
    });
  };

  useEffect(() => {
    if (show) loadTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return (
    <StyledBaseModal
      show={show}
      {...props}
      title={t("action.fullTransactionHistory")}
    >
      <div className="ms-4">
        <Title>{t("fullTransactionHistory.transaction.title")}</Title>
        <div className="ms-3 pt-4">
          <div className="row">
            <div className="col-3"><span className="font__weight--700">{t("fullTransactionHistory.transaction.distributorName")}:</span></div>
            <div className="col-6">{distributorInfo && distributorInfo.name}</div>
          </div>

          <div className="row">
            <div className="col-3"><span className="font__weight--700">{t("fullTransactionHistory.transaction.businessPartner")}:</span></div>
            <div className="col-6">{distributorInfo && distributorInfo.business_partner}</div>
          </div>

          <div className="row">
            <div className="col-3"><span className="font__weight--700">{t("fullTransactionHistory.transaction.country")}:</span></div>
            <div className="col-6">{distributorInfo && distributorInfo.country}</div>
          </div>

          <div className="row">
            <div className="col-3"><span className="font__weight--700">{t("fullTransactionHistory.transaction.implantImportedAt")}:</span></div>
            <div className="col-6">{distributorInfo && distributorInfo.imported_at}</div>
          </div>
        </div>
      </div>
      {transactions && (
        <Table
          sortable
          data={transactions}
          columns={["type", "created", "distributor","distributorCode", "country"]}
          format={{ created: dateFormat, country: countryById }}
          title={t("fullTransactionHistory.title")}
          headingTranslationKey="fullTransactionHistory.data"
        />
      )}
    </StyledBaseModal>
  );
};
