import { useContext } from "react";
import { UsersContext } from "../contexts/UsersContext";

export const useUsers = () => {
  const { users, updateUser, postUser, deleteUser, getUser, setUsersInValid } =
    useContext(UsersContext);

  return {
    users,
    updateUser,
    postUser,
    deleteUser,
    getUser,
    setUsersInValid,
  };
};
